import React from "react";

import Accordion from "react-bootstrap/Accordion";
import Footer from "./Footer";
import Header from "./Header";

const Faq = () => {
  return (
    <>
      <Header />
      <div className="container">
          <div className="row">
            <div className="col-12 text-center"></div>
          </div>
        </div>
        <div
          className="inner-banner-top "
          style={{
            backgroundImage: `url("	https://thumbs.dreamstime.com/b/kalpa-town-aerial-…ial-panoramic-view-kalpa-small-town-172680590.jpg")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="banner-overlay d-flex px-5 py-4 align-items-end">
                <div className="banner-text">
                  <h1> FAQs</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      <section className="px-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
            
              <h3 className="text-success">Fragen?</h3>
            </div>
            <div className="faq-pera">
              <p>
                Wir sind für sie da und beantworten gerne Ihre fragen über Ihre
                private tour in einem unserer reiseziele weltweit. Sie erhalten
                von uns auch informationen vor der abreise wenn sie buchen.
              </p>
              <p>
                Mehr als 40.000 gäste sind bereits mit uns gereist, also haben
                wir eine gute idee zu den gängigen fragen das wird gefragt.
                Diese haben wir im folgenden angesprochen. Wenn du noch mehr
                hast, lass es uns wissen!
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </section>
      <section className="px-5 py-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="accordion-header mb-5">
              <Accordion defaultActiveKey="0" className="Accordion">
                <div className="mt-3">
                  <Accordion.Item eventKey="0" className="border-0">
                  <Accordion.Header className="accordion-header">
                    Was sind die abreisedaten für die pauschalreisen auf deiner
                    webseite?
                  </Accordion.Header>
                  <Accordion.Body className="shadow-sm accordion-body">
                    Für unsere reisen gibt es keine festen abfahrtsdaten weil
                    jede tour privat ist, was bedeutet, dass sie abreisen können
                    an welchem ​​tag Sie möchten. Jede einzelne reiseroute auf
                    unserer website kann beginnen von jedem tag des jahres.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
               
                <div className="mt-3">
                <Accordion.Item eventKey="1" className="border-0">
                  <Accordion.Header>Sind das gruppenreisen?</Accordion.Header>
                  <Accordion.Body className="shadow-sm accordion-body">
                    Wir bieten keine gruppenführungen an. Wenn wir sagen, dass
                    unsere reisen privat sind, genau das meinen wir:Die einzigen
                    reisenden auf Ihrer reise bist du und die gefährten mit wem
                    sie reisen möchten. Ihre reiseroute ist völlig flexibel. Sie
                    können aus einer liste von aktivitäten auswählen oder
                    sehenswürdigkeiten jeden tag. Die einzige ausnahme hiervon
                    ist, wenn sie sich auf einer safari befinden wir können
                    jeden tag an Ihre interessen anpassen, Ihr energieniveau und
                    sogar die jahreszeit! wo sie Ihr fahrzeug teilen könnten mit
                    anderen gästen der lodge. Trotzdem, auch in diesem fall wir
                    arbeiten lieber mit kleinen lodges und camps mit
                    persönlichem service damit wir auf Ihre speziellen
                    interessen eingehen können.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
               
                <div className="mt-3">
                <Accordion.Item eventKey="2" className="border-0">
                  <Accordion.Header>
                    Können die Pauschalreisen auf Ihrer website geändert werden?
                  </Accordion.Header>
                  <Accordion.Body className="shadow-sm accordion-body">
                    Unbedingt! Unsere urlaubspakete sind bestimmt als
                    „Ausgangspunkt“ für die reiseroute dienen sie landen
                    schließlich auf reisen. Diese vorgeschlagenen reiserouten
                    basieren auf unserer eigenen expertise, reisezielerlebnis
                    und gästefavoriten! Sie können eine reise genau so
                    auswählen, wie sie angezeigt wird auf unserer website, oder
                    passen sie es weiter an Ihren zeitplan an, interessen und
                    budget mit einem unserer erfahrenen reiseberater.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
                
                <div className="mt-3">
                 
                </div>
                {/* <Accordion.Item eventKey="3" className="border-0">
                  <Accordion.Header>
                    Can the vacation packages on your website be modified?
                  </Accordion.Header>
                  <Accordion.Body>
                    Absolutely! Our vacation packages are intended to serve as a
                    “starting point” for the itinerary you ultimately end up
                    traveling. These suggested itineraries are based on our own
                    expertise, destination experience and guest favorites! You
                    can choose a trip exactly as it appears on our website, or
                    tailor it further to meet your schedule, interests, and
                    budget with one of our expert Travel Consultants.
                  </Accordion.Body>
                </Accordion.Item> */}
                <div className="mt-3">
                <Accordion.Item eventKey="4" className="border-0">
                  <Accordion.Header>
                    Ist der internationale flugpreis inbegriffen in den kosten
                    Ihrer website pauschalreisen?
                  </Accordion.Header>
                  <Accordion.Body className="shadow-sm accordion-body">
                    Internationale flüge sind nicht inbegriffen, denn das haben
                    wir bei unseren Gästen festgestellt ziehen es oft vor, ihre
                    eigenen flüge mit zu organisieren vielfliegermeilen oder
                    andere mittel. Jedoch, wenn sie möchten, dass wir Ihre
                    internationalen flüge arrangieren oder vorschläge zu den
                    besten routen machen und zeiten, dann machen wir das gerne.
                    Bitte beachten sie, dass alle regional und lokale flüge und
                    andere transportmittel während Ihrer reise ist immer im
                    preis inbegriffen.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
                
                <div className="mt-3">
                <Accordion.Item eventKey="5" className="border-0">
                  <Accordion.Header>
                    Was ist in den kosten enthalten Ihrer urlaubspakete?
                  </Accordion.Header>
                  <Accordion.Body className="shadow-sm accordion-body">
                    Unsere reisen beinhalten in der regel alle leistungen ab dem
                    moment, in dem Ihr flugzeug an Ihrem ziel ankommt, wo ein
                    vertreter auf Ihre ankunft wartet. Alle unterkünfte,
                    Überweisungen, regionale und lokale flüge, sowie
                    aktivitäten, sind arrangiert und enthalten. Falls nicht
                    anders angegeben, usere reiseziele sind nur mit frühstück
                    ausgestattet.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
               
                <div className="mt-3">
                <Accordion.Item eventKey="6" className="border-0">
                  <Accordion.Header>
                    Warum sind nicht alle mahlzeiten in der reise enthalten?
                  </Accordion.Header>
                  <Accordion.Body className="shadow-sm accordion-body">

                    Wir verstehen diese lokale Küche ist ein wichtiger aspekt
                    Ihrer reise und jeder hat einen anderen geschmack! <br />
                    Wir haben auch gefunden die viele unserer gäste genießen die
                    spontaneität, restaurants auf eigene faust zu entdecken. Als
                    ergebnis, schließen wir absichtlich nicht ein alle
                    mahlzeiten in der reiseroute.
                    <br />
                    Das frühstück ist in der regel an allen tagen inbegriffen
                    und gelegentliche mittag- und abendessen. Auf safari, alle
                    mahlzeiten sind in der regel inbegriffen wie sie in
                    abgelegenen gebieten sein werden ohne
                    restaurantalternativen. Ihre endgültige reiseroute wird
                    angezeigt welche mahlzeiten in Ihrer maßgeschneiderten reise
                    enthalten sind.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
               
                <div className="mt-3">
                <Accordion.Item eventKey="7" className="border-0">
                  <Accordion.Header>
                    Werde ich während meiner gesamten reise denselben
                    reiseleiter haben?
                  </Accordion.Header>
                  <Accordion.Body className="shadow-sm accordion-body">

                    Dies hängt von Ihrer reiseroute ab. Meistens verwenden wir
                    sachkundig lokale führer, die in der gegend ansässig sind
                    sie zeigen es dir. Sie sind Experten und man kann ihnen
                    vertrauen um Ihnen die bestmögliche erfahrung zu bieten. Sie
                    wissen, wohin man sie am besten zum sightseeing bringt und
                    die schönsten aussichten, welche restaurants zu empfehlen
                    sind, und wann sie eine beliebte attraktion besuchen
                    sollten, um den menschenmassen auszuweichen.
                    <br />
                    Wo wir lokale führer verwenden, sie haben normalerweise
                    einen neuen führer für jeden park, stadt oder region. Auch
                    wenn sich Ihr reiseleiter ändern kann, die qualität des
                    guidings gleichbleibend ist, und sie können sich darauf
                    verlassen, dass die guides immer freundlich sind,
                    professionell und sachkundig.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
               
                <div className="mt-3">
                <Accordion.Item eventKey="8" className="border-0">
                  <Accordion.Header>Besorgen sie meine visa?</Accordion.Header>
                  <Accordion.Body className="shadow-sm accordion-body">

                    Gerne unterstützen wir sie bei der beschaffung der
                    notwendigen visa für deine reise, aber es ist normalerweise
                    für gäste notwendig sie selbst zu arrangieren. Bitte
                    sprechen sie mit Ihrem riseberater über die visabestimmungen
                    und alle damit verbundenen fragen, die sie möglicherweise
                    haben.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
              
                <div className="mt-3">
                <Accordion.Item eventKey="9" className="border-0">
                  <Accordion.Header>
                    Kann eine reiseroute entworfen werden, die luxus
                    ausbalanciert und „off-the- beaten-track“-erlebnisse?
                  </Accordion.Header>
                  <Accordion.Body className="shadow-sm accordion-body">

                    Abseits der ausgetretenen pfade bedeutet für verschiedene
                    menschen unterschiedliche dinge, und es gibt eine vielzahl
                    von möglichkeiten, die lokale kultur zu erleben. Ob sie
                    einfach nur einen tag verbringen möchten durch die
                    landschaft fahren oder wenn sie nach etwas strengerem suchen
                    – wie eine abgelegene wanderung oder ein aufenthalt in einer
                    gastfamilie bei einer einheimischen familie – wir werden
                    unser bestes tun, um Ihren interessen gerecht zu werden und
                    gewünschtem komfort.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
               
                <div className="mt-3">
                <Accordion.Item eventKey="9" className="border-0">
                  <Accordion.Header>
                    Wie läuft der planungsprozess ab?
                  </Accordion.Header>
                  <Accordion.Body>
                    Sie arbeiten eng mit einem unserer reiseberater zusammen um
                    die Reise Ihrer Träume zu gestalten. Unsere reiseberater
                    sind experten und sind viel gereist, um auf dem laufenden zu
                    bleiben an unseren reisezielen, und sie können viele
                    ratschläge geben und Vorschläge, um Ihre leise
                    sicherzustellen optimal auf Ihre Interessen und
                    vorstellungen abgestimmt ist.
                    <br />
                    Unser Team ist reisebegeistert und engagiert, um
                    sicherzustellen, dass Ihr reiseerlebnis wird dich
                    verzaubern. Um Ihnen die bestmögliche reise zu bieten, sie
                    müssen wissen, was sie wollen – es ist am besten, so klar zu
                    sein und ehrlich wie möglich damit sie alle Informationen
                    haben sie müssen die richtige reiseroute planen. Wie in
                    jeder beziehung ist kommunikation der schlüssel: scheuen Sie
                    sich bitte nicht, fragen zu stellen und bedenken oder
                    wünsche zu äußern.
                  </Accordion.Body>
                </Accordion.Item>
                </div>
               
              </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="row">
        <div className="col-12 text-center">
          <h3>Got Questions?</h3>
          <div
            class="
 query-btn
 order-md-2
 pl-md-2
 mb-3
 order-1
 "
          >
            <button
              id="sendquery"
              class="btn btn-success"
              data-toggle="modal"
              data-target=".bd-example-modal-lg"
            >
              Contact Us
              <span>
                <i class="fas fa-paper-plane"></i>
              </span>
            </button>
          </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
};

export default Faq;
