import axios from "axios";
import { BASE_URL } from "../../service/url";
import { ActionTypes } from "../constants/actiontypes";

export const loaddatafrombackend = () => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/blog/getall`);
    // console.log("response----->>>", response);
    const { data, success } = response.data;
    if (success) {
      dispatch({
        type: ActionTypes.SET_BLOG_DATA,
        payload: data,
      });
    }
  } catch (error) {
    console.log("err===", error);
  }
};

export const getblogdetails = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/blog/getblog/${id}`);
    const { data } = response.data;
    // console.log(data);

    dispatch({
      type: ActionTypes.SET_BLOG_DETAILS,
      payload: data,
    });
  } catch (error) {
    console.log("err===", error);
  }

  // const blogs = res.data.data;

  // setLoading(false);
  // dispatch(blogdata(blogs));
};

export const loadfeaturedblog = () => async (dispatch) => {
  try {
    const response = await axios.get(`${BASE_URL}/blog/getfeaturedblog`);
    const { data } = response.data;
    console.log("----------------", data);

    dispatch({
      type: ActionTypes.SET_FEATURED_BLOG_DATA,
      payload: data,
    });
  } catch (error) {
    console.log("err===", error);
  }

  // const blogs = res.data.data;

  // setLoading(false);
  // dispatch(blogdata(blogs));
};

export const loadrelatedtraveltheme = () => async (dispatch) => {
  let obj = {
    traveltheme: "north india",
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/blog/getrelatedtraveltheme`,
      obj
    );
    const { data } = response.data;
    console.log("----------------", data);

    dispatch({
      type: ActionTypes.SET_RELATED_TRAVEL_THEMES_DATA,
      payload: data,
    });
  } catch (error) {
    console.log("err===", error);
  }

  // const blogs = res.data.data;

  // setLoading(false);
  // dispatch(blogdata(blogs));
};
