import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const TopDestinationCard = ({ Heading, image, Details, LinkTo }) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    console.log("object is clickeddddddddddddddddd");
    navigate(`/${LinkTo}`);
  };
  const [tourModal, setTourModal] = useState(false);
  return (
    <div className="px-2">
      <div className="card card-travel text-white  border-0">
        <img className="img-fluid w-100" src={image} alt="destination-img1" />
        <div className="card-img-overlay text-center">
          <div className="overlay-contant px-4 over ">
            <p
              className="card-title pt-0 "
              style={{ cursor: "pointer" }}
              onClick={handleNavigation}
            >
              {Heading}{" "}
            </p>
            <div className="card-details my-4">
              <p className="card-text pb-0 mb-2 pt-1 pt-0 px-2 mb-2">
                {Details}
              </p>
            </div>
            <div className="custom-btn d-flex justify-content-center justify-content-around">
              {/* <div>
              <button className="btn btn-light">Details</button>
            </div> */}

              {/* <div className="col-6 text-center ">
                <Link to={`/${LinkTo}`} className="link-text  btn btn-light ">
                  Details
                </Link>
              </div> */}
              {/* <div>
                <button
                  onClick={() => setTourModal(true)}
                  className="btn btn-light btn-light__white  "
                >
                  inquiry
                </button>
                {tourModal && (
                  <Modal
                    show={tourModal}
                    onHide={() => {
                      setTourModal(false);
                    }}
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <form
                        method="post"
                        action
                        name="inquiryFormLarge"
                        onsubmit="return validate_sendQuery_form()"
                      >
                        <div className="form-row">
                          <div className="col-md-6 form-group">
                            <input
                              type="text"
                              name="name"
                              className="form-control custom-height shadow-sm"
                              placeholder="First Name*"
                            />
                            <div id="name_error" />
                          </div>
                          <div className="col-md-6 form-group">
                            <input
                              type="text"
                              className="form-control custom-height shadow-sm"
                              placeholder="Last Name"
                            />
                          </div>
                          <div className="col-md-6 form-group">
                            <input
                              type="email"
                              name="email"
                              className="form-control custom-height shadow-sm"
                              placeholder="Email*"
                            />
                            <div id="email_error" />
                          </div>
                          <div className="col-md-6 form-group">
                            <input
                              type="text"
                              name="phone_number"
                              className="form-control custom-height shadow-sm"
                              placeholder="Phone no."
                            />
                            <div id="phone_number_error" />
                          </div>


                          <div class="col-md-6 form-group">
                  <input type="text" name="country" class="form-control custom-height shadow-sm"  placeholder="<%= translate.form_country%>">
                  <div id="country_error"></div>  
               </div>


                          <div className="col-md-6 form-group">
                            <select className="form-control custom-height shadow-sm">
                              <option>Select Country</option>
                              <option>Select Country</option>
                              <option>Select Country</option>
                            </select>
                          </div>
                          <div className="col-md-6 form-group">
                            <input
                              type="text"
                              name="name"
                              className="form-control custom-height shadow-sm"
                              placeholder="Trip duration in days"
                            />
                            <div id="name_error" />
                          </div>
                          <div className="col-md-12 form-group">
                            <select className="form-control custom-height shadow-sm">
                              <option>What stage you are planning</option>
                              <option>Still dreaming/ Researching</option>
                              <option>
                                Definitely travelling not sure which part of
                                India
                              </option>
                              <option>I want to Book my trip</option>
                            </select>
                          </div>
                          <div className="form-group col-12">
                            <textarea
                              className="form-control shadow-sm"
                              name="message"
                              id="exampleFormControlTextarea1"
                              rows={5}
                              defaultValue={""}
                            />
                            <div id="message_error" />
                          </div>
                          <div className="col-md-6 form-group">
                            <input
                              type="hidden"
                              name="inquiry_status"
                              defaultValue="send_query"
                              className="form-control custom-height shadow-sm"
                            />
                          </div>
                        </div>
                        <div className="custom-btn col-12 px-2">
                          <button
                            type="submit"
                            className="btn btn-success w-100 py-2"
                          >
                            Send &nbsp;
                            <i className="fas fa-paper-plane" />{" "}
                          </button>
                        </div>
                      </form>
                    </Modal.Body>
                  </Modal>
                )}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopDestinationCard;
