import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { postnewsletter } from "../redux/actions/newsletteraction";
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required*"),
});

const Newsletter = () => {
  // const [email, setEmail] = useState();
  const [submitAlert, setSubmitAlert] = useState(false);
  const dispatch = useDispatch();
  const successAdditionOfCategoryAlert = (title, icon) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        window.location.reload();
      }
    });
  };
  useEffect(() => {
    if (submitAlert) {
      successAdditionOfCategoryAlert("Submitted", "success");
    }
  }, [submitAlert]);

  const initialValue = {
    email: "",
  };
  const handleEmailSubmit = (formdata) => {
    dispatch(postnewsletter(formdata, setSubmitAlert));
  };
  // useEffect(() => {
  //   if (submitAlert) {
  //     successAdditionOfCategoryAlert("Submitted", "success");
  //   }
  // }, [submitAlert]);
  return (
    <section className="subscribe-newslatter">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-8 line-center mx-auto text-center">
            <h5 className>
              ERHALTEN SIE UNSERE SESSIONAL-ANGEBOTE UND ANGEBOTE
            </h5>
            <div className="content-box pb-0">
              <p className="text-paragraph">
                Melden Sie sich für unseren kostenlosen Newsletter an und
                erhalten Sie News aus der India Unlimited -Welt!
              </p>
              <Formik
                initialValues={initialValue}
                onSubmit={handleEmailSubmit}
                validationSchema={validationSchema}
              >
                {({ values, handleChange, handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <div id="name_error" />
                      <span
                        style={{
                          color: "tomato",
                          "font-size": "small",
                        }}
                      >
                        {errors.email && touched.email && (
                          <div>{errors.email}</div>
                        )}
                      </span>
                    </div>
                    <div className="custom-btn">
                      <button className="btn btn-warning" type="submit">
                        Anmelden
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
