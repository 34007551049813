import { React, useEffect, useState } from "react";
import CitySection from "../components/CitySection";
import DestinationTourCard from "../components/DestinationTourCard";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";


const SouthIndia = () => {
  const [tourdata, setTourdata] = useState();
  const [displayForm, setDisplayForm] = useState(true);
  const handleClick = () => {
    setDisplayForm(!displayForm);
  };
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTour();
  // }, []);
  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      category: "South India",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package`, obj);
      console.log("Reson data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
  // --------city data APi

  const [citydata, setCitydata] = useState();
  const [noOfCity, setNoOfCity] = useState(8);
  const cityslice = citydata?.slice(0, noOfCity);
  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data2 testing", response)
      const { result } = response.data;
      console.log(response.data, " City data2 testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  useEffect(() =>{
    fetchFilterTour()
    fetchCity()
  }, [])

 console.log("filterDataTour ......." , filterDataTour)

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Sudindien.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Südindien Reisen</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <p>Kultur,Küche und Charisma auf Luxus-Südindien-Touren</p>
                  <p>
                    Gleiten sie durch die üppigen backwaters von Kerala auf
                    einer luxuriösen hausboot tour, ein gemächlicher
                    lebensrhythmus entfaltet sich in diesen Dörfern Südindiens.
                  </p>
                  <p>
                    Als nächstes auf den karten,Ayurveda-massagen und
                    behandlungen zusammen mit yoga und meditation,in kurorten
                    entlang der küste von Kerala, oder an bergstationen
                    eingestellt zwischen gewürz- und teeplantagen.
                  </p>
                  <p>
                    In Kochi historische Kirchen stehen neben gebäuden aus der
                    kolonialzeit in boutique-hotels umgewandelt umgängliche
                    gastfreundschaft anzubieten. Die gleiche art von genialität
                    sie erhalten von der  Indien Unlimited berater wer hilft dir
                    erstellen sie eine maßgeschneiderte südindien- reiseroute.
                    Nachts, eine Oper besuchen Kathakali-aufführung, am besten
                    bekannt für die aufwändigen kostüme und das make-up der
                    tänzer.
                  </p>
                  <p>
                    Berge von ringelblumen, schlangen von hinduistischen
                    anhängern, und großartige architektur besucher in der
                    tempelstadt begrüßen von Madurai. Felsengehauene tempel in
                    Hampi, skulptur und kunst in Belur, und die weniger
                    bekannten der region höhlentempel sind allesamt
                    jahrhundertealte meisterwerke.
                  </p>
                  <p>
                    In Städten wie Hyderabad und Bengaluru und in jeder ecke
                    südindiens,das essen wird dich verlassen beide satt und
                    hungrig nach mehr.
                  </p>
                  <p>
                    Steigen sie hinüber, um Chennai, die hauptstadt von Tamil
                    Nadu, zu erkunden und dann drei Stunden nach Süden fahren,
                    in die ehemalige französische enklave Pondicherry, wo die
                    gemeinde Auroville wurde gegründet, um den frieden zu
                    fördern und menschliche einheit.{" "}
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE REISEN NACH SÜDINDIEN"
      />

<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default SouthIndia;
