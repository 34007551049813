import React from "react";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div>
        <footer className="pb-0 footer-section">
          <div className="container pt-5">
            <div className="row pb-3 ">
              <div className="col-sm-12 col-lg-3 mx-auto ">
                <div className="mb-4 pt-5">
                  {/* <a
                    className="d-block"
                    href="javascript:window.location:'/index.html'"
                  >
                    <img
                      src="../assets/images/logo.png"
                      style={{ filter: "invert(1)" }}
                      alt="Escale logo"
                      className="img-fluid logo-footer"
                    />
                  </a> */}
                  <Link to="/">
                  <img
                      src="../assets/images/logo.png"
                      style={{ filter: "invert(1)" }}
                      alt="Escale logo"
                      className="img-fluid logo-footer"
                    />
</Link>
                  <p className="text-white pt-4">
                    Genau wie unsere Destinationen, unser team ist eine Mischung
                    aus kulturen mit ausgesprochen individuellen perspektiven.
                  </p>
                </div>
                <div className="mb-4">
                  <div className="social-icons d-flex align-items-center justify-content-left">
                    <a
                      href="https://www.facebook.com/IndiaUnlimitedReise"
                      target="_blank"
                      className="d-flex w-30px h-30px align-items-center justify-content-center"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>

                    <a
                      href="https://instagram.com/india_unlimited_reisen?igshid=YmMyMTA2M2Y="
                      target="_blank"
                      className="d-flex w-30px h-30px align-items-center justify-content-center"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-6 col-md-4 col-lg-3  pt-md-4  pt-2">
                <h5 className="title">TOP-ZIEL</h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="/NordindienReisen">NORDINDIEN REISEN</a>
                  </li>
                  <li>
                    <a href="/SudindienReisen">SÜDINDIEN REISEN</a>
                  </li>
                  <li>
                    <a href="/ZentralUndWestindienReisen">
                      ZENTRAL UND WESTINDIEN REISEN
                    </a>
                  </li>
                  <li>
                    <a href="/NordostenIndienReisen">NORDOSTEN INDIEN REISEN</a>
                  </li>
                  <li>
                    <a href="/himalayas">HIMALAYAS</a>
                  </li>
                  <li>
                    <a href="/TierreisenUndSafari">TIERREISEN UND SAFARI</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-3 col-md-4 pt-md-4 col-6 pt-2">
                <h5 className="title">WEITERE LINKS</h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="/IndienReiseblog">INDIEN REISEBLOG</a>
                  </li>
                  <li>
                    <a href="/Reisebewertungen">REISEBEWERTUNGEN</a>
                  </li>
                  <li>
                    <a href="/DatenschutzBestimmungen">
                      DATENSCHUTZ-BESTIMMUNGEN
                    </a>
                  </li>
                  <li>
                    <a href="/Geschaftsbedingungen">GESCHÄFTSBEDINGUNGEN</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-3 col-md-4 pt-md-4 col-6 pt-2">
                <h5 className="title">WEITERE LINKS</h5>
                <ul className="list-unstyled">
                  <li>
                    <a href="/whyindiaunlimited">INDIA UNLIMITED</a>
                  </li>
                  <li>
                    <a href="/IndividuelleUndMaBgeschneiderteReisen">
                      INDIVIDUELLE UND MASSGESCHNEIDERTE REISEN
                    </a>
                  </li>
                  <li>
                    <a href="/IndienReiseberichte">INDIEN REISEBERICHTE</a>
                  </li>
                  <li>
                    <a href="/Covid19faq">COVID19 FAQS</a>
                  </li>
                  <li>
                    <a href="/faqs">FAQ</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <h5 className="title">VERBINDE DICH MIT UNS</h5>
              </div>
              <div className="col-md-6 my-3">
                <div className="address-text">
                  <p>Indien Address :-</p>
                  <p> 1482 D Wazir nagar, Delhi 110049</p>
                  <div className="pt-1">
                    <a className="link" href="tel:+919711077576">
                      <i className="fas fa-mobile px-2" />
                      +91 9711077576
                    </a>
                  </div>
                  <div>
                    <IoMdMail
                         style={{ color:" #fff", }}
        
                         />
                           <a className="link" href="mailto: ravindra@indiaunlimited.de">
                      ravindra@indiaunlimited.de
                    </a>
                    </div>
                </div>
              </div>
              <div className="col-md-6 my-3">
                <div className="address-text">
                  {/* <p>Deutschland(Germany) Address :-</p>
                  <p>Köln, Deutschland</p>
                  <div className="pt-1">
                    <a className="link" href="tel:+918287767646">
                      <i className="fas fa-mobile px-2" />
                      +49 163 6440121
                    </a>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-md-4 my-3">
                <div className="address-text">
                  <p>
                    India: Kakker bulding, 1466/1, Lane no : 7, Wazir Nagar ,
                    kotlamubarakpur, New delhi, 110003, India
                  </p>
                  <div className="pt-1">
                    <a className="link" href="tel:+918287767646">
                      <i className="fas fa-mobile px-2" />
                      +91 8287767646
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="container-fluid bg-green">
            <div className="row align-items-center pt-1 mx-0">
              <div className="order-md-1 mx-auto text-center my-1">
                <p className="mb-0 text-white">
                  India Unlimited © 2022 | All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <div
          className="modal fade bd-example-modal-lg"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                  method="post"
                  action
                  name="inquiryFormLarge"
                  onsubmit="return validate_sendQuery_form()"
                >
                  <div className="form-row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control custom-height shadow-sm"
                        placeholder="<Enter firstname "
                      />
                      <div id="name_error" />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        className="form-control custom-height shadow-sm"
                        placeholder="<Enter lastname "
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control custom-height shadow-sm"
                        placeholder="<Enter email "
                      />
                      <div id="email_error" />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control custom-height shadow-sm"
                        placeholder="Enter phone "
                      />
                      <div id="phone_number_error" />
                    </div>
                    <div className="col-md-6 form-group">
                      <select className="form-control custom-height shadow-sm">
                        <option>What stage you are planning</option>
                        <option>Still dreaming/ Researching</option>
                        <option>
                          Definitely travelling not sure which part of India
                        </option>
                      </select>
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control custom-height shadow-sm"
                        placeholder="Enter firstname "
                      />
                      <div id="name_error" />
                    </div>
                    <div className="col-md-12 form-group">
                      <select className="form-control custom-height shadow-sm">
                        <option>What stage you are planning</option>
                        <option>Still dreaming/ Researching</option>
                        <option>
                          Definitely travelling not sure which part of India
                        </option>
                        <option>I want to Book my trip</option>
                      </select>
                    </div>
                    <div className="form-group col-12">
                      <textarea
                        className="form-control shadow-sm"
                        name="message"
                        id="exampleFormControlTextarea1"
                        rows={5}
                        placeholder="<%= translate.form_message %>"
                        defaultValue={""}
                      />
                      <div id="message_error" />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="hidden"
                        name="inquiry_status"
                        defaultValue="send_query"
                        className="form-control custom-height shadow-sm"
                      />
                    </div>
                  </div>
                  <div className="custom-btn col-12 px-2">
                    <button
                      type="submit"
                      className="btn btn-success w-100 py-2"
                    >
                      button <i className="fas fa-paper-plane" />
                      {"{"}" "{"}"}
                    </button>
                  </div>
                </form>
              </div>
              <div className="modal-footer justify-content-start border-top-0 pt-0">
                <div className="col-12">
                  <small>brhsgaas</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content ">
              <div className="modal-header mx-3 modal-header-custom">
                <div className="col-12 px-3">
                  <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                  />
                </div>
                <button
                  type="button"
                  className="close btn-top"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                  method="post"
                  action
                  name="inquiryFormSmall"
                  onsubmit="return validate_inquery_form ( )"
                >
                  <div className="form-row">
                    <div className="col-12">
                      <p>
                        <span className="font-weight-bold pr-2"> </span>
                        {"{"}" "{"}"}
                      </p>
                    </div>
                    <div className="col-md-12 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control custom-height shadow-sm"
                        placeholder="<%= translate.form_firstname %>"
                      />
                      <div id="name_error1" />
                    </div>
                    <div className="col-md-12 form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control custom-height shadow-sm"
                        placeholder="<%= translate.form_email %>"
                      />
                      <div id="email_error1" />
                    </div>
                    <div className="col-md-12 form-group">
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control custom-height shadow-sm"
                        placeholder="<%= translate.form_phone %>"
                      />
                      <div id="phone_number_error1" />
                    </div>
                    <div className="form-group col-12">
                      <textarea
                        className="form-control shadow-sm"
                        name="message"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        placeholder="<%= translate.form_message %>"
                        defaultValue={""}
                      />
                      <div id="message_error1" />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="hidden"
                        name="inquiry_status"
                        defaultValue="send_query"
                        className="form-control custom-height shadow-sm"
                      />
                    </div>
                  </div>
                  <div className="custom-btn col-12 px-2">
                    <button
                      type="submit"
                      className="btn btn-success w-100 py-2"
                    >
                      {"{"}" "{"}"}
                      translate.form_send <i className="fas fa-paper-plane" />
                      {"{"}" "{"}"}
                    </button>
                  </div>
                </form>
              </div>
              <div className="modal-footer border-top-0 pt-0">
                <small> translate.form_text </small>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="popupmodal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="popupModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body text-center">
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
