import React, { useEffect, useState } from "react";
import { Link, NavLink, Redirect } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-number-input";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { postquerydata } from "../redux/actions/queryaction";
import { fieldErrors } from "../comman/err.message";
import startsWith from "lodash.startswith";
import * as Yup from "yup";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BASE_URL } from "../service/url";
import axios from "axios";
import { getFilterData } from "../redux/actions/filterData";

const validationSchema = Yup.object().shape({
  phoneNo: Yup.number(),
  firstname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  lastname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  email: Yup.string().email("Invalid Email").required("Required*"),
  country: Yup.string(),
});

const Header = () => {
  // singup modal state
  const [showsingup, setShowsinup] = useState(false);
  const handleCloseSignup = () => setShowsinup(false);
  const handleshowsingup = () => setShowsinup(true);
  const [navbartoggle, setNavbarToggle] = useState(false);

  const handleClose2 = () => setNavbarToggle(false);
  const handleShow2 = () => setNavbarToggle(true);

  //Forgot Password
  const [showforgot, setShowforgot] = useState(false);
  const handleCloseForgot = () => setShowforgot(false);
  const handleShowforgot = () => setShowforgot(true);
  //Set Password
  const [showsetpassword, setShowsetpassword] = useState(false);
  const handleClosesetpassword = () => setShowforgot(false);
  const handleShowsetpassword = () => setShowforgot(true);
  //sendquery

  const [queryModal, setQueryModal] = useState(false);
  const [travelResonData, setTravelResonData] = useState('');
  const[allResonData, setAllResonData]= useState({});
  const [value, setValue] = useState();
  const [show, setShow] = useState(false);
  const [queryalert, setQueryalert] = useState(false);
  const setClose = () => handleShow(false);
  const [navbarshow, setNavbarshow] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [showverfiyotp, setShowverfiyotp] = useState(false);
  const handleCloseverfiyotp = () => setShowverfiyotp(false);
  const HandleShowverfiyotp = () => setShowverfiyotp(true);
  const handleChange = (element, index) => {
    console.log(element, index, [
      ...otp.map((d, idx) => (idx === index ? element.value : d)),
    ]);
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleShow = () => setShow(true);
  const handleValue = () => setValue(true);
  const dispatch = useDispatch();

  // number and text validation
  const onlyTextAllowed = (e) => {
    const re = /^[a-zA-Z]+$/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  const onlyNumberAllow1 = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  console.log("yes travelthemeData",travelResonData)
  // const handlePhoneSubmit = () => {
  //   HandleShowverfiyotp(true);
  //   axios({
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     url: "http://localhost:8000/user/signup",
  //     data: { phone },
  //   })
  //     .then((response) => console.log("response is", response))
  //     .catch((err) => console.log("error is", err));
  // };
  const queryForm = {
    firstname: "",
    lastname: "",
    email: "",
    phoneNo: "",
    country: "",
    tripduration: "",
    stage: "",
    description: "",
  };

  const userSubmit = (formdata) => {
    console.log("innnnnnnnnn");
    const queryForm = {
      firstname: formdata?.firstname ? formdata?.firstname : "",
      lastname: formdata?.lastname ? formdata?.lastname : "",
      email: formdata?.email ? formdata?.email : "",
      phoneNo: phone,
      country: formdata?.country ? formdata?.country : "",
      tripduration: formdata?.tripduration ? formdata?.tripduration : "",
      stage: formdata?.stage ? formdata?.stage : "",
      description: formdata?.description ? formdata?.description : "",
    };
    console.log("queryform is", queryForm);
    dispatch(postquerydata(queryForm, setQueryalert));
    console.log("query alert state is", queryalert);
  };

  const successAdditionOfCategoryAlert = (title, icon) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        setQueryModal(false);
        window.location.reload();
      } else {
        setQueryModal(true);
      }
    });
  };

  useEffect(() => {
    if (queryalert) {
      successAdditionOfCategoryAlert("Submitted", "success");
    }
  }, [queryalert]);

  const fetchResonData = async () => {
    const obj = { 
      category:travelResonData
    }
    dispatch(getFilterData(obj))
  }
     
    
  useEffect(() => {
    fetchResonData();
  }, [travelResonData]);
  return (
    <>
      <div>
        <header>
          <nav className="navbar shadow-sm navbar-expand-lg sticky-top ">
            <div className="container-fluid px-md-4 px-2">
              <a
                className="navbar-brand mr-auto mr-lg-0"
                style={{ cursor: "pointer", border: "0" }}
                onclick="function_index()"
              >
                <Link to="/">
                  <img
                    className="d-lg-block d-none img-fluid logo-image-large"
                    src="/assets/images/logohome.png"
                    alt
                  />
                  <img
                    className="d-lg-none d-block logo-image"
                    src="/assets/images/logo.png"
                    alt
                  />
                </Link>
              </a>
              {/* <div className="col-auto order-3 pt-1 z-index-99">
                <span
                  className="navbar-toggler p-0 border-0 offcanvas-icon cursor-pointer"
                  type="button"
                  data-toggle="offcanvas"
                  onClick={handleShow2}
                >
                  Menu
                
                </span>
              </div>
              <Offcanvas
                show={navbartoggle}
                onHide={handleClose2}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <ul className="list-unstyled custom-accordian">
                    <li>
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Indien
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              <strong>
                                This is the first item's accordion body.
                              </strong>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>Reisethemen</li>
                    <li>Uber Uns</li>
                    <li>Reisebewertungen</li>
                    <li>Indien Rieseblog</li>
                    <li>Kontakt Uns</li>
                  </ul>
                </Offcanvas.Body>
              </Offcanvas> */}

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto mt-3 text-nowrap">
                  <li className="nav-item dropdown">
                    <NavLink
                      className="nav-link dropdown-toggle"
                      to="/travelindia"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Indien
                    </NavLink>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link className="dropdown-item"
                       onClick={() => {setTravelResonData("NorthIndia")
                                    
                      }

                      
                      }

                       to="/NordindienReisen">
                        Nordindien Reisen
                      </Link>
                      <Link className="dropdown-item" to="/SudindienReisen" 
                       onClick={() => setTravelResonData("NorthIndia")}

                      >
                        Südindien Reisen
                      </Link>
                      <Link
                       onClick={() => setTravelResonData("NorthIndia")}
                        className="dropdown-item"
                        to="/ZentralUndWestindienReisen"
                      >
                        Zentral Und Westindien Reisen
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/NordostenIndienReisen"
                      >
                        Nordosten Indien Reisen
                      </Link>
                      <Link className="dropdown-item"
                       onClick={() => setTravelResonData("NorthIndia")
                      
                      }
                      to="/himalayas">
                        Himalayas
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink
                      className="nav-link dropdown-toggle"
                      to="/traveltheme"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Reisethemen
                    </NavLink>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link className="dropdown-item" to="/LandundLeuteIndien">
                        Land Und Leute Indien
                      </Link>

                      <Link
                        className="dropdown-item"
                        to="/AyurvedaundYogaReisen"
                      >
                        Ayurveda Und Yoga Reisen
                      </Link>
                      <Link className="dropdown-item" to="/TierreisenUndSafari">
                        Tierreisen Und Safari
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/BadeferienUndStrandurlaubInIndien"
                      >
                        Badeferien Und Strandurlaub In Indien
                      </Link>
                      <Link className="dropdown-item" to="/Aktivurlaub">
                        Aktivurlaub
                      </Link>

                      <Link
                        className="dropdown-item"
                        to="/AlleRundreisenIndien"
                      >
                        Alle Rundreisen Indien
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    {/* <a class="nav-link dropdown-toggle p-0" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" > */}
                    <a
                      onclick="function_url('/about')"
                      className="nav-link dropdown-toggle"
                      style={{ cursor: "pointer" }}
                    >
                      Über Uns{" "}
                    </a>
                    {/* </a> */}
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link className="dropdown-item" to="/whyindiaunlimited">
                        India Unlimited
                      </Link>
                      <Link className="dropdown-item" to="/UnserTeam">
                        Unser Team
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/IndividuelleUndMaBgeschneiderteReisen"
                      >
                        Individuelle Und Maßgeschneiderte Reisen
                      </Link>
                      <Link className="dropdown-item" to="/IndienReiseberichte">
                        Indien Reiseberichte
                      </Link>
                      <Link className="dropdown-item" to="/Covid19faq">
                        Covid 19 Faqs
                      </Link>
                      <Link className="dropdown-item" to="/faqs">
                        Faqs
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                      to="/Reisebewertungen"
                    >
                      Reisebewertungen
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                      to="/IndienReiseblog"
                    >
                      Indien Reiseblog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      style={{ cursor: "pointer" }}
                      to="/KontaktUns"
                    >
                      Kontakt Uns
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="btn-custom-header">
                <div
                  className="
                query-btn
                 
                pl-md-2
                justify-content-end
                d-flex
                
              "
                >
                  <button
                    id="sendquery"
                    className="btn btn-success hh rounded-2 text-nowrap w-100"
                    data-toggle="modal"
                    data-target=".bd-example-modal-lg"
                    onClick={() => {
                      setQueryModal(true);
                    }}
                  >
                    Angebot Anfragen
                  </button>
                  {/* <div className="ml-4 align-self-center">
                  <div className="user-icon-nav d-flex justify-content-center">
                    <button
                      onClick={handleShow}
                      className="align-self-center btn d-flex"
                    >
                      <i className="fas fa-user" />
                    </button>
                  </div>
                </div> */}

                  {queryModal && (
                    <Modal
                      show={queryModal}
                      onHide={() => {
                        setQueryModal(false);
                      }}
                    >
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <Formik
                          initialValues={queryForm}
                          onSubmit={(values, { resetForm }) => {
                            userSubmit(values);
                            resetForm({ values: "" });
                          }}
                          validationSchema={validationSchema}
                        >
                          {({
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                          }) => (
                            <form
                              method="post"
                              name="inquiryFormLarge"
                              onSubmit={handleSubmit}
                            >
                              <div className="form-row">
                                <div className="col-md-6 form-group">
                                  <input
                                    type="text"
                                    maxLength={30}
                                    id="firstname"
                                    value={values.firstname}
                                    onChange={handleChange}
                                    className="form-control custom-height shadow-sm"
                                    placeholder="Vorname"
                                    onKeyPress={(e) => onlyTextAllowed(e)}
                                  />
                                  <div id="name_error" />
                                  <span
                                    style={{
                                      color: "tomato",
                                      "font-size": "small",
                                    }}
                                  >
                                    {errors.firstname && touched.firstname && (
                                      <div>{errors.firstname}</div>
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6 form-group">
                                  <input
                                    type="text"
                                    id="lastname"
                                    name="lastname"
                                    maxLength={30}
                                    value={values.lastname}
                                    onChange={handleChange}
                                    className="form-control custom-height shadow-sm"
                                    placeholder="Nachname"
                                    onKeyPress={(e) => onlyTextAllowed(e)}
                                  />
                                  <span
                                    style={{
                                      color: "tomato",
                                      "font-size": "small",
                                    }}
                                  >
                                    {errors.lastname && touched.lastname && (
                                      <div>{errors.lastname}</div>
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6 form-group">
                                  <input
                                    type="email"
                                    id=" email"
                                    value={values.email}
                                    onChange={handleChange}
                                    name="email"
                                    className="form-control custom-height shadow-sm"
                                    placeholder="Email*"
                                  />
                                  <div id="email_error" />
                                  <span
                                    style={{
                                      color: "tomato",
                                      "font-size": "small",
                                    }}
                                  >
                                    {errors.email && touched.email && (
                                      <div>{errors.email}</div>
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6 form-group ">
                                  <PhoneInput
                                    placeholder="Telefon-Nr"
                                    id=" phoneNo"
                                    name=" phoneNo"
                                    value={phone}
                                    // onChange={(e) => handlePhoneChange(e)}
                                    maxLength="17"
                                    onChange={setPhone}
                                    className=" form-control d-flex  custom-height shadow-sm"
                                  />
                                  <div id="phone_number_error" />
                                  <span
                                    style={{
                                      color: "tomato",
                                      "font-size": "small",
                                    }}
                                  >
                                    {errors.phoneNo && touched.phoneNo && (
                                      <div>{errors.phoneNo}</div>
                                    )}
                                  </span>
                                </div>

                                <div className="col-md-6 form-group">
                                  <select
                                    className="form-select custom-height shadow-sm"
                                    id="country"
                                    name="country"
                                    value={values.country}
                                    onChange={handleChange}
                                  >
                                    <option>Land Auswählen</option>
                                    <option value="india">India</option>
                                    <option value="germany">Germany</option>
                                    <option value="france">France</option>
                                  </select>
                                  <span
                                    style={{
                                      color: "tomato",
                                      "font-size": "small",
                                    }}
                                  >
                                    {errors.country && touched.country && (
                                      <div>{errors.country}</div>
                                    )}
                                  </span>
                                </div>
                                <div className="col-md-6 form-group">
                                  <input
                                    type="text"
                                    className="form-control custom-height shadow-sm"
                                    placeholder="Reisedauer In Tagen"
                                    id="tripduration"
                                    name="tripduration"
                                    maxLength={2}
                                    value={values.tripduration}
                                    onChange={handleChange}
                                    onKeyPress={(e) => onlyNumberAllow1(e)}
                                  />
                                  <div id="name_error" />
                                </div>
                                <div className="col-md-12 form-group">
                                  <select
                                    className="form-select custom-height shadow-sm"
                                    id="stage"
                                    value={values.stage}
                                    onChange={handleChange}
                                  >
                                    <option>Welche Phase Sie Planen</option>
                                    <option value="Still dreaming/ Researching">
                                      Ich träume immer noch/forsche
                                    </option>
                                    <option value="Definitely travelling not sure which part of India">
                                      Ich bin mir auf jeden Fall nicht sicher,
                                      in welchen Teil Indiens
                                    </option>
                                    <option value="I want to Book my trip">
                                      Ich möchte meine Reise buchen
                                    </option>
                                  </select>
                                </div>
                                <div className="form-group col-12">
                                  <textarea
                                    className="form-control shadow-sm"
                                    id="description"
                                    value={values.description}
                                    placeholder="Beschreibung"
                                    onChange={handleChange}
                                    rows={5}
                                    defaultValue={""}
                                  />
                                  <div id="message_error" />
                                </div>
                                <div className="col-md-6 form-group">
                                  <input
                                    type="hidden"
                                    name="inquiry_status"
                                    defaultValue="send_query"
                                    className="form-control custom-height shadow-sm"
                                  />
                                </div>
                              </div>
                              <div className="custom-btn col-12 px-2">
                                <button
                                  type="submit"
                                  className="btn btn-success w-100 py-2"
                                >
                                  Abschicken &nbsp;
                                  <i className="fas fa-paper-plane" />
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </Modal.Body>
                    </Modal>
                  )}
                </div>
                <button
                  class="navbar-toggler shadow-none pt-1"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i class="bi bi-list  toggle-navbar"></i>
                </button>
              </div>
            </div>
          </nav>
        </header>
      </div>

      {/* Login Modal */}

      <Modal show={show} onHide={setClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">Login/Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <h3 class=" text-center">Login your account</h3>
            </div>
          </div>
          <div className="col-md-12 form-group">
            <label className="mb-2 text-success">Email address</label>
            <input
              placeholder="Enter phone number"
              className=" form-control d-flex  custom-height shadow-sm"
            />
            <small className="text-danger">Enter Valid email</small>
            <div id="phone_number_error" />
          </div>
          <div className="col-md-12 form-group">
            <label className="mb-2 text-success">Password</label>
            <small
              style={{ cursor: " pointer" }}
              className="float-end text-danger  "
              onClick={() => {
                handleShowforgot();
              }}
            >
              {" "}
              Forgot password?
            </small>
            <input
              placeholder="Enter phone number"
              className=" form-control d-flex  custom-height shadow-sm"
            />
            <small className="text-danger">Enter valid password</small>
            <div id="phone_number_error" />
          </div>

          <div className="row">
            <div className="col-12 px-5 mb-2">
              <Button
                variant="success"
                type="submit"
                className="w-100"
                // onClick={handlePhoneSubmit}
              >
                Login
              </Button>
            </div>
            <div className="row my-3">
              <h6 className="text-center">
                Dont have an account?{" "}
                <div
                  onClick={() => {
                    handleshowsingup();
                    setShow(false);
                    // handleClose()
                  }}
                >
                  Register now
                </div>
              </h6>
            </div>
          </div>
          {/* <h3 class="card-title text-center">Log in to Codepen</h3>
          <form>
          <div class="form-group">
					<label for="exampleInputEmail1">Email address</label>
					<input type="email" class="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp"/>
				</div>

        <div class="form-group">
					<label for="exampleInputPassword1">Password</label>
					<a href="#" style="float:right;font-size:12px;">Forgot password?</a>
					<input type="password" class="form-control form-control-sm" id="exampleInputPassword1"/>
				</div>
        <button type="submit" class="btn btn-primary btn-block">Sign in</button>
				
				<div class="sign-up">
					Don't have an account? <a href="#">Create One</a>
				</div>
          </form> */}
        </Modal.Body>
      </Modal>
      {/* Forgot password modal */}

      {/* signup Modal */}
      <Modal show={showsingup} onHide={handleCloseSignup}>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <h3 class=" text-center">Sign Up your account</h3>
            </div>
          </div>
          <div className="col-md-12 form-group">
            <label className="mb-2 text-success">Email address</label>
            <input
              placeholder="Enter Email..."
              className=" form-control d-flex  custom-height shadow-sm"
            />
            <div id="phone_number_error" />
          </div>
          <div className="col-md-12 form-group">
            <label className="mb-2 text-success">Password</label>
            <input
              placeholder="Enter Password"
              className=" form-control d-flex  custom-height shadow-sm"
            />
          </div>
          <div className="col-md-12 form-group">
            <label className="mb-2 text-success"> Confirm Password</label>
            <input
              placeholder="Enter Confirm Password"
              className=" form-control d-flex  custom-height shadow-sm"
            />
          </div>
          <div className="row">
            <div className="col-12 px-5 mb-2">
              <Button
                variant="success"
                type="submit"
                className="w-100"
                onClick={() => {
                  HandleShowverfiyotp();
                  setShowsinup(false);
                }}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Otp Verify Modal */}
      <Modal show={showverfiyotp} onHide={handleCloseverfiyotp}>
        <Modal.Title closeButton className="text-success pl-2">
          Verify OTP
        </Modal.Title>

        <Modal.Body className="pt-0">
          <p class="text-gray-400 ">
            We have sent an OTP to
            <span className="text-danger">
              <u>abcd@gmail.com</u>
            </span>
          </p>
          <div
            id="otp"
            class="inputs d-flex flex-row justify-content-center mt-2"
          >
            {otp.map((data, index) => {
              return (
                <input
                  class="m-2 text-center form-control rounded py-4 border"
                  type="text"
                  id="first"
                  name="otp"
                  key={index}
                  value={data}
                  maxlength="1"
                  onChange={(e) => handleChange(e.target, index)}
                  // onFocus={(e) => e.target.select}
                />
              );
            })}
          </div>
          {/* 
          <div
            id="otp"
            class="inputs d-flex flex-row justify-content-center mt-2"
          >
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="first"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="second"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="third"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="fourth"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="fifth"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="sixth"
              maxlength="1"
            />
          </div> */}

          <div class="row text-center py-3">
            <p>
              Resend code in <b>00:30</b>
            </p>
          </div>
        </Modal.Body>
        <div className="row">
          <div className="col-12 px-5 mb-3">
            <Link to="/login">
              {" "}
              <Button
                variant="success"
                type="submit"
                className="w-100"
                // onSubmit={handleOtpSubmit}
              >
                Save
              </Button>
            </Link>
          </div>
        </div>
      </Modal>
      {/* Forgot verify Modal */}
      <Modal show={showforgot} onHide={handleCloseForgot}>
        <Modal.Title closeButton className="text-success pl-2">
          Verify OTP
        </Modal.Title>

        <Modal.Body className="pt-0">
          <p class="text-gray-400 ">
            Your Forgot an OTP sent to
            <span className="text-danger">
              <u>abcd@gmail.com</u>
            </span>
          </p>
          <div
            id="otp"
            class="inputs d-flex flex-row justify-content-center mt-2"
          >
            {otp.map((data, index) => {
              return (
                <input
                  class="m-2 text-center form-control rounded py-4 border"
                  type="text"
                  id="first"
                  name="otp"
                  key={index}
                  value={data}
                  maxlength="1"
                  onChange={(e) => handleChange(e.target, index)}
                  // onFocus={(e) => e.target.select}
                />
              );
            })}
          </div>
          {/* 
          <div
            id="otp"
            class="inputs d-flex flex-row justify-content-center mt-2"
          >
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="first"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="second"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="third"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="fourth"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="fifth"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="sixth"
              maxlength="1"
            />
          </div> */}

          <div class="row text-center py-3">
            <p>
              Resend code in <b>00:30</b>
            </p>
          </div>
        </Modal.Body>
        <div className="row">
          <div className="col-12 px-5 mb-3">
            <Link to="/login">
              {" "}
              <Button
                onClick={() => {
                  handleShowsetpassword();
                  setShowforgot(false);
                }}
                variant="success"
                type="submit"
                className="w-100"
                // onSubmit={handleOtpSubmit}
              >
                Save
              </Button>
            </Link>
          </div>
        </div>
      </Modal>
      {/* set password modal */}
      <Modal show={showsetpassword} onHide={handleClosesetpassword}>
        <Modal.Title closeButton className="text-success pl-2">
          Verify OTP
        </Modal.Title>

        <Modal.Body className="pt-0">
          <p class="text-gray-400 ">
            Your Forgot an OTP sent to
            <span className="text-danger">
              <u>abcd@gmail.com</u>
            </span>
          </p>
          <div
            id="otp"
            class="inputs d-flex flex-row justify-content-center mt-2"
          >
            {otp.map((data, index) => {
              return (
                <input
                  class="m-2 text-center form-control rounded py-4 border"
                  type="text"
                  id="first"
                  name="otp"
                  key={index}
                  value={data}
                  maxlength="1"
                  onChange={(e) => handleChange(e.target, index)}
                  // onFocus={(e) => e.target.select}
                />
              );
            })}
          </div>
          {/* 
          <div
            id="otp"
            class="inputs d-flex flex-row justify-content-center mt-2"
          >
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="first"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="second"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="third"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="fourth"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="fifth"
              maxlength="1"
            />
            <input
              class="m-2 text-center form-control rounded py-4 border"
              type="text"
              id="sixth"
              maxlength="1"
            />
          </div> */}

          <div class="row text-center py-3">
            <p>
              <b>00:30</b>
            </p>
          </div>
        </Modal.Body>
        <div className="row">
          <div className="col-12 px-5 mb-3">
            <Link to="/login">
              {" "}
              <Button
                variant="success"
                type="submit"
                className="w-100"
                // onSubmit={handleOtpSubmit}
              >
                Save
              </Button>
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Header;
