import { React, useEffect, useState } from "react";
import CitySection from "../components/CitySection";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";

import Footer from "./Footer";
import Header from "./Header";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

const Wildlife = () => {
  const [noOfElement, setNoOfElement] = useState(3);

  const [tourdata, setTourdata] = useState();
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };
  const[noOfTravel, setNoOfTravel] = useState(3);
  const [filterDataTour, setFilterDataTour] = useState('');
  console.log("setFilterDataTour".filterDataTour)
  const fetchFilterTour = async () => {
    const obj = {
      traveltheme: "Tierreisen Und Safari",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package-theme`, obj);
      console.log("theme data testing", response);
      // const { result } = response.data;
      const data = response.data?.data?.slice(0, noOfElement);;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
  // --------city data APi

  const [citydata, setCitydata] = useState();
  const [noOfCity, setNoOfCity] = useState(8);
  const cityslice = citydata?.slice(0, noOfCity);
  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data2 testing", response)
      const { result } = response.data;
      console.log(response.data, " City data2 testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };
  const handleloadMore = () => {
    setNoOfTravel(noOfTravel + noOfTravel);
    
  };

  useEffect(() => {
    fetchFilterTour();
    fetchCity();
  }, []);

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top bg-about mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Tierreisen und Safari.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Tierreisen Und Safari</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row px-5">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <p>
                    Von eindringlich schönen schneebedeckten eskapaden zu
                    üppigen wäldern und hügeln mit sanftem grün,von den endlosen
                    goldenen stränden bis zum saphir von den endlosen goldenen
                    stränden bis zu den saphir- und smaragdseen eingebettet in
                    die landschaft, die natürliche schönheit des indischen
                    subkontinents ist unvergleichlich
                  </p>
                  <p>
                    Die unberührten und unerforschten Gebiete des Landes lassen
                    einen in ehrfurcht vor ihrer atemberaubenden pracht zurück.
                    Darüber hinaus, die unterschiede und kontraste einer region
                    von einer anderen unterstreichen den Reiz dieser
                    Landschaften- sei es die eiskalte Wüste von Leh dieser
                    aufruf an den Seelensucher in dir oder die
                    sonnendurchfluteten mystischen wüstendünen von Rajasthan die
                    Sie in die zeit der royals zurückversetzen.
                  </p>
                  <p>
                    Ausgehend vom schneebedeckten Himalaya im Norden,
                    durchquerung der wüsten von Rajasthan, durchqueren sie die
                    üppigen Wälder von Madhya Pradesh und dann in den
                    weitläufigen backwaters von Kerala segeln, und schließlich
                    in die unberührten gewässer der Andamanen- und
                    Nikobareninseln eintauchen, die reise ist voller
                    abenteuerlicher hochländer und ruhiger ebenen und
                    tiefländer.
                  </p>
                  <p>
                    Ein land mit einer vielfältigen flora und Fauna,Indien ist
                    auch die heimat vieler nationalparks, wildschutzgebiete,
                    tigerreservate und biosphärenreservate, die bezaubernde
                    tierbeobachtungen bieten.
                  </p>
                  <p>
                    Von bergstationen und seen bis hin zu stränden, hier sind
                    alle natürlichen rückzugsorte, die sie in Ihre reiseroute
                    aufnehmen können während sie von einem ende des landes zum
                    anderen reisen. Genießen Sie die Tierwelt mit den
                    außergewöhnlichen Safari-Services von India Unlimited.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour }
        title="BELIEBTE REISEN ZU WILDTIERE UND SAFARI"
      />
       <div className=" col custom-btn pt-3 text-center">
              <button className="btn btn-success" onClick={handleloadMore}>
                Mehr laden
              </button>
            </div>

<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was Unsere Gäste Uber Uns Sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
     
      <Footer />
    </div>
  );
};
export default Wildlife;
