import axios from "axios";
import { BASE_URL } from "../../service/url";
import { ActionTypes } from "../constants/actiontypes";

export const postreviewdata =
  (formdata, setReviewalert) => async (dispatch) => {
    console.log("even before hitting the api-----------", formdata)
    try {
      console.log("formata send from dispatch", formdata);
      const response = await axios.post(
        `${BASE_URL}/review/addreview`,
        formdata
      );
      // const { data } = response.data;
      console.log("-----------------review data----------",response.status);
      if (response.status = 200) {
        setReviewalert(true);
      }
    } catch (error) {
      console.log("err===", error);
    }
  };
export const getreviewdata = () => async (dispatch) => {
  try {
    console.log("insideeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
    const response = await axios.get(`${BASE_URL}/review/getreviews`);
    const { result } = response.data;
    console.log("data on making review requset", result);
    dispatch({
      type: ActionTypes.SET_REVIEW_DATA,
      payload: result,
    });
  } catch (error) {
    console.log("err===", error);
  }
};
export const getdetailedreview = (id) => async (dispatch) => {
  try {
    console.log("detaileddddddddddddddddd reviewwwwwwwwwwwwwwwwwwwwww");
    const response = await axios.get(`${BASE_URL}/review/getreview/${id}`);
    const { result } = response.data;
    console.log("data on making review requset", result);
    dispatch({
      type: ActionTypes.SET_REVIEW_DETAILS,
      payload: result,
    });
  } catch (error) {
    console.log("err===", error);
  }
};
