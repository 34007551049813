import { React, useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

import ReviewCardSlider from "../components/ReviewCardSlider";
import TravelCard from "../components/TravelCard";
import QueryIndia from "../components/QueryIndia";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";

const IndiaTravelNotes = () => {
  const [tourdata, setTourdata] = useState();
  const fetchTour = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
      const { result } = response.data;

      setTourdata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  useEffect(() => {
    fetchTour();
  }, []);
  return (
    <div>
      <Header />
      <div
        className="inner-banner-top  mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/India Travel notes.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1> Indien Reiseberichte</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="row px-2 px-lg-5">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{ textAlign: "justify", }}>
                  <p className="pb-2">
                    Seit Anfang 2020, der ausbruch des Coronavirus oder COVID-19
                    hatte eine beispiellose auswirkung auf die Welt. Als die
                    pandemie unseren notfall brach reibungslos und effektiv
                    entfaltet. Jeder unserer gäste hatte einen eigenen
                    reisekoordinator rund um die Uhr verfügbar während des
                    ausflugs und ein team, das hinter den Kulissen arbeitet um
                    sicherzustellen, dass alle sicher nach hause zurückkehren.
                  </p>
                  <p className="pb-2">
                    Since then, wir haben die umfangreiche berichterstattung in
                    den medien aufmerksam verfolgt, und unser team hat sich auf
                    vertrauenswürdige reisehinweise verlassen ausgestellt vom
                    US-Außenministerium und der WHO, um das wohlbefinden unserer
                    gäste und unseres teams zu gewährleisten.
                  </p>
                  <p className="pb-2">
                    Wir haben den vorteil eines globalen netzwerks von partnern
                    vor ort die in der lage sind, die aktuellsten Informationen
                    zu teilen und einblicke in diese sich schnell entwickelnde
                    situation.
                  </p>
                  <p className="pb-2">
                    Bei India Unlimited, Ihre sicherheit und Ihr wohlbefinden
                    sind unsere obersten prioritäten. Wenn sie bereit sind, die
                    freude am reisen wieder zu erleben, wir sind hier um zu
                    helfen. Sie können sorglos buchen mit dem wissen:
                  </p>
                  <p className="pb-2">
                    Unsere Sorglos-Garantie ermöglicht es Ihnen um Ihre reise
                    kostenlos umzubuchen bis 60 Tage vor Abflug. Wenn eine reise
                    aufgrund von COVID-19 nicht möglich ist von der regierung
                    auferlegte reisebeschränkungen 60 tage vor abreise, sie
                    können wählen, ob sie Ihre reise kostenlos umbuchen möchten
                    (es gelten einige Ausnahmen).
                  </p>
                  <p style={{ textAlign: "justify", }}>
                    Wir stellen Ihnen einen persönlichen Reisekoordinator zur
                    Verfügung, während Ihrer Reise rund um die Uhr verfügbar, um die
                    Logistik zu verwalten, sonderwünsche nach möglichkeit erfüllen,
                    und kümmere dich um alle details um ein nahtloses erlebnis zu
                    gewährleisten. Ihr reisekoordinator steht Ihnen ebenfalls zur
                    verfügung um Ihnen bei der organisation der besten verfügbaren
                    option zu helfen sich rechtzeitig auf COVID-19 testen zu lassen
                    für Ihren heimflug.
                  </p>
                  <ul className="p-3 mb-0">
                    <li>
                      <p style={{ textAlign: "justify", }}>
                        Unsere lokalen teams überwachen die reiseziele und beraten
                        zuverlässig und empfehlungen.
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "justify", }}>
                        Wir buchen hotels und fahrzeuge mit höchster gesundheit und
                        sicherheitsstandards an jedem Zielort.
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "justify", }}>
                        Unsere lokalen guides sind sachkundig und erfahren, und
                        personalisieren Sie jeden ausflug für Sie.
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "justify", }}>
                        Wir nehmen strenge Gesundheit und sicherheitsmaßnahmen, die
                        den empfehlungen der regierung entsprechen weltweit und
                        bieten nothilfe an allen unseren zielen an.
                      </p>
                    </li>
                    <li>
                      <p style={{ textAlign: "justify", }}>
                        Alle unsere bedingungen sind transparent, ohne versteckte
                        Kosten.
                      </p>
                    </li>
                  </ul>
                  <p style={{ textAlign: "justify", }}>
                    Als leidenschaftliche reisende selbst, wir glauben, dass reisen
                    eine der ultimativen freuden im Leben ist. Während wir weiterhin
                    genau beobachten die situation vor ort, wir möchten Ihnen
                    versichern dass wir bei jedem schritt an Ihrer seite sind.
                  </p>
                  <p style={{ textAlign: "justify", }}>
                    Ob sie zu diesem zeitpunkt auf einer reise sind oder entscheiden
                    sie sich, in zukunft mit uns zu reisen, mit unseren flexiblen
                    reiserichtlinien, gründliche anleitung, lokale expertise, und
                    unermüdliche unterstützung, vergessen sie Ihre sorgen - sie werden
                    immer in sicheren händen sein. Wir kümmern uns darum, dass sie
                    sorgenfrei reisen können.
                  </p>

                </div>
              </div>
              <div className="col-11 mx-auto col-lg-4">
                <QueryIndia />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid">
        <div className="row px-2 px-lg-5">
          <div className="col-12 col-lg-8 text-lg-left text-start " >

            <div>

            </div>

          </div>
        </div>
      </div>
      <DestinationTourCard
        tourData={tourdata}
        title="BELIEBTE REISEN NACH INDIEN"
      />

      <Footer />
    </div>
  );
};

export default IndiaTravelNotes;
