import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./pages/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Review from "./pages/Review";

import Tourdetails from "./pages/Tourdetails";
import Beaches from "./pages/Beaches";
import Travel_theme from "./pages/Travel_theme";
import NorthIndia from "./pages/NorthIndia";
import SouthIndia from "./pages/SouthIndia";
import CentralandWestIndia from "./pages/CentralandWestIndia";
import EastIndia from "./pages/EastIndia";
import Himalayas from "./pages/Himalayas";
import IslandsandBeaches from "./pages/IslandsandBeaches";
import HeritageandCulture from "./pages/HeritageandCulture";
import NatureandLandscape from "./pages/NatureandLandscape";
import RelaxationandSpa from "./pages/RelaxationandSpa";
import WhyIndiaUnlimited from "./pages/WhyIndiaUnlimited";
import OurTeam from "./pages/OurTeam";
import Faq from "./pages/Faq";
import { Blog } from "./pages/Blog";
import Blogdetails from "./pages/Blogdetails";
import PrivateTour from "./pages/PrivateTour";
import IndiaTravelNotes from "./pages/IndiaTravelNotes";
import CovidMaps from "./pages/CovidMaps";
import TourPackage from "./pages/TourPackage";
import Profile from "./pages/Profile";
import Ayurveda from "./pages/Ayurveda";
import Wildlife from "./pages/Wildlife";
import WineandFood from "./pages/WineandFood";
import Adventure from "./pages/Adventure";
import Covid19 from "./pages/Covid19";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CityDetails from "./pages/CityDetails";
import ViewCity from "./pages/ViewCity";


function App() {
  return (
    <Routes>
      <Route element={<Home />} path="/" />
      <Route element={<About />} path="/about" />
      <Route element={<Contact />} path="/KontaktUns" />
      <Route element={<Review />} path="/Reisebewertungen" />
      <Route element={<Tourdetails />} path="/tourdetails" />
      <Route element={<Travel_theme />} path="/traveltheme" />
      <Route element={<Beaches />} path="/BadeferienUndStrandurlaubInIndien" />
      <Route element={<HeritageandCulture />} path="/LandundLeuteIndien" />
      <Route element={<NatureandLandscape />} path="/AlleRundreisenIndien" />
      <Route element={<RelaxationandSpa />} path="/relaxation" />
      <Route element={<Ayurveda />} path="/AyurvedaundYogaReisen" />
      <Route element={<Wildlife />} path="/TierreisenUndSafari" />
      <Route element={<WineandFood />} path="/wineandfood" />
      <Route element={<Adventure />} path="/Aktivurlaub" />

      <Route element={<NorthIndia />} path="/NordindienReisen" />
      <Route element={<SouthIndia />} path="/SudindienReisen" />
      <Route
        element={<CentralandWestIndia />}
        path="/ZentralUndWestindienReisen"
      />
      <Route element={<EastIndia />} path="/NordostenIndienReisen" />
      <Route element={<Himalayas />} path="/himalayas" />
      <Route element={<IslandsandBeaches />} path="/TierreisenUndSafari" />
      <Route element={<WhyIndiaUnlimited />} path="/whyindiaunlimited" />
      <Route element={<OurTeam />} path="/UnserTeam" />

      <Route
        element={<PrivateTour />}
        path="/IndividuelleUndMaBgeschneiderteReisen"
      />
      <Route element={<IndiaTravelNotes />} path="/IndienReiseberichte" />
      <Route element={<Covid19 />} path="/Covid19faq" />
      <Route element={<Faq />} path="/faqs" />
      <Route element={<TermsConditions />} path="/Geschaftsbedingungen" />
      <Route element={<PrivacyPolicy />} path="/DatenschutzBestimmungen" />

      <Route element={<Blog />} path="/IndienReiseblog" />
      <Route element={<Blogdetails />} path="/blog-details/:id" />
      <Route element={<TourPackage />} path="/TourPaket/:id" />
      <Route element={<Profile />} path="/login" />
      <Route element={<CityDetails />} path="/city-details/:id" />
      <Route element={<ViewCity />} path="/View-city" />
    </Routes>
  );
}

export default App;
