import React, { useEffect } from "react";

import Card from "react-bootstrap/Card";
import { Button } from "bootstrap";
import DestinationSlider from "../components/DestinationSlider";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getblogdetails } from "../redux/actions/blogaction";
import { useParams } from "react-router-dom";
import Newsletter from "../components/Newsletter";
import IndiaUnlimited from "../components/IndiaUnlimited";
import moment from "moment";

const Blogdetails = () => {
  const blogdetailsdata = useSelector((state) => state.allBlogs?.blogdetails);
  console.log("blogdetailsdata------------", blogdetailsdata);
  let { id } = useParams();
  console.log("id is", id);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getblogdetails(id));
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="title common-heading text-success ">
                {blogdetailsdata?.title}
              </h1>
              <p className="fst-italic title">
                {blogdetailsdata?.short_description}
              </p>
              <i className="far fa-clock text-success mb-3 ">
                <b className="ps-2">   {moment(blogdetailsdata).format("DD MMMM YYYY")}</b>
              </i>
           
            </div>
            <div className="col-12 text-center ">
              <div>
                <img
                  // src="https://res.cloudinary.com/enchanting/f_auto,q_70,w_768,h_512,c_fill/et-web/2021/03/Wildlife-on-the-Chobe-River-in-Chobe-National-Park-in-Botswana.jpg"
                  src={blogdetailsdata?.image}
                  className=" rounded-top w-100 px-3"
                  alt=""
                  style={{ maxHeight: "600px", objectFit: "cover" }}
                />
              </div>
              <div className="post-details__author mt-3">
                Author: {blogdetailsdata?.author}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container px-5">
          <div className="row">
            <p>{blogdetailsdata?.description}</p>

            {/* <div className="row">
              <div className="col-md-12  common-heading pb-5">
                <p className="title mb-0">
                  Verantwortungsvolles Reisen im unglaublichen Indien
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* <div className="container-fluid recommended-tours wildlife-tours text-center">
        <DestinationSlider />
      </div> */}

      <IndiaUnlimited/>
      <section className="our-achievement bg-black">
        <div className="container-fluid px-md-5">
          <div className="row">
            <div className="col-md-12 text-center common-heading pb-5">
              {/* <p className="title mb-0 text-green">Lorem ipsum</p> */}
              <h2 className="text-white">Unser Erfolg</h2>
            </div>
          </div>
          <div className="row">
            <div className=" col-sm-6 col-lg-3 my-3">
              <div className="d-flex justify-content-center">
                <div>
                  <img
                    src="/assets/images/our-achievement/icon-light-1.png"
                    alt="achievment img"
                  />
                </div>
                <div className="text-info">
                  <h6>58</h6>
                  <p>Reiseziel in Indien</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 my-3">
              <div className="d-flex justify-content-center">
                <div>
                  <img
                    src="/assets/images/our-achievement/icon-light-2.png"
                    alt="achievment img"
                  />
                </div>
                <div className="text-info">
                  <h6>42</h6>
                  <p>Reiserouten</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 my-3">
              <div className="d-flex justify-content-center">
                <div>
                  <img
                    src="/assets/images/our-achievement/icon-light-3.png"
                    alt="achievment img"
                  />
                </div>
                <div className="text-info">
                  <h6>453</h6>
                  <p>Zufriedene Kunden</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 my-3">
              <div className="d-flex justify-content-center">
                <div>
                  <img
                    src="/assets/images/our-achievement/icon-light-4.png"
                    alt="some image"
                  />
                </div>
                <div className="text-info">
                  <h6>24</h6>
                  <p>Immer verfügbar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Newsletter />

      <Footer />
    </>
  );
};

export default Blogdetails;
