import { ActionTypes } from "../constants/actiontypes";

const initialState = {
  blogs: [],
  featuredblogs: [],
  // relatedthemes: [],
  blogdetails: [],
};

export const loadblogsReducer = (state = initialState, { type, payload }) => {
  // console.log("sel blog -----------", type, payload);
  switch (type) {
    case ActionTypes.SET_BLOG_DATA:
      return { ...state, blogs: payload };
    case ActionTypes.SET_FEATURED_BLOG_DATA:
      return { ...state, featuredblogs: payload };
    // case ActionTypes.SET_RELATED_TRAVEL_THEMES_DATA:
    //   return { ...state, relatedthemes: payload };
    case ActionTypes.SET_BLOG_DETAILS:
      return { ...state, blogdetails: payload };
    default:
      return state;
  }
};
export const loadfeaturedblogsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_FEATURED_BLOG_DATA:
      return { ...state, featuredblogs: payload };
    default:
      return state;
  }
};
// export const loadrelatedthemeReducer = (state = initialState, { type, payload }) => {
//   switch (type) {
//     case ActionTypes.SET_RELATED_TRAVEL_THEMES_DATA:
//       return { ...state, blogs: payload };
//     default:
//       return state;
//   }
// };
