import axios from "axios";
import { BASE_URL } from "../../service/url";
import { ActionTypes } from "../constants/actiontypes";

export const gettourpackage = (id) => async (dispatch) => {
  try {
    console.log("tour call-----", id);
    const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettour/${id}`);

    const { data, success } = response.data;
    console.log("response.data.result----->>", response.data.result);

    if (success) {
      dispatch({
        type: ActionTypes.SET_TOUR_PACKAGE,
        // payload: data,
        payload: response.data.result,
      });
    }

    // dispatch({
    //   type: ActionTypes.SET_TOUR_PACKAGE,
    //   payload: data,
    // });
  } catch (error) {
    console.log("err===", error);
  }
};
// export const getblogdetails = () => async (dispatch) => {
//   try {
//     const response = await axios.get(
//       `${BASE_URL}/blog/getblog/62de86f47cbdeb57cd6631be`
//     );
//     const { data } = response.data;
//     // console.log(data);

//     dispatch({
//       type: ActionTypes.SET_BLOG_DETAILS,
//       payload: data,
//     });
//   } catch (error) {
//     console.log("err===", error);
//   }

//   // const blogs = res.data.data;

//   // setLoading(false);
//   // dispatch(blogdata(blogs));
// };

// export const loadfeaturedblog = () => async (dispatch) => {
//   try {
//     const response = await axios.get(`${BASE_URL}/blog/getfeaturedblog`);
//     const { data } = response.data;
//     console.log("----------------", data);

//     dispatch({
//       type: ActionTypes.SET_FEATURED_BLOG_DATA,
//       payload: data,
//     });
//   } catch (error) {
//     console.log("err===", error);
//   }

//   // const blogs = res.data.data;

//   // setLoading(false);
//   // dispatch(blogdata(blogs));
// };

// export const loadrelatedtraveltheme = () => async (dispatch) => {
//   let obj = {
//     traveltheme: "north india",
//   };
//   try {
//     const response = await axios.post(
//       `${BASE_URL}/blog/getrelatedtraveltheme`,
//       obj
//     );
//     const { data } = response.data;
//     console.log("----------------", data);

//     dispatch({
//       type: ActionTypes.SET_RELATED_TRAVEL_THEMES_DATA,
//       payload: data,
//     });
//   } catch (error) {
//     console.log("err===", error);
//   }

//   // const blogs = res.data.data;

//   // setLoading(false);
//   // dispatch(blogdata(blogs));
// };
