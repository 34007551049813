import { React, useState, useEffect } from "react";
import CitySection from "../components/CitySection";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

const Adventure = () => {
  const [tourdata, setTourdata] = useState();
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };
  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      traveltheme:"Aktivurlaub",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package-theme`, obj);
      console.log("theme data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
  // --------city data APi

  const [citydata, setCitydata] = useState();
  const [noOfCity, setNoOfCity] = useState(8);
  const cityslice = citydata?.slice(0, noOfCity);
  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data2 testing", response)
      const { result } = response.data;
      console.log(response.data, " City data2 testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  useEffect(() => {
    fetchFilterTour();
    fetchCity();
  }, []);

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top bg-about mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Aktivurlaub.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Aktivurlaub</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <p>
                    Planen sie eine reise voller abenteuer, oder markiere ein
                    abenteuerliches element auf Ihre reiseroute. Bei  India Unlimited
                    verstehen wir das dass abenteuer viele ebenen haben, und
                    dass die gäste unterschiedliche fähigkeiten haben und
                    bedürfnisse. Für manche eine fahrt mit dem heißluftballon
                    vor sonnenaufgang über das glitzern pagoden von bagan in
                    Myanmar macht den trick. Andere mögen einen adrenalinschub
                    suchen, kilometerlange seilrutsche durch die nebelwälder
                    costa ricas. Wieder andere wollen tagelang wandern zum
                    gipfel des Kilimandscharo, Tansania.
                  </p>
                  <p>
                    Wanderungen und geführte wandererlebnisse einen teil davon
                    bilden kann fast jeden urlaub. Gehen sie zum
                    gletschertrekking eine wandersafari unternehmen durch die
                    Überschwemmungsgebiete des sambesi in Sambia, oder erfreuen
                    sie sich an einer kleinen wanderung rund um den Fuß des
                    Vulkans Cotopaxi in Ecuador.
                  </p>
                  <p>
                    Abenteuertouren endlose möglichkeiten bieten. Sie können
                    dich leicht aus der ruhe bringen die ausgetretenen pfade
                    oder weit aus Ihrer komfortzone. Beginnen sie mit einem
                    helikopterflug über dem okavango delta in botswana.
                    Beschleunigen sie mit einem flug im ultraleichtflugzeug über
                    die Viktoriafälle an der Grenze zwischen Simbabwe und
                    Sambia. Und wenn sie eine echte herausforderung suchen,
                    folgen sie dem mit einem bungee-sprung in der nähe der
                    wasserfälle.
                  </p>
                  <p>
                    In Mittelamerika sie können einzigartige unterirdische
                    dolinen besuchen, und höhlen,wie die cenote Blue Hole in
                    belize. Weiter südlich, entdecken sie das leben in der
                    wildnis patagoniens auf einer reittour begleitet von
                    authentischen gauchos.
                  </p>
                  <p>
                    Wenn Sie einen langsamen Urlaub suchen in der sonne, sie
                    können immer noch die gesamte palette der
                    wassersportmöglichkeiten in betracht ziehen an Ihrem Ziel.
                    Tauchen, Schnorcheln, Standup-Paddle-Boarding und
                    Jetskifahren sind an stränden auf der ganzen Welt von der
                    karibik bis zu den Malediven verfügbar.
                  </p>
                  <p>
                    Radsportbegeisterte können eine gemütliche fahrt genießen
                    rund um die weinberge von stellenbosch in südafrika. Wenn
                    sie fitter sind und eine anstrengendere reise suchen, denken
                    sie an mountainbiken in den Tonkanesischen Alpen in Vietnam
                    oder entlang der Death Road in der nähe von la paz in
                    Bolivien.
                  </p>
                  <p>
                    Abenteuer bedeutet nicht unbedingt, dass Sie rau sind es mit
                    Unterkunft aus. Nach einer quad-fahrt durch die Kalahari in
                    Namibia oder einer Wildwasser-Rafting-Tour auf dem Ganges
                    bei Rishikesh in Indien können sie immer noch zu luxus und
                    leiblichem Komfort zurückkehren. Und all unsere Wildtier-
                    und Safari-abenteuer beinhalten auch die besten camps und
                    lodges.
                  </p>
                  <p>
                    Auch wenn Sie nur unter freiem himmel schlafen möchten mit
                    Sternen in einem bequemen bett mitten im afrikanischen
                    busch, waren. Was auch immer Ihre Neigung ist, wir sind
                    eingestimmt, und wir sorgen für eine ordentliche Dosis
                    frischer Luft und ein fix der freien natur.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE AUSFLÜGE FÜR ABENTEUER"
      />

<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default Adventure;
