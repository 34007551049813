import { React, useState, useEffect } from "react";

import Footer from "./Footer";
import Header from "./Header";

import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import CitySection from "../components/CitySection";
import axios from "axios";
import { BASE_URL } from "../service/url";
import DestinationTourCard from "../components/DestinationTourCard";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";


const CentralandWestIndia = () => {
  const [tourdata, setTourdata] = useState();
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTour();
  // }, []);
  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      category: "Central & West India",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package`, obj);
      console.log("Reson data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
  const [citydata, setCitydata] = useState();
  const [noOfCity, setNoOfCity] = useState(8);
  const cityslice = citydata?.slice(0, noOfCity);
  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data2 testing", response)
      const { result } = response.data;
      console.log(response.data, " City data2 testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  useEffect(() =>{
    fetchFilterTour();
    fetchCity()
  }, [])

 console.log("filterDataTour ......." , filterDataTour)

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top  mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Zentral Und Westindien Reisen.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1> Zentral Und Westindien Reisen</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row px-5">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <p>Tiger-Safaris,Goa-Strände, und Maximum City Mumbai</p>
                  <p>
                    West- und Zentralindien bieten eine reihe von reisemomenten:
                    tigersafaris zu wüstenlandschaften, zu Mumbais chaos. Und
                    während sie in der region sind, erkunden sie die
                    felsenarchitektur, paläste,und spirituelle seite auch.
                  </p>
                  <p>
                    Jenseits seiner kolonialen und art-deco-architektur,
                    pulsierendes Mumbai, mit seinen hektischen märkten und
                    endloses gedränge kann überwältigend sein.
                  </p>
                  <p>
                    Wir verstehen das. Deshalb die privaten Indien reisen von
                     India Unlimited führen Sie durch diese megacity im gemütlichem
                    tempo. Und zur entspannung danach, breite deine flügel nach
                    osten aus oder süden. Kaum eine flugstunde nach süden ist
                    Indiens entspannteste ecke, Goa, wo warmes wasser,
                    kokospalmen, und köstliche meeresfrüchte sind
                    selbstverständlich.
                  </p>
                  <p>
                    Wählen sie eine alternative ruhe durch reisen nach osten zu
                    den Nationalparks Zentralindiens, berühmt für Tigersafaris
                    und luxuriöse Zeltcamps. Aber jenseits dieses
                    apex-raubtiers, die dichten wälder und weitläufige wiesen
                    randvoll mit bunten vögeln, insekten, und eine menge anderer
                    Tiere.
                  </p>
                  <p>
                    Auch diese region ist geschichte- und traumziel für
                    architekturliebhaber, mit erotischen tempeln in Khajuraho,
                    und eine vielzahl von großen mittelalterlichen festungen und
                    paläste. Auf einer privaten Indien-tour, spirituell suchende
                    können auch zeit einbeziehen tempel zu erkunden und
                    hinduistische pilgerstätten entlang des heiligen flusses
                    Narmada.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE REISEN NACH ZENTRAL UND WESTINDIEN"
      />

<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
     
      <Footer />
    </div>
  );
};

export default CentralandWestIndia;
