import React from 'react'
import Footer from './Footer'
import Header from './Header'

const PrivacyPolicy = () => {
  return (
    <div>
        <Header />
        <div className="inner-banner-top bg-about mb-4">
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>

DATENSCHUTZ-BESTIMMUNGEN</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <section className='px-3'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col'>
                    <h3>
Datenschutz-Bestimmungen</h3>
                </div>
            </div>
            <div className='row'>
            <div className='col-md-12'>
                <p>
Ihre Privatsphäre ist uns sehr wichtig. Wir haben diese Richtlinie entwickelt, damit Sie besser verstehen, wie wir personenbezogene Daten erfassen, kommunizieren, offenlegen und verwenden..</p>
                </div>
                <div className='col-md-12'>
                    <h3>A. Für die USA und andere Nicht-EU-Länder Unsere Website</h3>
                </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <p>Die Begriffe „India Unlimited“ oder „Unternehmen“ oder „uns“ oder „wir“ beziehen sich auf die Eigentümer dieser Website. Der Begriff „Sie“ bezieht sich auf den Benutzer oder Betrachter unserer Website.</p>
                        <p>Diese Datenschutzrichtlinie („Richtlinie“) spiegelt die Verpflichtung des Unternehmens zum Schutz der Privatsphäre und personenbezogener Daten wider, die von Benutzern der Website bereitgestellt werden, und legt die Art und Art der Informationen fest, die das Unternehmen von Benutzern zum Zweck der Dienste, der Nutzung und Offenlegung sammelt solcher Informationen und die vom Unternehmen ergriffenen Sicherheitsmaßnahmen zum Schutz der Informationen.</p>
                        <p><strong>
Bitte Beachten Sie:</strong> Diese Richtlinie gilt nur für die Benutzer der Website und die auf der Website gesammelten Informationen und Daten und nicht für andere Informationen oder Websites. Es wird Ihnen hiermit empfohlen, diese Richtlinie sorgfältig zu lesen und die Art und den Zweck des Sammelns und/oder Sammelns sensibler, persönlicher und anderer Informationen sowie die Verwendung, Offenlegung und Weitergabe solcher Informationen vollständig zu verstehen. Indem Sie die Dienste anfordern und ihnen zustimmen, stimmen Sie hiermit auch zu, dass das Unternehmen Informationen an Drittanbieter weitergibt, die vom Unternehmen für die Zwecke der Dienste beauftragt wurden, und dass Benutzerinformationen und -daten grenzüberschreitend übertragen und/oder gespeichert werden.</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <h5>TDiese Richtlinie erklärt:</h5>
                        <ul>
                            <li>
Die Informationen, die wir möglicherweise online über Sie sammeln.</li>
                            <li>
Die Verwendung und Rolle von Cookies und ähnlichen Technologien auf unserer Website.</li>
                            <li>Wie wir Informationen, die wir über Sie und von Ihnen sammeln, schützen und verwenden.</li>
                            <li>Die Umstände, unter denen wir Ihre Daten an Dritte weitergeben könnten.</li>
                            <li>Wann wir Ihre Daten verwenden dürfen, um Sie zu kontaktieren.</li>
                            <li>So können Sie sicher sein, dass die Informationen, die wir über Sie gespeichert haben, korrekt und aktuell sind.</li>
                        </ul>
                    </div>
                </div>
            </div>
      </section>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
