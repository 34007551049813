import { ActionTypes } from "../constants/actiontypes";

const initialState = {
  detailed_review: [],
};

export const loadreviewdetails = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_REVIEW_DETAILS:
      return { ...state, detailed_review: payload };
    default:
      return state;
  }
};
