import { React, useState, useEffect } from "react";
import CitySection from "../components/CitySection";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";

import Footer from "./Footer";
import Header from "./Header";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
const NatureandLandscape = () => {
  const [tourdata, setTourdata] = useState();
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };
  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      traveltheme:"Alle Rundreisen Indien",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package-theme`, obj);
      console.log("theme data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
   // --------city data APi

   const [citydata, setCitydata] = useState();
   const [noOfCity, setNoOfCity] = useState(8);
   const cityslice = citydata?.slice(0, noOfCity);
   const fetchCity = async () => {
     try {
       const response = await axios.get(`${BASE_URL}/city_management/get-city`);
       console.log("city data2 testing", response)
       const { result } = response.data;
       console.log(response.data, " City data2 testing laksdjflkajdsflk");
       setCitydata(result);
     } catch (error) {
       console.log("err===", error);
     }
   };

  useEffect(() => {
    fetchFilterTour();
    fetchCity();
  }, []);

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top bg-about mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Alle Rundreisen indien.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Alle Rundreisen Indien</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <p>
                    On your beach vacation, a warm fuzziness envelops you as
                    soon as you catch sight of tranquil blue waters lapping
                    silken sands. Your taste buds tingle with sublime flavors of
                    coconut and local seafood. Watch a brilliant orange sunset
                    as you sip a drink with your toes burrowing into the sand.
                  </p>
                  <p>
                    Enchanting Travels experts understand that a beach is not
                    just a beach. Some like it hot, and some like it cold. You
                    may prefer sandy shores touched by cool Atlantic waters
                    around Cape Town, South Africa or warm tropical waters off
                    the Mozambique coast. Take your pick from a secluded private
                    stretch of beach, or one with a pier and boardwalk, and a
                    restaurant and party scene.
                  </p>
                  <p>
                    Luxurious accommodation with modern amenities forms the base
                    of a beach holiday. We will find you a fabulous five-star
                    hotel, retro cliff-top cabin, or private villa with floor-to
                    ceiling windows. Hold still though, because we also know
                    that a beach vacation is much more than hours spent on a
                    sun-lounger sipping mai tais.
                  </p>
                  <p>
                    Let the wonderful creatures of the sea become an integral
                    part of your beach vacation. Hop on a whale and dolphin
                    watching tour on the Sri Lankan coast. Or get up close and
                    personal as you swim with dolphins in Mauritius or with
                    turtles and colorful tropical fish in the coral-filled
                    waters of Indonesia or the Maldives. Water sports that suit
                    every level of challenge and fitness are yours for the
                    asking.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE AUSFLÜGE FÜR NATUR UND LANDSCHAFT"
      />

<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
     
      <Footer />
    </div>
  );
};

export default NatureandLandscape;
