import { React, useState } from "react";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";

const RelaxationandSpa = () => {
  const [displayForm, setDisplayForm] = useState(true);
  const handleClick = () => {
    setDisplayForm(!displayForm);
  };

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top  mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/luxury india.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Indien Luxus Reisen</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5">
                <div className="beaches-article d-flex flex-column justify-content-center">
                  <p>
                    Luxusreisen nach Indien bieten eine reiche geschichte, farbe
                    und charme für alle die sich in ihren grenzen aufhalten.
                  </p>
                  <p>
                    Von königlichen palästen zu heiligen schreinen, entdecker
                    werden nie enttäuscht. Ob Sie ein Liebhaber von farbe und
                    gewürzen sind, Indien hat unendlich viele Dinge auf Lager.
                    Oder architektonische pracht, Luxusreisen in Indien sind
                    überfüllt mit unermesslichem reichtum. palaste, basare, und
                    flussboote warten, bereit, diese zu belohnen die für eine
                    woche voller exotischer Überraschungen landen, oder steil
                    für einen monat oder länger in den wundern des sstens.
                  </p>
                  <p>
                    Hansa India sind besonders magisch. Indien bietet
                    unglaubliche luxusunterkünfte mit servicestandards und eine
                    opulente umgebung, die dazu passt. Mit den jüngsten
                    Investitionen des landes in ihrer verkehrsinfrastruktur,
                    reisen durch ganz Indien war noch nie einfacher- keine
                    überfüllten züge und busse mehr. Mit Direktflügen zu allen
                    wichtigen Drehkreuzen, Luxusreisen nach Indien ist so
                    einfach wie faszinierend. Hansa India hat unglaubliche
                    privatjet-optionen auch innerhalb Indiens. Sie können wie
                    ein moderner Maharadscha reisen und ankommen und abreisen,
                    wo und wann immer sie möchten. Das ist der unterschied
                    zwischen Hansa India.
                  </p>
                  <p>
                    Besuchen Sie geschäftige städte, malerische strände,
                    majestätische berge, oder die Strecke der ikonischen Paläste
                    im Herzen der kulturellen Geschichten Indiens. Wandeln sie
                    auf den Spuren von Heiligen und Fürsten während du ein Land
                    erkundest, das vor Leben nur so strotzt, sowohl heilig als
                    auch irdisch.
                  </p>
                  <p>
                    Nicht alle Schätze Indiens sind solche, die man in palästen
                    findet.Herzliche gastfreundschaft und interessante kultur in
                    der Hansa India stil von tradition und luxus. Indiens
                    einzigartige kultur und günstige lage helfen Sie ihm, sich
                    gut mit anderen asiaten zu paaren, ozeanisch, und Ziele im
                    Nahen Osten. Wenden Sie sich an unsere fachberater, um Ihre
                    perfekte Indien-luxusreise zu planen.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row p-auto">
            <div className="col-sm-12">
              <h4>POPULAR TRIPS TO NORTH INDIA</h4>
            </div>
          </div>

          <div className="row recommended-tours wildlife-tours text-center">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-6 col-md-4 py-3 px-2">
                  <TravelCard
                    Destination="4 Days Bandhavgarh Tiger Safari"
                    Days="10"
                    Perperson="10,000"
                    Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                    LinkTo="tour-package"
                    image="assets/images/recommended-rours/recommended-img1.jpg"
                    className="w-100"
                  />
                </div>
                <div className="col-sm-6 col-md-4 py-3">
                  <TravelCard
                    Destination="4 Days Bandhavgarh Tiger Safari"
                    Days="10"
                    Perperson="10,000"
                    Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                    LinkTo="tour-package"
                    image="assets/images/recommended-rours/recommended-img3.jpg"
                    className="w-100"
                  />
                </div>
                <div className="col-sm-6 col-md-4 py-3">
                  <TravelCard
                    Destination="4 Days Bandhavgarh Tiger Safari"
                    Days="10"
                    Perperson="10,000"
                    Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                    LinkTo="tour-package"
                    image="assets/images/recommended-rours/recommended-img1.jpg"
                    className="w-100"
                  />
                </div>
              </div>

              <hr />
              <section
                class="travellers-review bg-pattern-2"
                style={{
                  background: "url(/assets/images/travel-agency-bg.jpg)",
                }}
              >
                <div class="container-fluid px-md-5">
                  <div class="row">
                    <div class="col-md-12 text-center common-heading pb-5">
                      <p class="title mb-2">Referenzen</p>
                      <h2>Was unsere Gäste über uns sagen</h2>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <ReviewCardSlider />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default RelaxationandSpa;
