import { SET_BLOG_LIST , SET_BLOG_SUCCESS , SET_BLOG_FAILURE } from '../constants/newtype' ;
const initialState = {
    // loading:false,
    blog:[],
    error:"",
}

const blogReducer = (state = initialState, action) => {
  // console.log(state, "reducer laksdjflksa fljsafdlksadfj")
    switch (action.type) {
      case SET_BLOG_LIST:
        return {
          ...state,
          // loading: true,
        };
      case SET_BLOG_SUCCESS:
        return {
          // loading: false,
          blog: action.payload,
          error: "",
        };
      case SET_BLOG_FAILURE:
        return {
          // loading: false,
          blog: [],
          error: action.payload,
        };
      default:
        return state;
    }
}

    export default blogReducer;