import React, { useState } from "react";
import DestinationSlider from "../components/DestinationSlider";
import BlogSlider from "../components/BlogSlider";
import TopDestinationSlider from "../components/TopDestinationSlider";
import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { postquerydata } from "../redux/actions/queryaction";
import PhoneInput from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { fieldErrors } from "../comman/err.message";
import BlogArticle from "../components/BlogArticle";
import { loaddatafrombackend } from "../redux/actions/blogaction";
import { gettourpackage } from "../redux/actions/tourpackageaction";
import Newsletter from "../components/Newsletter";
import { fetchBlog } from "../redux/actions/actionBlog";
import axios from "axios";
import { BASE_URL } from "../service/url";
import CitySection from "../components/CitySection";
import IndiaUnlimited from "../components/IndiaUnlimited";
import { BsArrowRightShort } from "react-icons/bs";


const validationSchema = Yup.object().shape({
  phoneNo: Yup.number(),
  firstname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .matches(/^\S*$/, "Whitespace is not allowed")
    .required("Required*"),
  lastname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  email: Yup.string().email("Invalid Email").required("Required*"),
  country: Yup.string(),
});

const Home = () => {
  const [blogdata, setBlogdata] = useState();
  const [tourdata, setTourdata] = useState();
  const [citydata, setCitydata] = useState();
  const [queryModal, setQueryModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [queryalert, setQueryalert] = useState(false);
  const dispatch = useDispatch();
  const [bannerfield, setBannerField] = useState({
    budgetperperson: "",
    destination: "",
    numberoftravellers: "",
    theme: "",
  });

  const handlePhoneChange = (value) => setPhone(value);
  const navigate = useNavigate();
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const handlesetBannerField = (input) => {
    let name = input.target.id;
    let newobject = bannerfield;
    newobject[name] = input.target.value;
    console.log("newobject", newobject);
    setBannerField(newobject);
  };

  console.log("bannerfield", bannerfield);

  // const blogdata = useSelector((state) => state?.bloghome?.blog);
  // console.log(blogdata, "testing blog data ")
  // const blogdata = useSelector((state) => state?.allBlogs);

  const tourpackagedata = useSelector((state) => state?.alltours?.tours);
  // console.log("the blogsdata is 000000000000000000", blogdata);
  console.log("the toursdata is 111111111111111111", tourpackagedata);
  // const visibleblogs = blogdata;

  // console.log("after spice", blogdata);
  // number and text validation
  const onlyTextAllowed = (e) => {
    const re = /^[a-zA-Z]+$/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  const onlyNumberAllow1 = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const queryForm = {
    firstname: "",
    lastname: "",
    email: "",
    phoneNo: "",
    country: "",
    tripduration: "",
    stage: "",
    description: "",
  };

  const userSubmit = (formdata) => {
    console.log("onusersubmit function", formdata);
    const queryForm = {
      firstname: formdata?.firstname ? formdata?.firstname : "",
      lastname: formdata?.lastname ? formdata?.lastname : "",
      email: formdata?.email ? formdata?.email : "",
      phoneNo: phone,
      country: formdata?.country ? formdata?.country : "",
      tripduration: formdata?.tripduration ? formdata?.tripduration : "",
      stage: formdata?.stage ? formdata?.stage : "",
      description: formdata?.description ? formdata?.description : "",
      // destination: formdata?.destination ? formdata?.destination : "",
      // theme: formdata?.theme ? formdata?.theme : "",
      // numberoftravellers: formdata?.numberoftravellers
      //   ? formdata?.numberoftravellers
      //   : "",
      // budgetperperson: formdata?.budgetperperson
      //   ? formdata?.budgetperperson
      //   : "",
      ...bannerfield,
    };
    console.log("queryform is", queryForm);
    // axios({
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   url: "http://localhost:8000/query/addquery",
    //   data: JSON.stringify(queryForm),
    // })
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((error) => console.log(error));
    dispatch(postquerydata(queryForm, setQueryalert));
  };
  const handlebuttonclick = () => {
    navigate("/IndienReiseblog");
  };
  const successAdditionOfCategoryAlert = (title, icon) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        setQueryModal(false);
        window.location.reload();
      } else {
        setQueryModal(true);
      }
    });
  };
  useEffect(() => {
    dispatch(gettourpackage());

    if (queryalert) {
      successAdditionOfCategoryAlert("Submitted", "success");
    }
  }, [queryalert]);

  // useEffect(() => {
  //   // dispatch(loaddatafrombackend());
  //   dispatch(fetchBlog());
  // }, []);

  const fetchBlog = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/blog/getall`);
      const { data } = response.data;
      console.log(data, "blog data testing laksdjflkajdsflk");
      setBlogdata(data);
    } catch (error) {
      console.log("err===", error);
    }
  };

  const fetchTour = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
      const { result } = response.data;
      console.log(response.data, "tour data testing laksdjflkajdsflk");
      setTourdata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data testing", response)
      const { result } = response.data;
      console.log(response.data, " City data testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

 

  useEffect(() => {
    fetchBlog();
    fetchTour();
    fetchCity();
  }, []);

  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /^[a-zA-Z]+$/g;
    if (!regex.test(key)) {
      e.preventDefault();
    } else {
      console.log("You pressed a key: " + key);
    }
  };


  

  const [noOfElement, setNoOfElement] = useState(8);
  const cityslice = citydata?.slice(0, noOfElement);
  const[articls,setArticls]= useState(4);
  const blogslice = blogdata?.slice(0, articls);

  return (
    <>
      <Header />
      <div>
        <section className="banner-hero pt-0">
          <div className="position">
            <video autoPlay muted loop id="myVideo" className="w-100">
              <source
                src="/assets/images/video/Video-Banner.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="container">
            <div className="row">
              <div className="banner-info">
                <div className="text-bg text-center">
                  <h1>Explore the real India</h1>
                  <p>Tradition, Beauty & Culture</p>
                </div>
              </div>
              <div className="banner-logo">
                <div className="d-flex">
                  {/* <div>
                    <img src="assets/images/trustpilot.png" alt="" />
                  </div> */}
                  {/* <div>
                    <img
                      className="pl-4"
                      src="assets/images/recommended.png"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row " id="in">
              <div className="col-lg-10 mx-auto">
                <div className="search-box bg-none">
                  <form className="needs-validation">
                    <div className="form-row g-0">
                      <div className="input-group mb-3 col-xl col-md-3 px-0 rounded-0">
                        <select
                          className="form-select rounded-0"
                          id="destination"
                          // value={bannerfield.destination}
                          onChange={(e) => handlesetBannerField(e)}
                        >
                          <option>Wohin möchten Sie Reisen?</option>
                          <option value="North India">
                            Nordindien Reisen{" "}
                          </option>
                          <option value="South India">Südindien Reisen</option>
                          <option value="Central and West India">
                            Zentral Und Westindien Reisen
                          </option>
                          <option value="East India">
                            {" "}
                            Nordosten Indien Reisen{" "}
                          </option>
                          <option value="Himalaya">Himalaya </option>
                          <option value="Islands and Beaches">
                            Tierreisen Und Safari{" "}
                          </option>
                        </select>
                      </div>
                      <div className="input-group mb-3 col-xl col-md-3 px-0 rounded-0">
                        <select
                          className="form-select rounded-0"
                          id="theme"
                          // value={bannerfield.theme}
                          onChange={(e) => handlesetBannerField(e)}
                        >
                          <option selected>Reiseinteresssen Wählen?</option>
                          <option value="Adventure">Aktivurlaub</option>
                          <option value="Heritage and Culture">
                            Land Und Leute Indien{" "}
                          </option>
                          <option value="Ayurveda">
                            Ayurveda Und Yoga Reisen
                          </option>
                          <option value="Relaxation and Spa">
                            Indien Luxus Reisen
                          </option>
                          <option value="Nature and Landscape">
                            Alle Rundreisen Indien
                          </option>
                          <option value="wildlife">
                            Tierreisen & Safaris{" "}
                          </option>
                          <option value="Beaches">
                            Badeferien & Strandurlaub In Indien
                          </option>
                        </select>
                      </div>
                      <div className="input-group mb-3 col-xl col-md-3 px-0 rounded-0">
                        <select
                          className="form-select rounded-0"
                          id="numberoftravellers"
                          // value={bannerfield.numberoftravellers}
                          onChange={(e) => handlesetBannerField(e)}
                        >
                          <option selected>Anzahl Reisende ?</option>
                          <option value="1">1 Reisende </option>
                          <option value="2">2 Reisende </option>
                          <option value="3">3 Reisende </option>
                          <option value="4">4 Reisende </option>
                          <option value="5">4+ Reisende </option>
                        </select>
                      </div>
                      <div className="input-group mb-3 col-xl col-md-3 px-0 rounded-0">
                        <select
                          className="form-select rounded-0"
                          id="budgetperperson"
                          // value={bannerfield.budgetperperson}
                          onChange={(e) => handlesetBannerField(e)}
                        >
                          <option>Budget Pro Person</option>
                          <option value="500-1000">
                            500&euro; - 1000&euro;
                          </option>
                          <option value="1000-2000">
                            1000&euro; -2000&euro;
                          </option>
                          <option value="2000-3000">
                            2000&euro; - 3000&euro;
                          </option>
                          <option value="3000+">Above 3000&euro;</option>
                        </select>
                      </div>
                      <div className="col-xl col-md-3 px-0 rounded-0 mb-3">
                        <button
                          type="button"
                          className="btn btn-success w-100 rounded-0 hh"
                          onClick={() => {
                            setQueryModal(true);
                          }}
                        >
                          Angebot Anfragen
                        </button>
                        {queryModal && (
                          <Modal
                            show={queryModal}
                            onHide={() => {
                              setQueryModal(false);
                            }}
                          >
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                              <Formik
                                initialValues={queryForm}
                                onSubmit={userSubmit}
                                validationSchema={validationSchema}
                              >
                                {({
                                  values,
                                  handleChange,
                                  handleSubmit,
                                  errors,
                                  touched,
                                }) => (
                                  <form onSubmit={handleSubmit}>
                                    <div className="form-row">
                                      <div className="col-md-6 form-group">
                                        <input
                                          type="text"
                                          id="firstname"
                                          name="firstname"
                                          value={values.firstname}
                                          onChange={handleChange}
                                          className="form-control custom-height shadow-sm"
                                          placeholder="Vorname"
                                          maxLength={30}
                                          onKeyPress={(e) => handleKeyPress(e)}
                                        />
                                        <div id="name_error" />
                                        <span
                                          style={{
                                            color: "tomato",
                                            "font-size": "small",
                                          }}
                                        >
                                          {errors.firstname &&
                                            touched.firstname && (
                                              <div>{errors.firstname}</div>
                                            )}
                                        </span>
                                      </div>
                                      <div className="col-md-6 form-group">
                                        <input
                                          type="text"
                                          id="lastname"
                                          name="lastname"
                                          value={values.lastname}
                                          onChange={handleChange}
                                          className="form-control custom-height shadow-sm"
                                          placeholder="Nachname"
                                          maxLength={30}
                                          onKeyPress={(e) => handleKeyPress(e)}
                                        />
                                        <span
                                          style={{
                                            color: "tomato",
                                            "font-size": "small",
                                          }}
                                        >
                                          {errors.lastname &&
                                            touched.lastname && (
                                              <div>{errors.lastname}</div>
                                            )}
                                        </span>
                                      </div>
                                      <div className="col-md-6 form-group">
                                        <input
                                          type="email"
                                          id=" email"
                                          value={values.email}
                                          onChange={handleChange}
                                          name="email"
                                          className="form-control custom-height shadow-sm"
                                          placeholder="Email*"
                                        />
                                        <div id="email_error" />
                                        <span
                                          style={{
                                            color: "tomato",
                                            "font-size": "small",
                                          }}
                                        >
                                          {errors.email && touched.email && (
                                            <div>{errors.email}</div>
                                          )}
                                        </span>
                                      </div>
                                      <div className="col-md-6 form-group">
                                        <PhoneInput
                                          placeholder="Telefon-Nr"
                                          id=" phoneNo"
                                          name=" phoneNo"
                                          value={phone}
                                          // onChange={(e) => handlePhoneChange(e)}
                                          onChange={setPhone}
                                          className=" form-control d-flex  custom-height shadow-sm"
                                        />
                                        <div id="phone_number_error" />
                                        <span
                                          style={{
                                            color: "tomato",
                                            "font-size": "small",
                                          }}
                                        >
                                          {errors.phoneNo &&
                                            touched.phoneNo && (
                                              <div>{errors.phoneNo}</div>
                                            )}
                                        </span>
                                      </div>
                                      {/* <div className="col-md-6 form-group">
                        <input type="text" name="country" className="form-control custom-height shadow-sm"  placeholder="<%= translate.form_country%>">
                        <div id="country_error"></div>  
                     </div> */}
                                      <div className="col-md-6 form-group">
                                        <select
                                          className="form-select custom-height shadow-sm"
                                          id="country"
                                          name="country"
                                          value={values.country}
                                          onChange={handleChange}
                                        >
                                          <option>Land Auswählen</option>
                                          <option value="india">India</option>
                                          <option value="germany">
                                            Germany
                                          </option>
                                          <option value="france">France</option>
                                        </select>
                                        <span
                                          style={{
                                            color: "tomato",
                                            "font-size": "small",
                                          }}
                                        >
                                          {errors.country &&
                                            touched.country && (
                                              <div>{errors.country}</div>
                                            )}
                                        </span>
                                      </div>
                                      <div className="col-md-6 form-group">
                                        <input
                                          type="text"
                                          className="form-control custom-height shadow-sm"
                                          placeholder="Reisedauer In Tagen"
                                          id="tripduration"
                                          name="tripduration"
                                          value={values.tripduration}
                                          onChange={handleChange}
                                          onKeyPress={(e) =>
                                            onlyNumberAllow1(e)
                                          }
                                        />
                                        <div id="name_error" />
                                      </div>
                                      <div className="col-md-12 form-group">
                                        <select
                                          className="form-select custom-height shadow-sm"
                                          id="stage"
                                          value={values.stage}
                                          onChange={handleChange}
                                        >
                                          <option>
                                            Welche Phase Sie Planen
                                          </option>
                                          <option value="Still dreaming/ Researching">
                                            Ich träume immer noch/forsche
                                          </option>
                                          <option value="Definitely travelling not sure which part of India">
                                            Ich bin mir auf jeden Fall nicht
                                            sicher, in welchen Teil Indiens
                                          </option>
                                          <option value="I want to Book my trip">
                                            Ich möchte meine Reise buchen
                                          </option>
                                        </select>
                                      </div>
                                      <div className="form-group col-12">
                                        <textarea
                                          className="form-control shadow-sm"
                                          id="description"
                                          name="description"
                                          placeholder="Beschreibung"
                                          value={values.description}
                                          onChange={handleChange}
                                          rows={5}
                                          defaultValue={""}
                                        />
                                        <div id="message_error" />
                                      </div>
                                      <div className="col-md-6 form-group">
                                        <input
                                          type="hidden"
                                          name="inquiry_status"
                                          defaultValue="send_query"
                                          className="form-control custom-height shadow-sm"
                                        />
                                      </div>
                                    </div>
                                    <div className="custom-btn col-12 px-2">
                                      <button
                                        type="submit"
                                        className="btn btn-success w-100 py-2"
                                      >
                                        Abschicken &nbsp;
                                        <i className="fas fa-paper-plane" />
                                      </button>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </Modal.Body>
                          </Modal>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </section>
        <section className="search-input bg-pattern-1 pt-0">
          <div className="container-fluid px-md-5 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 text-center common-heading pb-5">
                <h2 className="text-success"> INDIA UNLIMITED</h2>
                {/* <p className="title mb-0 "> Indien Individualreisen</p> */}
              </div>
              <div className="col-lg-6 col-sm-12 mb-sm-3">
                <img
                  className="img-fluid w-100 rounded inner-img "
                  // src="/assets/images/Photos India Unlimited/2.jpg"
                  src="/assets/images/Photos India Unlimited/Humayun's Tomb, New Delhi, India_shutterstock_120787414.jpg"
                  // src="/assets/images/home/photos.jpg https://img.freepik.com/free-vector/flat-india-independence-day-illustration_52683-65485.jpg?w=996&t=st=1661000608~exp=1661001208~hmac=35334cff650aacb288d197214eae152e51b175a766853c548f234fe6016b23a3"
                  alt
                />
              </div>
              <div className="col-lg-6 col-sm-12 py-2  ">
                <div className="px-md-4 about-text custom-scrolbar ">
                  <p className="bold-text about-pera">
                    Namaste und Herzlichen Willkommen in Indien - India
                    Unlimited Reisen ist Spezialist für individuelle und private
                    Reisen in Indien. Eine Reise nach Inden ist mit Sicherheit
                    ein Abenteuer. Das liegt in der Natur dieses Landes
                    begründet, das gleichzeitig verblüffend und inspirierend,
                    unvollkommen und unglaublich ist.
                  </p>

                  {/* <span className="bold-text">
                      Best Proficiency of ourIndian Travel Agents in London UK,
                    </span>{" "} */}
                  {/* <span className="bold-text">
                      Booking an adventure tour to India with an adventure
                    </span>{" "} */}
                  <p className="bold-text ">
                    Von Norden Indien bis Süden Indien ist die Geografie Indiens
                    fesselnd. In den Bergstationen in Himachal Pradesh wacht man
                    mit Blick auf die schneebedeckten Gipfel des mächtigen
                    Himalaya auf. Die indische Halbinsel hat eine 4.600 Meilen
                    lange Küstenlinie mit einer Vielzahl von Sehenswürdigkeiten:
                    Die Sandstrände von Goa, die Mangroven der Sundarbans, die
                    historischen Tempel am Meer in Mamallapuram. Und dann ist da
                    noch Kanyakumari, die südlichste Spitze Indiens, wo das
                    Arabische Meer, der Indische Ozean und der Golf von Bengalen
                    aufeinandertreffen.
                  </p>

                  <p>
                    Vielleicht haben Sie schon einmal das Klischee gehört, dass
                    Indien das reinste Chaos ist, und vielleicht ist es das
                    auch. Aber der Wahnsinn hat Methode. Überlassen Sie die
                    Entdeckung dieses Musters den Experten von India Unlimited,
                    die Erfahrung in der Organisation privater Reisen nach
                    Indien haben. Alles, was Sie tun müssen, ist, Vertrauen zu
                    haben, sich zu amüsieren und viel Chai zu trinken.
                  </p>
                  <p>
                    Egal, in welche Richtung Sie reisen, die Geschichte Indiens,
                    die sich in den prächtigen Monumenten widerspiegelt, wird
                    Sie in Ehrfurcht und Demut versetzen. Im südlichen
                    Bundesstaat Tamil Nadu werden Sie Zeuge von Religiosität und
                    Ritualen im Meenakshi-Tempel von Madurai. Gehen Sie nach
                    Westen, wo Rajasthans Mehrangarh Fort, das über der Stadt
                    Jodhpur thront, sowohl Palast als auch Symbol einer
                    mittelalterlichen Militärtradition ist. In Nordindien werden
                    die glorreichen Tage des Mogulreiches in den Monumenten von
                    Orten wie Fatehpur Sikri und Delhi lebendig. Planen Sie
                    mindestens zwei Wochen ein, um einige der Wunder dieses
                    bemerkenswerten Landes zu erleben, auch wenn Sie im Moment
                    eigentlich nur das Taj Mahal besuchen möchten.
                  </p>
                </div>
                {/* <div className="custom-button pt-3">
                  <button className="btn button-green border-success text-success">
                    Angebot Anfragen
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <section className="banner-2 pt-0">
          <div className="container-fluid px-0">
            <div className="col-md-12 px-0">
              <img
                className="img-fluid w-100"
                src="assets/images/bannerhome.jpg"
                alt="banner-2"
              />
            </div>
          </div>
        </section>
        <section className="destination pt-0">
          <div className="container-fluid px-md-5">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xsm-12 text-center common-heading pb-5">
                {/* <p >What You Like!</p> */}
                <h1 className="title mb-0 fw-light">
                  {" "}
                  Wie Sieht Ihre Traumreise Aus
                </h1>
              </div>
            </div>
            <TopDestinationSlider />
          </div>
        </section>
    {/* <section className="recommended-tours bg-pattern-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center common-heading pb-5">
                <p className="title mb-2"> Indien Erleben</p>
                <h2>Das Vielfältige Land Der Farben Und Mystik Erleben</h2>
              </div>
             <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6 col-md-4 py-3 px-2">
                      <TravelCard
                        Destination="4 Days Bandhavgarh Tiger Safari"
                        Days="10"
                        Perperson="10,000"
                        Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                        LinkTo="tour-package"
                        image="assets/images/recommended-rours/recommended-img1.png"
                        className="w-100"
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 py-3">
                      <TravelCard
                        Destination="4 Days Bandhavgarh Tiger Safari"
                        Days="10"
                        Perperson="10,000"
                        Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                        LinkTo="tour-package"
                        image="assets/images/recommended-rours/recommended-img3.png"
                        className="w-100"
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 py-3">
                      <TravelCard
                        Destination="4 Days Bandhavgarh Tiger Safari"
                        Days="10"
                        Perperson="10,000"
                        Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                        LinkTo="tour-package"
                        image="assets/images/recommended-rours/recommended-img1.png"
                        className="w-100"
                      />
                    </div>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </section>  */}

        <section className="travel-agency bg-pattern-2 pb-0">
          <div className="container">
            <div className="col-md-12 text-center common-heading pb-2">
              {/* <p className="title mb-0">Why to travel</p> */}
              <h1 className=" title text-white">Warum Mit Uns?</h1>
            </div>
            <div className="row justify-content-center pb-2">
              <div className="col-xl col-md-4 text-center my-3">
                <img
                  className="why-travel-img"
                  src="assets/images/why-travel/img1 .png"
                  alt="travel-agency-img1"
                />
                <div className="travel-agency-text">
                  <h6 className="text-white">Authentisch Und Einzigartig</h6>
                  {/* <p className="">Unsere preisgekrönten, lizenzierten örtlichen Reiseleiter bieten unglaubliche Einblicke und Insider-Tipps und vermitteln Ihnen mit Leidenschaft einzigartige und exklusive Erlebnisse.</p> */}
                </div>
              </div>
              <div className="col-xl col-md-4 text-center my-3">
                <img
                  className="why-travel-img"
                  src="assets/images/why-travel/img2.png"
                  alt="travel-agency-img2"
                />
                <div className="travel-agency-text">
                  <h6 className="text-white">Rundum betreut reisen</h6>
                  {/* <p>Local knowledge</p> */}
                </div>
              </div>
              <div className="col-xl col-md-4 text-center my-3">
                <img
                  className="why-travel-img"
                  src="assets/images/why-travel/img3.png"
                  alt="travel-agency-img3"
                />
                <div className="travel-agency-text">
                  <h6 className="text-white">Erstklassige Erlebnisse</h6>
                  {/* <p>Unique and bespoke itineraries</p> */}
                </div>
              </div>
              <div className="col-xl col-md-4 text-center my-3">
                <img
                  className="why-travel-img"
                  src="assets/images/why-travel/img4.png"
                  alt="travel-agency-img4"
                />
                <div className="travel-agency-text">
                  <h6 className="text-white"> Sicher & zuverlässig</h6>
                  {/* <p>Committed to responsible travel</p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="recommended-tours wildlife-tours">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 text-center common-heading pb-5">
                {/* <p className="title mb-0">Lorem Ipsum</p> */}
                <h2>
                  Individuelle Reisen Mit Safaris Und Nationalparks In Indien
                </h2>
              </div>
            </div>
            <div className="col-auto mx-auto custom-slide-with">
               <DestinationSlider tourdata={tourdata} /> 
              
            </div>
          </div>
        </section>

        <section className="home-blog">
          <div className="container">
            <div className="section-heading d-sm-flex align-items-center justify-content-center">
              <div className="heading-group">
                <h2 className="section-title text-success">
                  {" "}
                  Aur Unserem Reiseblog
                </h2>
              </div>
            </div>
            <div className="blog-section">
              <div className="row gx-4">
                {blogslice?.map((item) => (
                  <div className="col-lg-6 d-flex ">

                    <BlogArticle details={item} />
                  </div>
                ))}
              </div>
              <div className="heading-btn text-end">
                <button
                  type="submit"
                  className="btn border-success text-white bg-success"
                  onClick={handlebuttonclick}
                >
                  Mehr Anzeigen <BsArrowRightShort size={20 }/>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

       <IndiaUnlimited/>
        <section className="our-achievement bg-black">
          <div className="container-fluid px-md-5">
            <div className="row">
              <div className="col-md-12 text-center common-heading pb-5">
                {/* <p className="title mb-0 text-green">Lorem ipsum</p> */}
                <h2 className="text-white">Unser Erfolg</h2>
              </div>
            </div>
            <div className="row">
              <div className=" col-sm-6 col-lg-3 my-3">
                <div className="d-flex justify-content-center">
                  <div>
                    <img
                      src="assets/images/our-achievement/icon-light-1.png"
                      alt="achievment img"
                    />
                  </div>
                  <div className="text-info">
                    <h6>58</h6>
                    <p>Reiseziel in Indien</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <div className="d-flex justify-content-center">
                  <div>
                    <img
                      src="assets/images/our-achievement/icon-light-2.png"
                      alt="achievment img"
                    />
                  </div>
                  <div className="text-info">
                    <h6>42</h6>
                    <p>Reiserouten</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <div className="d-flex justify-content-center">
                  <div>
                    <img
                      src="assets/images/our-achievement/icon-light-3.png"
                      alt="achievment img"
                    />
                  </div>
                  <div className="text-info">
                    <h6>453</h6>
                    <p>Zufriedene Kunden</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 my-3">
                <div className="d-flex justify-content-center">
                  <div>
                    <img
                      src="assets/images/our-achievement/icon-light-4.png"
                      alt="some image"
                    />
                  </div>
                  <div className="text-info">
                    <h6>24</h6>
                    <p>Immer verfügbar</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Newsletter />
      </div>
      <Footer />
    </>
  );
};

export default Home;
