import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { postquerydata } from "../redux/actions/queryaction";
import { Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  phoneNo: Yup.number(),
  firstname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  lastname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  email: Yup.string().email("Invalid Email").required("Required*"),
  country: Yup.string(),
});

const QueryCard = () => {
  const dispatch = useDispatch();
  const [queryalert, setQueryalert] = useState(false);

  const queryForm = {
    firstname: "",
    lastname: "",
    email: "",
    phoneNo: "",
    country: "",
    tripduration: "",
    stage: "",
    description: "",
  };

  const userSubmit = (formdata) => {
    const queryForm = {
      firstname: formdata?.firstname ? formdata?.firstname : "",
      lastname: formdata?.lastname ? formdata?.lastname : "",
      email: formdata?.email ? formdata?.email : "",
      phoneNo: formdata?.phoneNo ? formdata?.phoneNo : "",
      country: formdata?.country ? formdata?.country : "",
      tripduration: formdata?.tripduration ? formdata?.tripduration : "",
      stage: formdata?.stage ? formdata?.stage : "",
      description: formdata?.description ? formdata?.description : "",
    };
    console.log("queryform is", queryForm);
    // axios({
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   url: "http://localhost:8000/query/addquery",
    //   data: JSON.stringify(queryForm),
    // })
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((error) => console.log(error));
    dispatch(postquerydata(queryForm, setQueryalert));
  };
  const successAdditionOfCategoryAlert = (title, icon) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then(() => {
      window.location.reload();
    });
  };
const onlyNumberAllow = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (queryalert) {
      successAdditionOfCategoryAlert("Submitted", "success");
    }
  }, [queryalert]);
  return (
    <div className="col-11 mx-auto col-lg-4 pr-lg-4">
      <div className="card bg-light">
        <div className="card-body px-4 text-center">
          <h4 className="pb-3" style={{fontSize:"23px"}}>WIEDER SORGLOS REISEN</h4>
          <p className="mb-0">
            Buchen Sie Ihre private, individuelle Tour Ihres Lebens
          </p>
          <p className="mb-3">mit 100% Vertrauen</p>

          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="step1"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <Formik
                initialValues={queryForm}
                onSubmit={(values, { resetForm }) => {
                  userSubmit(values);
                  resetForm({ values: "" });
                }}
                validationSchema={validationSchema}
              >
                {({ values, handleChange, handleSubmit, errors, touched }) => (
                  <form
                    method="post"
                    action
                    name="inquiryFormLarge"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-row">
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          className="form-control custom-height shadow-sm"
                          placeholder="
                          Vorname"
                          id="firstname"
                          name="firstname"
                          value={values.firstname}
                          onChange={handleChange}
                          maxLength={30}
                        />
                        <div id="name_error" />
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.firstname && touched.firstname && (
                            <div>{errors.firstname}</div>
                          )}
                        </span>
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          className="form-control custom-height shadow-sm"
                          placeholder="Nachname"
                          id="lastname"
                          name="lastname"
                          value={values.lastname}
                          onChange={handleChange}
                          maxLength={30}
                        />
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.lastname && touched.lastname && (
                            <div>{errors.lastname}</div>
                          )}
                        </span>
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="email"
                          className="form-control custom-height shadow-sm"
                          placeholder="Email*"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <div id="email_error" />
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.email && touched.email && (
                            <div>{errors.email}</div>
                          )}
                        </span>
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="tel"
                          className="form-control custom-height shadow-sm"
                          placeholder="
                          Telefon-Nr"
                          id="phoneNo"
                          name="phoneNo"
                          value={values.phoneNo}
                          onChange={handleChange}
                          maxLength={14}
                          onKeyPress={e=>onlyNumberAllow(e)}
                       
                        />
                        <div id="phone_number_error" />
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.phoneNo && touched.phoneNo && (
                            <div>{errors.phoneNo}</div>
                          )}
                        </span>
                      </div>

                      <div className="col-md-6 form-group">
                        <select
                          className="form-select custom-height shadow-sm"
                          id="country"
                          name="country"
                          value={values.country}
                          onChange={handleChange}
                        >
                          <option>Land Auswählen</option>
                          <option value="India">India</option>
                          <option value="Germany">Germany</option>
                          <option value="France">France</option>
                        </select>
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.country && touched.country && (
                            <div>{errors.country}</div>
                          )}
                        </span>
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="text"
                          className="form-control custom-height shadow-sm "
                          placeholder="Reisedauer In Tagen"
                          id="tripduration"
                          name="tripduration"
                          value={values.tripduration}
                          onChange={handleChange}
                          style={{fontSize:"14px"}}
                        />
                        <div id="name_error" />
                      </div>
                      <div className="col-md-12 form-group">
                        <select
                          className="form-select custom-height shadow-sm"
                          id="stage"
                          value={values.stage}
                          onChange={handleChange}
                        >
                          <option>Welche Phase Sie Planen</option>
                          <option value="Still dreaming/ Researching">
                            Ich träume immer noch/forsche
                          </option>
                          <option value="Definitely travelling not sure which part of India">
                            Ich bin mir auf jeden Fall nicht sicher, in welchen
                            Teil Indiens
                          </option>
                          <option value="I want to Book my trip">
                            Ich möchte meine Reise buchen
                          </option>
                        </select>
                      </div>
                      <div className="form-group col-12">
                        <textarea
                          className="form-control shadow-sm"
                          name="description"
                          placeholder="Beschreibung"
                          value={values.description}
                          onChange={handleChange}
                          id="exampleFormControlTextarea1"
                          rows={5}
                          defaultValue={""}
                        />
                        <div id="message_error" />
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="hidden"
                          name="inquiry_status"
                          defaultValue="send_query"
                          className="form-control custom-height shadow-sm"
                        />
                      </div>
                    </div>
                    <div className="custom-btn col-12 px-2">
                      <button
                        type="submit"
                        className="btn btn-success w-100 py-2"
                      >
                        Abschicken
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueryCard;
