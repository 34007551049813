import React, {useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import { BASE_URL } from "../service/url";
import axios from "axios";

const CitySection = ({ citydata, handleReadMoreClick }) => {
  const data = citydata ;
   console.log("data recieved anmol", data);
  const navigate = useNavigate();
  const handleClick = () => {

    navigate(`/city-details/${data._id}`);
    //sconsole.log("ihih;''''==========",id);
  };

  return (
    <>
    
      <div
            className="card text-white cursor-pointer city-card"
              onClick={handleClick}
           
          >

            <img
              className="card-img city-img "
              // src="assets/images/top-destinations/img-1.jpg"
              src={citydata?.banner}
              alt="Card image"
              style={{
                height:"144px",
                objectFit:"cover",
                width:"220px !important"
              }}
              
            />
            <div className="card-img-overlay">
              <div className="row align-items-end h-100">
                {/* <Link to="/city-details"></Link> */}
                <div className="col-12 d-flex justify-content-between">
                  <h5 className="card-title text-white">{citydata?.city_name}</h5>
                  <span>
                    <i className="fas fa-chevron-right text-success" />
                  </span>
                </div>
              </div>
            </div>
          </div>
      
    </>
  );
};

export default CitySection;
