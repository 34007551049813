import React from 'react'
import Footer from "./Footer";
import Header from "./Header";

const Travel_theme = () => {
  return (
    <div>
    <Header />

    <div className="inner-banner-top bg-about mb-4">
      <div className="container">
        <div className="row">
          <div className="banner-overlay d-flex justify-content-star align-items-end">
            <div className="banner-text p-4">
              <h1>BEACHES</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div className="row px-5">
        <div className="col-12">
          <div className="row">
            <div className="col-8">
              <div className="beaches-article d-flex flex-column justify-content-center">
                <p>
                  On your beach vacation, a warm fuzziness envelops you as
                  soon as you catch sight of tranquil blue waters lapping
                  silken sands. Your taste buds tingle with sublime flavors of
                  coconut and local seafood. Watch a brilliant orange sunset
                  as you sip a drink with your toes burrowing into the sand.
                </p>
                <p>
                  Enchanting Travels experts understand that a beach is not
                  just a beach. Some like it hot, and some like it cold. You
                  may prefer sandy shores touched by cool Atlantic waters
                  around Cape Town, South Africa or warm tropical waters off
                  the Mozambique coast. Take your pick from a secluded private
                  stretch of beach, or one with a pier and boardwalk, and a
                  restaurant and party scene.
                </p>
                <p>
                  Luxurious accommodation with modern amenities forms the base
                  of a beach holiday. We will find you a fabulous five-star
                  hotel, retro cliff-top cabin, or private villa with floor-to
                  ceiling windows. Hold still though, because we also know
                  that a beach vacation is much more than hours spent on a
                  sun-lounger sipping mai tais.
                </p>
                <p>
                  Let the wonderful creatures of the sea become an integral
                  part of your beach vacation. Hop on a whale and dolphin
                  watching tour on the Sri Lankan coast. Or get up close and
                  personal as you swim with dolphins in Mauritius or with
                  turtles and colorful tropical fish in the coral-filled
                  waters of Indonesia or the Maldives. Water sports that suit
                  every level of challenge and fitness are yours for the
                  asking.
                </p>

                <div className="col-auto">
                  <a href="#" className="btn btn-success">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="card bg-light">
                <div className="card-body px-4">
                  <h3 className="col-auto">TRAVEL CAREFREE AGAIN</h3>
                  <p>Book your private, custom tour of a lifetime</p>
                  <p>with 100% confidence</p>
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#step1"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        STEP 1
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#step2"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        STEP 2
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="step1"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <form>
                        <div className="row">
                          <div class="input-group mb-3">
                            <select
                              class="custom-select"
                              id="inputGroupSelect01"
                            >
                              <option selected>Beaches</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div class="input-group mb-3">
                            <select
                              class="custom-select"
                              id="inputGroupSelect01"
                            >
                              <option selected>
                                Where do you want to go?
                              </option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div class="input-group mb-3">
                            <select
                              class="custom-select"
                              id="inputGroupSelect01"
                            >
                              <option selected>When</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div class="input-group mb-3">
                                <select
                                  class="custom-select"
                                  id="inputGroupSelect01"
                                >
                                  <option selected>No. of travelers</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-6">
                              <div class="input-group mb-3">
                                <select
                                  class="custom-select"
                                  id="inputGroupSelect01"
                                >
                                  <option selected>Budget</option>
                                  <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-success">Get started</button>
                      </form>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="step-2"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
        <form>
          <input type="text" placeholder="Full Name*"/>
          <input type="email" placeholder="Email*"/>
          <input type="number" placeholder="Phone*"/>
          <div class="input-group mb-3">
            <select
              className="custom-select"
              id="inputGroupSelect01"
            >
              <option selected>
                What stage of planning are you inx    ?
              </option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>       
          </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </section>
    <Footer />
  </div>
  )
}

export default Travel_theme