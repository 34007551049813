import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CitySection from "../components/CitySection";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import Footer from "./Footer";
import Header from "./Header";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import { useSelector } from "react-redux";

const NorthIndia = () => {
  const [tourdata, setTourdata] = useState();
  const [displayForm, setDisplayForm] = useState(true);
  //  const filterDataTour = useSelector((state) => state.filterData);
  const handleClick = () => {
    setDisplayForm(!displayForm);
  };
  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      category: "North India",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package`, obj);
      console.log("Reson data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };

  // --------city data APi

  const [citydata, setCitydata] = useState();
  const [noOfCity, setNoOfCity] = useState(8);
  const cityslice = citydata?.slice(0, noOfCity);
  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data2 testing", response)
      const { result } = response.data;
      console.log(response.data, " City data2 testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  useEffect(() =>{
    fetchFilterTour()
    fetchCity()
  }, [])

 console.log("filterDataTour  ......." , filterDataTour)
//  const [noOfFilterData, setNoOfFilterData] = useState(8);
//  const filterDats = filterDataTour?.slice(0, noOfFilterData);

//  const handleloadMore = () => {
//    setNoOfElement(noOfElement + noOfElement);

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top bg-about mb-4 north-banner"
        style={{
          backgroundImage: `url("/assets/images/banner/Nordosten.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Nordindien Reisen</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5 ">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <p>
                    Höhepunkte des indischen Erbes auf einer Tour von Nordindien
                  </p>
                  <p>
                    Was sollten Sie erwarten auf Ihrer privaten Tour nach
                    Nordindien? l wenn du das Taj Mahal siehst , Indiens
                    berühmtestes Monument lieben. Erwarten Sie, umhüllt zu
                    werden durch ein Gefühl des Friedens, wenn Sie den Goldenen
                    Tempel von Amritsar betreten. Vielleicht ist es der Gesang
                    der Mönche in einem abgelegenen Himalaya-Kloster das wird
                    deine Seele rühren.
                  </p>
                  <p>
                    Denkmäler und Festungen, paläste und Moscheen, Nordindien
                    hat eine beispiellose geschichte und erbe. Delhis
                    kaiserliches und Moghul-Architektur ist ebenso ein Köder wie
                    sein essen und einkaufen. Die berge bieten nicht nur schöne
                    ausblicke, aber auch kolonialer charme und lebendige
                    Kulturen.
                  </p>
                  <p>
                    Wenn es jemals einen weg gab, zu erfahren leben wie ein
                    maharadscha, es ist in Rajasthans palasthotels. Wachen sie
                    mit einem pfauentanz auf auf rasenflächen außerhalb Ihres
                    zimmers. Speisen sie raffinierte mahlzeiten gekocht nach
                    königlichen rezepten, in königlichen küchen.
                  </p>
                  <p>
                    Stab mit turban wird Ihre wünsche voraussehen, jeden noch so
                    kleinen wunsch erfüllen. Genau so sind berater bei 
                    India Unlimited arbeiten, kümmert sich um jede anfrage wie sie Ihr
                    privates gestalten Rundreise durch Nordindien.
                  </p>
                  <p>
                    Nicht nur sind sie unerbittlich fokussiert auf das
                    verständnis Ihrer anforderungen, sie sind engagiert um eine
                    störungsfreie fahrt zu gewährleisten. Eine fahrt mit dem
                    heißluftballon? Kein problem. Erkundung eines geheimen
                    tunnels zwischen majestätischen wallburgen, oder vielleicht
                    eine bootsfahrt in der dämmerung am Ganges? Kein problem.
                    Komm zum Taj, aber bleiben sie für die vielfalt und farbe
                    Nordindiens.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE REISEN NACH NORDINDIEN"
      />

<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3 sub-title">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
      {/* <section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            <div className="row mx-sm-3 mx-0">
        
    

        
     {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2"> 
               <CitySection citydata={citydata} />

        </div>
            ))} 
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section> */}
      <Footer />
    </div>
  );
};

export default NorthIndia;
