import { React, useState, useEffect } from "react";

import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";

import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import CitySection from "../components/CitySection";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

const EastIndia = () => {
  const [tourdata, setTourdata] = useState();
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTour();
  // }, []);
  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      category: "East India",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package`, obj);
      console.log("Reson data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
  const [citydata, setCitydata] = useState();
  const [noOfCity, setNoOfCity] = useState(8);
  const cityslice = citydata?.slice(0, noOfCity);
  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data2 testing", response)
      const { result } = response.data;
      console.log(response.data, " City data2 testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  useEffect(() =>{
    fetchFilterTour();
    fetchCity()
  }, [])

 console.log("filterDataTour ......." , filterDataTour)
  return (
    <div>
      <Header />

      <div
        className="inner-banner-top  mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Nordindien.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Nordosten Indien Reisen</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <h3>Ostindien-Tour:Die weniger befahrene straße</h3>
                  <p>
                    Eine tour durch ostindien führt dich durch eine dramatisch
                    abwechslungsreiche landschaft: mangroven in den Sundarbans
                    und hohe gipfel im hohen Himalaya. Die meisten touren
                    beginnen in Kalkutta, einst die hauptstadt des Britischen
                    Empire,wo das geschäftige B.B.D. Bagh randvoll mit
                    verfallender kolonialarchitektur aus dem 19. jahrhundert,
                    Moosbedeckte Grabsteine ​​auf dem South Park Street
                    Friedhof, der Paresnath-Jain-tempel, die Armenische Kirche,
                    alle erzählen geschichten von den multikulturellen
                    einflüssen die diese stadt über jahrtausende geprägt haben.
                  </p>
                  <p>
                    Segeln Sie im morgengrauen auf der Hooghly, tempel
                    flussaufwärts zu besuchen, und beenden sie den Tag mit einem
                    typisch bengalischen essen. Bengalen sind besessen von
                    Essen, und Kalkutta, für all seine mängel, ist ein paradies
                    für feinschmecker. Gehen Sie nicht, ohne an der Fülle der
                    bengalischen Küche teilzuhaben und gespräch.
                  </p>
                  <p>
                    Rund um die bergstation von Darjeeling, große ländereien
                    wachsen einige der begehrtesten Tees der Welt. Nippen sie an
                    einer tasse reinem Darjeeling first flush, inmitten
                    nebelverhangener teegärten in kolonialem ambiente.
                  </p>
                  <p>
                    Der Klang von singenden Buddhistischen Mönchen erfüllt die
                    Luft in Pelling, Sikkim. Gärten strotzen vor orchideen und
                    waldteam mit Rhododendron. An jeder ecke des staates, sie
                    werden zu atemberaubenden ausblicken auf schneebedeckte
                    bergketten kommen und der markante fünfgipfelige Berg
                    Kanchenjunga.
                  </p>
                  <p>
                    Weiter östlich, im Kaziranga-Nationalpark in Assam,
                    einhörnige Nashörner grasen träge, und Otter schwimmen
                    energisch in den schlammigen Flüssen, die vorbeifließen.
                    Assam ist Teil der sieben nordöstlichen
                    Bundesstaatenliebevoll die sieben schwestern genannt.
                    Einzigartige tierwelt wie Hoolock-Gibbons und große
                    nashornvögel dieses land von außergewöhnlicher natürlicher
                    schönheit bewohnen, mit{" "}
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE REISEN NACH OSTINDIEN"
      />
      <section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
     
      <Footer />
    </div>
  );
};

export default EastIndia;
