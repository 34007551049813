import { React, useEffect, useState } from "react";
import CitySection from "../components/CitySection";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

const Beaches = () => {
  const [tourdata, setTourdata] = useState();
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };
  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      traveltheme:"Badeferien Und Strandurlaub In Indien",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package-theme`, obj);
      console.log("theme data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
  // --------city data APi

  const [citydata, setCitydata] = useState();
  const [noOfCity, setNoOfCity] = useState(8);
  const cityslice = citydata?.slice(0, noOfCity);
  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data2 testing", response)
      const { result } = response.data;
      console.log(response.data, " City data2 testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  useEffect(() => {
    fetchFilterTour();
    fetchCity();
  }, []);

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top  mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Badeferien und Strandurlauo in indien.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Badeferien Und Strandurlaub In Indien</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row px-5">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <p>
                    Sonnige Seite nach oben im Strandurlaub In Ihrem
                    Strandurlaub eine warme verschwommenheit umhüllt dich sobald
                    sie das ruhige blaue wasser sehen plätschern von seidenen
                    sanden.
                  </p>
                  <p>
                    Ihre geschmacksknospen prickeln mit erhabenen aromen von
                    kokosnuss und lokalen meeresfrüchten. Beobachten sie einen
                    leuchtend orangefarbenen sonnenuntergang während sie mit
                    Ihren zehen an einem getränk nippen in den sand graben.
                  </p>
                  <p>
                    Hansa India experten verstehen dass ein strand ist nicht nur
                    ein strand. Manche mögen es heiß, und manche mögen es kalt.
                    Vielleicht bevorzugen sie sandstrände berührt von coolem Goa
                    oder südindische strände. Wählen sie aus einem abgelegenen
                    privaten strandabschnitt, oder eines mit pier und promenade,
                    und eine restaurant- und partyszene.
                  </p>
                  <p>
                    Luxuriöse Unterkunft mit modernen annehmlichkeiten bildet
                    die grundlage für einen strandurlaub. Wir finden für Sie ein
                    fabelhaftes fünf-sterne-hotel, retro-klippenhütte, oder
                    private villa mit bodentiefen fenstern.
                  </p>
                  <p>
                    Halt doch still, weil wir das auch wissen strandurlaub ist
                    viel mehr als stunden, die Sie auf einer sonnenliege
                    verbringen und mai tais schlürfen.
                  </p>
                  <p>
                    Lassen sie die wunderbaren kreaturen des meeres werden Sie
                    zu einem festen bestandteil Ihres strandurlaubs. Steigen sie
                    auf einen wal und delfin beobachtungstour an der küste.
                    Wassersportarten, die zu e get passen bereit, sich
                    davonzuschleichen im urlaub an die küste da geht es nur um
                    entspannung, oder eine, die teil einer längeren safari ist
                    oder kultururlaub. Nach Ihrer Aromaölmassage und
                    Meersalz-Peeling ist fertig, mitnehmen, ruhiger spaziergang
                    an einem unberührten strand. Wie du den himmelskuß
                    beobachtest das Meer, das Sie wahrscheinlich geben möchten
                    sich eine sanfte prise um zu überprüfen, ob sie träumen.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE REISE FÜR STRÄNDE"
      />

<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
     
      <Footer />
    </div>
  );
};

export default Beaches;
