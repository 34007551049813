import React from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../service/url";
import axios from "axios";
import { HiUserGroup } from "react-icons/hi"
import moment from "moment";
import { BiTimeFive } from "react-icons/bi";


const BlogCard = ({
  image,
  title,
  author,
  shortdescription,
  minutesread,
  id,
}) => {
  const navigate = useNavigate();
  const handleClick = (id) => {
    console.log("blog card id -------", id)
    navigate(`/blog-details/${id}`);
  };
  return (
    <div className="relatedblog ">
      <div className="blog-head d-flex">
      <div class="card" className="w-100">
        <div className="" style={{ overflow: "hidden"}} >
        <img class="card-img " style={{ transition:" 0.3s",height:"193px", objectFit:"cover"}}   src={image} alt="" />
        </div>
        <div class="card-body">
          <div className="d-flex justify-content-between mt-3">
          <div class="text-muted cat col-md-6 p-0" style={{fontSize:"12px", fontWeight:"500", color: "#4baf5d !important", }}> 
           <HiUserGroup  size={15}/><span> by  :</span> {author}
          </div>
  <div class="text-muted cat col-md-6 p-0" style={{fontSize:"12px", fontWeight:"500" }}>  
            <BiTimeFive size={15}/>  {moment(minutesread).format("DD MMMM YYYY")}
          </div>
          </div>
         
        <div>  <h4 class="card-title mt-3" style={{color:"#28a745"}}>{title}</h4></div>
         <div> <p class="card-text text-truncate2" style={{marginBottom:"none"}} >{shortdescription}</p></div>
          <button
            class="btn text-success blog-slider-button "
            onClick={() => handleClick(id)}
          >
            Blog Lesen
          </button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default BlogCard;
