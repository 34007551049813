import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { postquerydata } from "../redux/actions/queryaction";
import Swal from "sweetalert2";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import {FaCarSide, FaBuilding } from "react-icons/fa";
import { GiCommercialAirplane } from "react-icons/gi";


const validationSchema = Yup.object().shape({
  phoneNo: Yup.number(),
  firstname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  lastname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  email: Yup.string().email("Invalid Email").required("Required*"),
  country: Yup.string(),
});

const TravelCard = ({ Destination, Days, PerDay, Details, LinkTo, image }) => {
  const [phone, setPhone] = useState("");
  const [tourModal, setTourModal] = useState(false);
  const [queryalert, setQueryalert] = useState(false);
  const dispatch = useDispatch();
  const detailarray = Details.split("");
  console.log("gjgfffghjkl;tyuiyuiyuiouioui",detailarray)
  const detailslice = detailarray?.slice(0, 45);
  const detailTourPackage = detailslice.join(" ");
  const queryForm = {
    firstname: "",
    lastname: "",
    email: "",
    phoneNo: "",
    country: "",
    tripduration: "",
    stage: "",
    description: "",
  };
  const userSubmit = (formdata) => {
    const queryForm = {
      firstname: formdata?.firstname ? formdata?.firstname : "",
      lastname: formdata?.lastname ? formdata?.lastname : "",
      email: formdata?.email ? formdata?.email : "",
      phoneNo: phone,
      country: formdata?.country ? formdata?.country : "",
      tripduration: formdata?.tripduration ? formdata?.tripduration : "",
      stage: formdata?.stage ? formdata?.stage : "",
      description: formdata?.description ? formdata?.description : "",
    };
    console.log("queryform is", queryForm);

    dispatch(postquerydata(queryForm, setQueryalert));
  };
  const successAdditionOfCategoryAlert = (title, icon) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        setTourModal(false);
        window.location.reload();
      } else {
        setTourModal(true);
      }
    });
  };
  const onlyTextAllowed = (e) => {
    const re = /^[a-zA-Z]+$/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  const onlyNumberAllow1 = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (queryalert) {
      successAdditionOfCategoryAlert("Submitted", "success");
    }
  }, [queryalert]);
  return (
    
   <>
    <div className="card  px-2 border-0">
      <div className="card text-white">
        <div className="position-relative">
         <div className="inner">
         <img
            className="card-img img-fluid  inner"
            src={image}
            alt="Card image"
            style={{ width: "100%", height: "260px", objectFit:"cover", }}
          />
         </div>
          <div className="card-img-overlay">
            <div className="card-info">
              <h5 className="card-title font-weight-light mb-1 text-left">
                {Destination}
              </h5>
              <div className="d-flex card-text">
                <span>
                  {Days}
                  Tage:
                </span>
                <span className="px-2">
                  <i className="fas fa-rupee-sign" /> {PerDay}/Tag
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body pt-1">
          <div className="service-icon d-flex">
            {/* <span className="pl-0">
              <img src="assets/images/vector-img/hotel.jpg" alt="vector-img1" />
            </span> */}
            <span className="icon">
            <FaBuilding size={20}  className=" icon"  />

            </span>
            <span>
           < FaCarSide size={20} className=" icon" />
            </span>
            <span>
              <GiCommercialAirplane size={20}  className=" icon"  />
            </span> 
          </div>
          <p className="body-text pt-2 text-truncate2 " 
          style={{height:"56px"}}>
           {detailTourPackage}
          </p>
          <hr className="text-bg-info" />
          <div className="row btn-link-custom">
            <div className="col-6 text-center border-right-custom">
              <Link to={`/${LinkTo}`} className="link-text text-uppercase tour-btn">
                Einzelheiten
              </Link>
            </div>
            <div className="col-6 text-center">
              <button
                onClick={() => setTourModal(true)}
                className="link-text link-text__green text-uppercase border-0 bg-none btn-anfragen"
              >
                Anfragen
              </button>
              {tourModal && (
                <Modal
                  show={tourModal}
                  onHide={() => {
                    setTourModal(false);
                  }}
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Formik
                      initialValues={queryForm}
                      onSubmit={userSubmit}
                      validationSchema={validationSchema}
                    >
                      {({
                        values,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                      }) => (
                        <form
                          method="post"
                          name="inquiryFormLarge"
                          onSubmit={handleSubmit}
                        >
                          <div className="form-row">
                            <div className="col-md-6 form-group">
                              <input
                                type="text"
                                maxLength={30}
                                id="firstname"
                                value={values.firstname}
                                onChange={handleChange}
                                className="form-control custom-height shadow-sm"
                                placeholder="Vorname"
                                onKeyPress={(e) => onlyTextAllowed(e)}
                              />
                              <div id="name_error" />
                              <span
                                style={{
                                  color: "tomato",
                                  "font-size": "small",
                                }}
                              >
                                {errors.firstname && touched.firstname && (
                                  <div>{errors.firstname}</div>
                                )}
                              </span>
                            </div>
                            <div className="col-md-6 form-group">
                              <input
                                type="text"
                                id="lastname"
                                name="lastname"
                                maxLength={30}
                                value={values.lastname}
                                onChange={handleChange}
                                className="form-control custom-height shadow-sm"
                                placeholder="Nachname"
                                onKeyPress={(e) => onlyTextAllowed(e)}
                              />
                              <span
                                style={{
                                  color: "tomato",
                                  "font-size": "small",
                                }}
                              >
                                {errors.lastname && touched.lastname && (
                                  <div>{errors.lastname}</div>
                                )}
                              </span>
                            </div>
                            <div className="col-md-6 form-group">
                              <input
                                type="email"
                                id=" email"
                                value={values.email}
                                onChange={handleChange}
                                name="email"
                                className="form-control custom-height shadow-sm"
                                placeholder="Email*"
                              />
                              <div id="email_error" />
                              <span
                                style={{
                                  color: "tomato",
                                  "font-size": "small",
                                }}
                              >
                                {errors.email && touched.email && (
                                  <div>{errors.email}</div>
                                )}
                              </span>
                            </div>
                            <div className="col-md-6 form-group p-0">
                              <PhoneInput
                                placeholder="Telefon-Nr"
                                id=" phoneNo"
                                name=" phoneNo"
                                value={phone}
                                // onChange={(e) => handlePhoneChange(e)}
                                maxLength="17"
                                onChange={setPhone}
                                className=" form-control d-flex  custom-height shadow-sm"
                              />
                              <div id="phone_number_error" />
                              <span
                                style={{
                                  color: "tomato",
                                  "font-size": "small",
                                }}
                              >
                                {errors.phoneNo && touched.phoneNo && (
                                  <div>{errors.phoneNo}</div>
                                )}
                              </span>
                            </div>

                            <div className="col-md-6 form-group">
                              <select
                                className="form-select custom-height shadow-sm"
                                id="country"
                                name="country"
                                value={values.country}
                                onChange={handleChange}
                              >
                                <option>Land Auswählen</option>
                                <option value="india">India</option>
                                <option value="germany">Germany</option>
                                <option value="france">France</option>
                              </select>
                              <span
                                style={{
                                  color: "tomato",
                                  "font-size": "small",
                                }}
                              >
                                {errors.country && touched.country && (
                                  <div>{errors.country}</div>
                                )}
                              </span>
                            </div>
                            <div className="col-md-6 form-group">
                              <input
                                type="text"
                                className="form-control custom-height shadow-sm"
                                placeholder="Reisedauer in Tagen"
                                id="tripduration"
                                name="tripduration"
                                maxLength={2}
                                value={values.tripduration}
                                onChange={handleChange}
                                onKeyPress={(e) => onlyNumberAllow1(e)}
                              />
                              <div id="name_error" />
                            </div>
                            <div className="col-md-12 form-group">
                              <select
                                className="form-select custom-height shadow-sm"
                                id="stage"
                                value={values.stage}
                                onChange={handleChange}
                              >
                                <option>Welche Phase Sie planen</option>
                                <option value="Still dreaming/ Researching">
                                  Ich träume immer noch/forsche
                                </option>
                                <option value="Definitely travelling not sure which part of India">
                                  Ich bin mir auf jeden Fall nicht sicher, in
                                  welchen Teil Indiens
                                </option>
                                <option value="I want to Book my trip">
                                  Ich möchte meine Reise buchen
                                </option>
                              </select>
                            </div>
                            <div className="form-group col-12">
                              <textarea
                                className="form-control shadow-sm"
                                id="description"
                                value={values.description}
                                placeholder="Beschreibung"
                                onChange={handleChange}
                                rows={5}
                                defaultValue={""}
                              />
                              <div id="message_error" />
                            </div>
                            <div className="col-md-6 form-group">
                              <input
                                type="hidden"
                                name="inquiry_status"
                                defaultValue="send_query"
                                className="form-control custom-height shadow-sm"
                              />
                            </div>
                          </div>
                          <div className="custom-btn col-12 px-2">
                            <button
                              type="submit"
                              className="btn btn-success w-100 py-2"
                            >
                              Abschicken &nbsp;
                              <i className="fas fa-paper-plane" />
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Modal.Body>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    
   </>
    
  );
};

export default TravelCard;
