import axios from "axios";
import { BASE_URL } from "../../service/url";
import { ActionTypes } from "../constants/actiontypes";

export const postquerydata = (formdata, setQueryalert) => async (dispatch) => {
  try {
    console.log("formata send from dispatch", formdata);
    const response = await axios.post(`${BASE_URL}/query/addquery`, formdata);
    const data = response.data;
    console.log("ooooooooooooooooooooopppppppppppppppppsssssss", data);
    if (response.data.success) {
      setQueryalert(true);
    }
    //  else {
    //   setQueryalert(false);
    // }

    dispatch({
      type: ActionTypes.POST_QUERY_FORMDATA,
      payload: formdata,
    });
  } catch (error) {
    console.log("err===", error);
  }
};
