import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Footer from "./Footer";
import Header from "./Header";

const Profile = () => {
  const [userEmail, setUserEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [userImage, setUserImage] = useState(null);
  const [logoutModal, setLogoutModal] = useState(false);

  const handlelogout = () => setLogoutModal(true);
  const handlelogoutClose = () => setLogoutModal(false);

  return (
    <>
      <Header />
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <div className="card m-4 shadow">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first" href="#">
                    Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" href="#" onClick={handlelogout}>
                    Logout
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Modal show={logoutModal} onHide={handlelogoutClose}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>Do you want to logout?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handlelogoutClose}>
                  Close
                </Button>
                <Button variant="primary">Logout</Button>
              </Modal.Footer>
            </Modal>
          </Col>
          <Col sm={9}>
            <div className="card m-3 shadow ">
              <div className="card-body mb-3">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <h2>Profile</h2>
                    <div className="row">
                      <div className="col-8">
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                              <div class="pic-holder text-center">
                                <label htmlFor="newProfilePhoto">
                                <img
                                  id="profilePic"
                                  class="pic"
                                  src="https://source.unsplash.com/random/150x150?person"
                                  style={{borderRadius:"50%"}}
                                />
                                </label>
                               <input
                                  class="uploadProfileInput d-none"
                                  type="file"
                                  name="profile_pic"
                                  id="newProfilePhoto"
                                /> 
                              </div>

                              {/* <div className="profile-pic text-center">
                            <div class="avatar-upload">
                              <div class="avatar-edit">
                                <input
                                  type="file"
                                  id="imageUpload"
                                  accept=".png, .jpg, .jpeg"
                                />
                              </div>
                              <div class="avatar-preview">
                                <div
                                  id="imagePreview"
                                  className="profile-image-upload"
                                >
                                  <img
                                    src="/assets/images/user-img-2.png"
                                    className="w-100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}
                            </div>
                          </div>

                          <div className="name m-2 ">
                            <div className="row">
                              <div className="col-4 text-center">Name *:</div>
                              <div className="col-8 ">
                                <input
                                  type="text"
                                  placeholder="Name"
                                  className="w-100 profile-input"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="PhoneNo m-2">
                            <div className="row">
                              <div className="col-4 text-center">
                                Phone Number*:
                              </div>
                              <div className="col-8">
                                <input
                                  type="tel"
                                  placeholder="Phone Number"
                                  className="w-100 profile-input"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="Email m-2">
                            <div className="row">
                              <div className="col-4 text-center">
                                Email ID*:
                              </div>
                              <div className="col-8">
                                <input
                                  type="email"
                                  className="w-100 profile-input"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="Country m-2">
                            <div className="row">
                              <div className="col-4 text-center">Country*:</div>
                              <div className="col-8">
                                <select className="w-100 profile-input">
                                  <option value="India">India</option>
                                  <option value="USA">USA</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Italy">Italy</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-4"></div>
                      <div className="row">
                        <div class="heading-btn text-end">
                          <button type="submit" class="btn btn-success">
                            Sparen
                          </button>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">{/* <Sonnet /> */}</Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Col>
        </Row>
      </Tab.Container>
      <Footer />
    </>
  );
};

export default Profile;
