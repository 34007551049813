import React from "react";

import Accordion from "react-bootstrap/Accordion";
import QueryIndia from "../components/QueryIndia";
import Footer from "./Footer";
import Header from "./Header";

const Covid19 = () => {
  return (
    <>
      <Header />
      <div>
        <div
          className="inner-banner-top  mb-4"
          style={{
            backgroundImage: `url("/assets/images/banner/Covid FAQ.jpg")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="banner-overlay d-flex justify-content-star align-items-end">
                <div className="banner-text p-4">
                  <h1>Covid-19 FAQS</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section>
          <div className="row px-5">
            <div className="col-md12">
              <div className="row">
                <div className="col-md-8 col-sm-12">
                  <div className="beaches-article d-flex flex-column justify-content-center">
                    <div>
                      <h2 className="text-success">
                        Frequently Asked Questions
                      </h2>
                    </div>
                    <div>
                      <h3 className="text-success pt-5">COVID-19 and Travel</h3>
                    </div>
                    <p style={{textAlign: "justify",}}>
                      Fragen beantworten wir gerne sie haben über unsere
                      aktualisierten richtlinien während der
                      Coronavirus-Pandemie. Die Landschaft verändert sich
                      schnell als länder weltweite Reiseverbote langsam aufheben
                      und internationale reisen wieder aufnehmen, und
                      internationale reisen wieder aufnehmen, und
                      stornierungsbedingungen für Ihre reise auf Ihr heimatland
                      und Ihr ziel.
                    </p>
                    <p style={{textAlign: "justify",}}>
                      Auf dieser Seite, Wir haben die am häufigsten gestellten
                      Fragen beantwortet von unseren gästen über das reisen und
                      buchen sie eine reise zu diesem zeitpunkt. Na sicher, wenn
                      sie spezielle fragen zu Ihrer reise haben, sich nach einem
                      zukünftigen urlaub erkundigen möchten, oder teilen sie Ihr
                      feedback, sie können sich jederzeit mit uns in verbindung
                      setzen.
                    </p>

                    {/* <div className="col-auto">
                      <a href="#" className="btn btn-success">
                        General Faq
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="col-11 mx-auto col-lg-4">
                  <QueryIndia />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col">
            <hr />
          </div>
        </div>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="accordian-header ps-3">
                      <h3>FAQs</h3>
                    </div>
                    <Accordion defaultActiveKey="0">
                      <div className="border-2">
                        <hr />
                      </div>
                      <Accordion.Item eventKey="0" className="border-0">
                        <Accordion.Header>
                          <p style={{textAlign: "justify",}}>
                          Meine reise soll innerhalb der nächsten 60 tage
                          beginnen. Welche möglichkeiten habe ich?
                          </p>
                         
                        </Accordion.Header>
                        <Accordion.Body>
                        <p style={{textAlign: "justify",}}>
                        Ihr reiseberater oder reisekoordinator hätte schon
                          kontakt aufnehmen sollen Ihnen, Ihre optionen zu
                          besprechen, wenn Ihre reise innerhalb der nächsten 60
                          tage beginnt. Ihre sicherheit und Ihr wohlbefinden
                          haben für uns oberste priorität. Wir bieten Ihnen je
                          nach bedarf eine individuelle reiseberatung zu Ihrem
                          reiseziel und Ihren reisedaten.
                        </p>
                          
                          <p style={{textAlign: "justify",}}>
                          Dafür, wir verlassen uns auf vertrauenswürdige
                          reisehinweise ausgestellt vom US-Außenministerium, WHO
                          und unsere partner vor ort die die aktuellsten
                          informationen und erkenntnisse teilen.
                          </p>
                         
                          <p style={{textAlign: "justify",}}>
                          Wenn sie zu Ihrem ziel reisen ist aufgrund von
                          COVID-19-einschränkungen nicht möglich, sie können
                          Ihre reise kostenlos umbuchen ab 60 tage vor Ihrer
                          abreise unter unserer 60-tägigen
                          COVID-19-schutzgarantie. Bitte beachten Sie, nur
                          gäste, deren buchungsdatei in ordnung ist mit der
                          geleisteten restzahlung, können diese leistung in
                          anspruch nehmen.
                          </p>
                         
                          <p style={{textAlign: "justify",}}>
                          Sie haben die möglichkeit, Ihre reise auf einen
                          späteren zeitpunkt zu verschieben indem sie die
                          differenz der kosten für jede saison bezahlen oder
                          streckenänderungen.
                          </p>
                         
                          <p style={{textAlign: "justify",}}>
                          Bitte beachten sie, dass bei terminverschiebungen
                          aufgrund von faktoren können zusätzliche gebühren
                          anfallen wie verfügbarkeit. Wir bieten Ihnen auch die
                          möglichkeit, einen reisegutschein gültig zu machen für
                          drei Jahre, damit sie Ihr reiseziel und Ihren
                          reisetermin in der zukunft frei wählen können.
                          </p>
                          
                        </Accordion.Body>
                      </Accordion.Item>
                      <div className="border-2">
                        <hr />
                      </div>
                      <Accordion.Item eventKey="1" className="border-0">
                        <Accordion.Header>  <p style={{textAlign: "justify",}}>
                        Ich habe eine zukünftige reise bei Ihnen gebucht,
                          möchte diese aber stornieren oder verschieben. Welche
                          möglichkeiten habe ich?
                        </p>
                         
                        </Accordion.Header>
                        <Accordion.Body> 
                        <p style={{textAlign: "justify",}}>
                        Ihr reiseberater oder reisekoordinator wird sich mit
                          Ihnen in verbindung setzen 60 tage vor Ihrem
                          abreisedatum, um die Ihnen zur verfügung stehenden
                          optionen zu besprechen.
                        </p>
                         
                          <p style={{textAlign: "justify",}}>
                          Wenn eine reise aufgrund von COVID-bedingten
                          reisebeschränkungen nicht möglich ist, gemäß den
                          bedingungen unserer 60-tägigen COVID-19-schutzgarantie
                          sie haben anspruch auf eine kostenlose verschiebung,
                          solange Ihre buchungsdatei in gutem zustand ist mit
                          der geleisteten restzahlung.
                          </p>
                         
                          <p style={{textAlign: "justify",}}>  Wenn Ihre reise voraussichtlich mehr als 60 tage
                          später beginnt, du kannst:</p>
                         
                       <p style={{textAlign: "justify",}}>
                       – Verschieben sie Ihre reise, indem sie nur die nicht
                          erstattungsfähigen gebühren unserer buchungspartner
                          bezahlen und alle zusätzlichen Kosten im zusammenhang
                          mit der saisonalität.
                       </p>
                         
                       <p style={{textAlign: "justify",}}>
                       – sie können Ihre reise stornieren, indem sie die
                          standardstornierung bezahlen gebühren, die in Ihrem
                          angebot angegeben sind, wenn sie sich entscheiden,
                          überhaupt nicht zu reisen. An diesem punkt können wir
                          eine kostenlose stornierung anbieten wenn eine hin-
                          oder rückreise nicht möglich ist. Der restbetrag Ihrer
                          anzahlung wird Ihnen in form einer drei jahre gültigen
                          reisegutschrift erstattet.
                       </p>
                        
                      <p style={{textAlign: "justify",}}>
                      Bis sie mit Ihrem reiseberater sprechen oder
                          reisekoordinator 60 tage vor reiseantritt, wir möchten
                          Ihnen versichern, dass wir alles tun wir können hinter
                          den Kulissen für einen safe sorgen und freudiges
                          reiseerlebnis für sie.
                   
                      </p>
                          
                        </Accordion.Body>
                      </Accordion.Item>
                      <div className="border-2">
                        <hr />
                      </div>
                      <Accordion.Item eventKey="2" className="border-0">
                        <Accordion.Header>
                        <p style={{textAlign: "justify",}}>
                          Ich möchte eine reise buchen aber ich mache mir sorgen
                          über anderungen das kann sich auf meine reise
                          auswirken. Welche möglichkeiten habe ich?
                          </p>
                        </Accordion.Header>
                        <Accordion.Body>
                        <p style={{textAlign: "justify",}}>
                            Buchen sie Ihre nächste reise bis zum 31. Oktober
                            2022, und genieße unsere Sorglos-Garantie! und wir
                            werden den ursprünglichen preis Ihrer reise
                            anerkennen. Bitte beachten sie, dass für eine
                            umbuchung der ursprüngliche reisepreis anerkannt
                            wird. Der endgültige reisepreis der neuen reise kann
                            für verschiedene daten höher oder niedriger
                            sein.Internationale flüge und spezifische
                            dienstleistungen wie Malediven-Hotels, kreuzfahrten,
                            luxuszugreisen, gorilla-trekking und wohnmobile sind
                            von diesem angebot ausgeschlossen.
                          </p>
                          <p style={{textAlign: "justify",}}>
                            Bitte wenden sie sich an Ihren reiseberater, um
                            weitere Informationen zu unserer sorglos-garantie zu
                            erhalten.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <div className="border-2">
                        <hr />
                      </div>
                      <Accordion.Item eventKey="3" className="border-0">
                        <Accordion.Header>
                        <p style={{textAlign: "justify",}}>  Sollte ich mir sorgen um die finanzlage von 
                          India Unlimited machen?</p>
                         
                        </Accordion.Header>
                        <Accordion.Body>
                        <p style={{textAlign: "justify",}}>
                            Absolut nicht.India Unlimited ist eine zuverlässige
                            private-equity-gesellschaft, was es uns fast
                            unmöglich macht, in einer position zu seinden handel
                            einzustellen. Wir sind von allen führenden
                            internationalen Reiseverbänden akkreditiert,
                            einschließlich USTOA und AFTA. So können Sie
                            beruhigt reisen.
                          </p>
                           <p style={{textAlign: "justify",}}>
                            Ob sie zu diesem zeitpunkt auf einer reise sind oder
                            entscheiden Sie sich, in Zukunft mit uns zu reisen,
                            mit unseren flexiblen reiserichtlinien, gründliche
                            anleitung, lokale expertise und unerschütterliche
                            unterstützung, Sie können Ihre sorgen hinter sich
                            lassen –sie werden immer in sicheren händen sein.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <div className="border-2">
                        <hr />
                      </div>
                      <Accordion.Item eventKey="4" className="border-0">
                        <Accordion.Header>
                          Wie kann ich mich vor COVID-19 schützen?
                        </Accordion.Header>
                        <Accordion.Body>
                           <p style={{textAlign: "justify",}}>
                            Wir möchten einige vorbeugende maßnahmen teilen dass
                            renommierte internationale Gesundheitsorganisationen
                            empfohlen haben um die Verbreitung von COVID-19 zu
                            bekämpfen:
                          </p>
                           <p style={{textAlign: "justify",}}>Während des Fluges:</p>
                           <p style={{textAlign: "justify",}}>
                            Verwenden sie feuchttücher auf gemeinsamen
                            oberflächen du wirst dich berühren während du
                            fliegst, z.b. sitzbezüge abwischen, tabletttisch,
                            armlehne, kopfstütze,
                            in-flight-entertainment-tasten, fensterrollo usw.
                            Verwenden sie während Ihrer flüge masken zu und von
                            einem ziel und während zwischenstopps. Minimieren
                            sie besuche auf der toilette um eine minimale
                            belastung durch das gehen im gang zu gewährleisten,
                            und müssen den türgriff oder die spülknöpfe
                            berühren. Bringen sie Ihre eigenen
                            desinfektionsmittel mit und masken zur verwendung
                            während der reise. Trinken sie am flughafen immer
                            mineralwasser und gaststätten. Wischen sie die
                            getränkedosen oder flaschen aus bevor sie direkt von
                            ihnen trinken
                          </p>
                           <p style={{textAlign: "justify",}}> Während des ausflugs:</p>
                           <p style={{textAlign: "justify",}}>
                            Hände regelmäßig waschen und berühre dein gesicht
                            nicht oft, insbesondere die T-Zone, d.h. augen, nase
                            und mund.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <div className="border-2">
                        <hr />
                      </div>
                      <Accordion.Item eventKey="5" className="border-0">
                        <Accordion.Header>
                        <p style={{textAlign: "justify",}}>
                          Handdesinfektionsmittel für den gebrauch mitnehmen
                          wenn seife und wasser nicht verfügbar sind?
                          </p>
                        </Accordion.Header>
                        <Accordion.Body>
                        <p style={{textAlign: "justify",}}>
                          Menschenansammlungen meiden oder diejenigen, die
                          symptome aufweisen.
                          </p>
                          <p style={{textAlign: "justify",}}>
                          Nutzen sie servietten auf der toilette statt
                          händetrockner.
                          </p>
                          <p style={{textAlign: "justify",}}>
                          Trinken sie wasser zur flüssigkeitszufuhr und um Ihr
                          Immunsystem zu stärken.
                          </p>
                          <p style={{textAlign: "justify",}}>
                          Hand in hand minimieren kontakt mit anderen.
                          </p>
                          <p style={{textAlign: "justify",}}>
                          Vermeiden sie streetfood und unverpackte lebensmittel.
                          </p>
                          <p style={{textAlign: "justify",}}>
                          Kleidung sofort wechseln nachdem sie das hotel oder
                          Ihr zuhause erreicht haben.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <div className="border-2">
                        <hr />
                      </div>
                      <Accordion.Item eventKey="6" className="border-0">
                        <Accordion.Header>
                        <p style={{textAlign: "justify",}}>
                          Können sie mir helfen, einen COVID-19-test für meine
                          heimreise zu bekommen?
                          </p>
                        </Accordion.Header>
                        <Accordion.Body>
                        <p style={{textAlign: "justify",}}>
                            Ja, wir können dabei behilflich sein. Bitte sprechen
                            sie mit Ihrem reiseberater zum zeitpunkt der buchung
                            oder wenn sie bereits gebucht haben, wenden sie sich
                            bitte an Ihren reisekoordinator
                          </p>
                          <p style={{textAlign: "justify",}}>der diesen service für sie arrangieren kann.</p>
                          <p style={{textAlign: "justify",}}>
                            Ihr reisekoordinator beginnt durch das kennenlernen
                            bei einem PCR-test wird für Ihre reise benötigt: es
                            kommt auf das herkunftsland an, bestimmungsland,
                            fluggesellschaften und wenn sie zwischenlandungen
                            haben in einem anderen land.
                          </p>
                           <p style={{textAlign: "justify",}}>
                            Regeln ändern sich sehr schnell, so unsere
                            reisekoordinatoren stehen über den regeln jedes
                            landes und fluggesellschaftsanforderungen, um
                            sicherzustellen, dass wir über die neuesten updates
                            verfügen.
                          </p>
                           <p style={{textAlign: "justify",}}>
                            Wir finden dann zuverlässig und professionelle
                            labors in den destinationen um zeitpläne zu
                            verstehen und tests zu planen.
                          </p>
                           <p style={{textAlign: "justify",}}>
                            PCR-Tests sind in großstädten einfacher zu
                            koordinieren, aber schwieriger in abgelegenen
                            gebieten.
                          </p>
                           <p style={{textAlign: "justify",}}>
                            Auch mit unseren partnern stehen wir in kontakt um
                            den bestmöglichen service zu finden.
                          </p>
                           <p style={{textAlign: "justify",}}>
                            Abgestimmte PCR-Tests können zu geringfügigen
                            anderungen führen zu reiserouten,zum beispiel,
                            möglicherweise müssen sie eine hauptstadt erreichen
                            24 stunden früher als ursprünglich geplant damit der
                            test gemacht werden kann. Wenn möglich, wir
                            organisieren die probenentnahme in Ihrem hotel
                            anstatt sie zu bitten, ins krankenhaus oder labor zu
                            gehen.
                          </p>
                           <p style={{textAlign: "justify",}}>
                            Wir versuchen, Ihre erfahrung bequemer zu machen,
                            hoteltests sind jedoch nicht immer verfügbar in
                            jedem land.
                          </p>
                           <p style={{textAlign: "justify",}}>
                            Bitte beachten sie dass, während wir alle
                            vorkehrungen für sie treffen, zusätzliche Kosten im
                            zusammenhang mit der prüfung sind nicht enthalten.
                            Sie können sorgenfrei reisen dass egal in welchem
                            ​​land, egal wie abgelegen, wir organisieren die
                            beste verfügbare option für sie um sich rechtzeitig
                            für Ihren heimflug testen zu lassen.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div className="col-lg-4">
                    <img
                      src="https://res.cloudinary.com/enchanting/f_auto,q_70,w_260,h_260,c_fill/et-web/2019/06/Enchanting-Travels-Maldives-2.jpg"
                      class="img-fluid rounded-circle ms-5 shadow"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Covid19;
