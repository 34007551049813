import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { MdOutlineLocationOn } from "react-icons/md";
import { MdOutlineBed } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { postquerydata } from "../redux/actions/queryaction";
import axios from "axios";
import parse from "html-react-parser";
import { Formik } from "formik";
import PhoneInput from "react-phone-number-input";
import Swal from "sweetalert2";

import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
// import { gettourpackage } from "../redux/actions/tourpackageaction";
import * as Yup from "yup";
import { BASE_URL } from "../service/url";
// import HotelGallery from "../components/ReactImageVideoLightbox";
import { ImageSlider } from "react-simple-gallery"
import "react-simple-gallery/dist/index.css"
const validationSchema = Yup.object().shape({
  phoneNo: Yup.number(),
  firstname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  lastname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  email: Yup.string().email("Invalid Email").required("Required*"),
  country: Yup.string(),
});


const TourPackage = () => {
  const [phone, setPhone] = useState("");
  const { id } = useParams();
  const [queryModal, setQueryModal] = useState(false);
  const [queryalert, setQueryalert] = useState(false);
  const [tourpackage, setTourpackage] = useState();
  const dispatch = useDispatch();
  console.log("tour data is", tourpackage);

  useEffect(() => {
    window.scrollTo(0, 0);
    gettourpackage(id);
  }, []);

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };
  // number and text validation
  const onlyTextAllowed = (e) => {
    const re = /^[a-zA-Z]+$/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  const onlyNumberAllow1 = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const queryForm = {
    firstname: "",
    lastname: "",
    email: "",
    phoneNo: "",
    country: "",
    tripduration: "",
    stage: "",
    description: "",
  };

  const userSubmit = (formdata) => {
    console.log("innnnnnnnnn");
    const queryForm = {
      firstname: formdata?.firstname ? formdata?.firstname : "",
      lastname: formdata?.lastname ? formdata?.lastname : "",
      email: formdata?.email ? formdata?.email : "",
      phoneNo: phone,
      country: formdata?.country ? formdata?.country : "",
      tripduration: formdata?.tripduration ? formdata?.tripduration : "",
      stage: formdata?.stage ? formdata?.stage : "",
      description: formdata?.description ? formdata?.description : "",
    };
    console.log("queryform is", queryForm);
    dispatch(postquerydata(queryForm, setQueryalert));
    console.log("query alert state is", queryalert);
  };

  const successAdditionOfCategoryAlert = (title, icon) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        setQueryModal(false);
        window.location.reload();
      } else {
        setQueryModal(true);
      }
    });
  };
  const gettourpackage = async (id) => {
    const response = await axios.get(
      `${BASE_URL}/tour/tourpackage/gettour/${id}`
    );

    console.log("response.data.result----->>", response.data.result[0]);
    setTourpackage(response.data.result[0]);
  };
  console.log("the tourpackage set is", tourpackage);
  useEffect(() => {
    gettourpackage(id);

    if (queryalert) {
      successAdditionOfCategoryAlert("Submitted", "success");
      setQueryModal(false);
    }
  }, [queryalert]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-12">
                  <img
                    src={tourpackage?.image?.[0]}
                    class="img-fluid rounded-top tour-banner w-100"
                    alt=""
                  />
                  <div className="col">
                    <h3 className=" my-2 pt-4">
                      {tourpackage?.state}
                    </h3>
                    <h4 className="text-success">{tourpackage?.packageName}</h4>

                    {/* <h5> {tourpackage?.traveltheme}</h5> */}
                    <p className="py-2 " style={{textAlign:" justify",}}>{tourpackage?.description}</p>
                  </div>
                </div>
                <div className="col-12 ">
                  <div className="col d-flex align-items-center pb-4">
                    <img
                      src="assets/images/tour-package-img/travel-itinerary 1.png"
                      class="img-fluid rounded-top"
                      alt=""
                    />
                    <h4 className="col-auto p-0">
                      Individuell Anpassbare Rundreiseidee
                    </h4>
                  </div>
                  {tourpackage?.iternary?.map((item, index) => (
                    <div className="row">
                      <div className="col-md-6 pt-4">
                        <img
                          src={item?.iternary_image}
                          class="img-fluid rounded-top"
                          alt=""
                        />
                      </div>
                      <div className="col-md-6 pt-4">
                        <h3>
                          Tage {index + 1}: {item?.iternary_title}
                        </h3>
                        <p style={{textAlign:" justify",}}>{item?.iternary_description}</p>
                        <div className="my-3">
                          <span className="mr-3">
                            <MdOutlineLocationOn />
                          </span>
                          {item?.iternary_location}
                        </div>
                        <div className="mb-4">
                          <span  onClick={handleShow} >
                            <MdOutlineBed className="mr-3"/>
                          Stay at{" "}{item?.iternary_hotel}</span>
                          {/* <Link to="/hyatt" className="text-success">
                          Hyatt Regenancy traveltheme {item?.iternary_hotel}
                        </Link> */ 
}
                         
                          
                        
      {/* <ImageSlider data={tourpackage?.image?.[0]}/> */}
      {/* <ReactImageVideoLightbox/> */}
  
                          
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                  {/* <HotelGallery
                  show={show}
                  onHide={handleClose}
                  /> */}
                </div>
                <div className="col-12">
                  
                </div>

                <div className="col-12">
                  <div class="mapouter">
                    <iframe
                      className="gmap_iframe"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      width="100%"
                      height="400"
                      src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Kakker bulding, 1466/1, Lane no : 7, Wazir Nagar , kotlamubarakpur, New delhi, 110003, India&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                  </div>
                </div>
                <div className="col-12 my-3">
                  <h3 className="text-success">Preisangaben</h3>
                  <h5 className="font-weight-bold">
                    Ca.&nbsp;{tourpackage?.budgetperday} /
                    {tourpackage?.budgetperday?.[0]?.Day}Tage
                  </h5>
                  <div className="highlighted-card w-lg-50 px-3 py-2 mx-auto my-4">
                    <h3 className="text-success d-inline-block">
                    €{tourpackage?.budgetperday}
                    </h3>
                    &nbsp; Pro Tag
                    <p>(Steuern sind in diesem Preis nicht enthalten)</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      <h3>Im Preis Inbegriffen</h3>
                      <ul className="list-included">
                        {tourpackage?.includedinpackage &&
                          parse(tourpackage?.includedinpackage)}
                        {/* <li>15 nights in the named hotels (or comparable)</li>
                        <li>Daily breakfast in hotel restaurants</li>
                        <li>Full board in Corbett and Chambal</li>
                        <li>
                          Meeting and assistance on arrival / departure by one
                          of our employees
                        </li>
                        <li>
                          Transport in the following AC vehicles: 1-2 people,
                          Indigo or similar, max. 2 people / 3-4 people, Innova
                          or similar, max. 5 people / 5-7 people, 7-seater van,
                          max. 7 people
                        </li> */}
                      </ul>
                    </div>
                    <div className="col-6">
                      <h3>Nicht Im Preis Inbegriffen</h3>
                      <ul className="list-notincluded">
                        {tourpackage?.excludedinpackage &&
                          parse(tourpackage?.excludedinpackage)}
                        {/* <li>15 nights in the named hotels (or comparable)</li>
                        <li>Daily breakfast in hotel restaurants</li>
                        <li>Full board in Corbett and Chambal</li>
                        <li>
                          Meeting and assistance on arrival / departure by one
                          of our employees
                        </li>
                        <li>
                          Transport in the following AC vehicles: 1-2 people,
                          Indigo or similar, max. 2 people / 3-4 people, Innova
                          or similar, max. 5 people / 5-7 people, 7-seater van,
                          max. 7 people
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="row">
                <div className="col-10 col-lg-12 mx-auto">
                  <Card className="shadow border-success">
                    <Card.Body className="p-0  ">
                      <div className="row mx-0">
                        <div className="card-header bg-card-success text-white">
                          <div className="col-md-12">
                            <div className="row p-2">
                              <div className="col-sm-12 col-md-12 col-lg-4">
                                <img
                                  src="/assets/images/tour-package-img/img2.jpg "
                                  class="img-fluid rounded-top w-100 h-100 img-height-card "
                                  alt=""
                                />
                              </div>
                              <div className="col-md-8 p-0">
                                <div>
                                  <p className="p-font-card">
                                    Personalisieren Sie diese Reise mit Ihrer
                                    lokalen Reise Experte
                                  </p>
                                </div>
                                <div>
                                  <h3 className="text-white h-font-card ">
                                    Michael{" "}
                                  </h3>
                                </div>
                                <div className="col p-0">
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star checked"></span>
                                  <span class="fa fa-star"></span>
                                  <span class="fa fa-star"></span>(1,091
                                  Bewertungen)
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row list-group list-group-flush py-2">
                            <div className="col-12 list-group-item">
                              <div className="row">
                                <div className="col-6">
                                  <span className="text-gray">
                                    Die Summe An Tagen
                                  </span>
                                </div>
                                <div className="col-6">
                                  <h3>{tourpackage?.totaldays} Days</h3>
                                </div> 
                              </div>
                            </div>
                            <div className="col-12 list-group-item">
                              <div className="row">
                                <div className="col-6">
                                  {" "}
                                  <span className="text-gray">Aus</span>
                                </div>
                                <div className="col-6">
                                  <h5 className="text-success">
                                    €{tourpackage?.budgetperday}/-
                                  </h5>
                                  <p>Pro Tag</p>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 list-group-item">
                              <div className="row">
                                <div className="col-6">
                                  <span className="text-gray">
                                    Inklusivleistungen
                                  </span>
                                </div>
                                <div className="col-6">
                                  {tourpackage?.includedservices.map(
                                    (item, index) => (
                                      <button className="bg-none border-0">
                                        {/* <img
                                          src= {item}
                                          className="tour-package-card-logos img-fluid"
                                          width="24px"
                                        /> */}
                                        {item}
                                      </button>
                                    )
                                  )}
                                  {/* <button className="bg-none border-0">
                                    <img
                                      src="/assets/images/tour-package-img/guide.jpg"
                                      className="tour-package-card-logos img-fluid"
                                      width="24px"
                                    />
                                    Driver
                                  </button>
                                  <button className="bg-none border-0 text-left">
                                    <img
                                      src="/assets/images/tour-package-img/guide.jpg"
                                      className="tour-package-card-logos img-fluid"
                                      width="24px"
                                    />
                                    Airport Transfer
                                  </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col custom-btn">
                            <button
                              id="sendquery"
                              className="btn  w-100 bg-card-success  btn-success p-2 mb-3"
                              data-toggle="modal"
                              data-target=".bd-example-modal-lg"
                              onClick={() => {
                                setQueryModal(true);
                              }}
                            >
                              Anfrage Angebot
                              <span>
                                <i className="fas fa-paper-plane" />
                              </span>
                            </button>
                            {queryModal && (
                              <Modal
                                show={queryModal}
                                onHide={() => {
                                  setQueryModal(false);
                                }}
                              >
                                <Modal.Header closeButton></Modal.Header>
                                <Modal.Body>
                                  <Formik
                                    initialValues={queryForm}
                                    onSubmit={userSubmit}
                                    validationSchema={validationSchema}
                                  >
                                    {({
                                      values,
                                      handleChange,
                                      handleSubmit,
                                      errors,
                                      touched,
                                    }) => (
                                      <form
                                        method="post"
                                        name="inquiryFormLarge"
                                        onSubmit={handleSubmit}
                                      >
                                        <div className="form-row">
                                          <div className="col-md-6 form-group">
                                            <input
                                              type="text"
                                              maxLength={30}
                                              id="firstname"
                                              value={values.firstname}
                                              onChange={handleChange}
                                              className="form-control custom-height shadow-sm"
                                              placeholder="Vorname"
                                              onKeyPress={(e) =>
                                                onlyTextAllowed(e)
                                              }
                                            />
                                            <div id="name_error" />
                                            <span
                                              style={{
                                                color: "tomato",
                                                "font-size": "small",
                                              }}
                                            >
                                              {errors.firstname &&
                                                touched.firstname && (
                                                  <div>{errors.firstname}</div>
                                                )}
                                            </span>
                                          </div>
                                          <div className="col-md-6 form-group">
                                            <input
                                              type="text"
                                              id="lastname"
                                              name="lastname"
                                              maxLength={30}
                                              value={values.lastname}
                                              onChange={handleChange}
                                              className="form-control custom-height shadow-sm"
                                              placeholder="Nachname"
                                              onKeyPress={(e) =>
                                                onlyTextAllowed(e)
                                              }
                                            />
                                            <span
                                              style={{
                                                color: "tomato",
                                                "font-size": "small",
                                              }}
                                            >
                                              {errors.lastname &&
                                                touched.lastname && (
                                                  <div>{errors.lastname}</div>
                                                )}
                                            </span>
                                          </div>
                                          <div className="col-md-6 form-group">
                                            <input
                                              type="email"
                                              id=" email"
                                              value={values.email}
                                              onChange={handleChange}
                                              name="email"
                                              className="form-control custom-height shadow-sm"
                                              placeholder="Email*"
                                            />
                                            <div id="email_error" />
                                            <span
                                              style={{
                                                color: "tomato",
                                                "font-size": "small",
                                              }}
                                            >
                                              {errors.email &&
                                                touched.email && (
                                                  <div>{errors.email}</div>
                                                )}
                                            </span>
                                          </div>
                                          <div className="col-md-6 form-group">
                                            <PhoneInput
                                              placeholder="Telefon-Nr"
                                              id=" phoneNo"
                                              name=" phoneNo"
                                              value={phone}
                                              // onChange={(e) => handlePhoneChange(e)}
                                              maxLength="17"
                                              onChange={setPhone}
                                              className=" form-control d-flex  custom-height shadow-sm"
                                            />
                                            <div id="phone_number_error" />
                                            <span
                                              style={{
                                                color: "tomato",
                                                "font-size": "small",
                                              }}
                                            >
                                              {errors.phoneNo &&
                                                touched.phoneNo && (
                                                  <div>{errors.phoneNo}</div>
                                                )}
                                            </span>
                                          </div>

                                          <div className="col-md-6 form-group">
                                            <select
                                              className="form-select custom-height shadow-sm"
                                              id="country"
                                              name="country"
                                              value={values.country}
                                              onChange={handleChange}
                                            >
                                              <option>Land Auswählen</option>
                                              <option value="india">
                                                India
                                              </option>
                                              <option value="germany">
                                                Germany
                                              </option>
                                              <option value="france">
                                                France
                                              </option>
                                            </select>
                                            <span
                                              style={{
                                                color: "tomato",
                                                "font-size": "small",
                                              }}
                                            >
                                              {errors.country &&
                                                touched.country && (
                                                  <div>{errors.country}</div>
                                                )}
                                            </span>
                                          </div>
                                          <div className="col-md-6 form-group">
                                            <input
                                              type="text"
                                              className="form-control custom-height shadow-sm"
                                              placeholder="Reisedauer in Tagen"
                                              id="tripduration"
                                              name="tripduration"
                                              maxLength={2}
                                              value={values.tripduration}
                                              onChange={handleChange}
                                              onKeyPress={(e) =>
                                                onlyNumberAllow1(e)
                                              }
                                            />
                                            <div id="name_error" />
                                          </div>
                                          <div className="col-md-12 form-group">
                                            <select
                                              className="form-select custom-height shadow-sm"
                                              id="stage"
                                              value={values.stage}
                                              onChange={handleChange}
                                            >
                                              <option>
                                                Welche Phase Sie planen
                                              </option>
                                              <option value="Still dreaming/ Researching">
                                                Ich träume immer noch/forsche
                                              </option>
                                              <option value="Definitely travelling not sure which part of India">
                                                Ich bin mir auf jeden Fall nicht
                                                sicher, in welchen Teil Indiens
                                              </option>
                                              <option value="I want to Book my trip">
                                                Ich möchte meine Reise buchen
                                              </option>
                                            </select>
                                          </div>
                                          <div className="form-group col-12">
                                            <textarea
                                              className="form-control shadow-sm"
                                              id="description"
                                              value={values.description}
                                              placeholder="Beschreibung"
                                              onChange={handleChange}
                                              rows={5}
                                              defaultValue={""}
                                            />
                                            <div id="message_error" />
                                          </div>
                                          <div className="col-md-6 form-group">
                                            <input
                                              type="hidden"
                                              name="inquiry_status"
                                              defaultValue="send_query"
                                              className="form-control custom-height shadow-sm"
                                            />
                                          </div>
                                        </div>
                                        <div className="custom-btn col-12 px-2">
                                          <button
                                            type="submit"
                                            className="btn btn-success w-100 py-2"
                                          >
                                            Abschicken &nbsp;
                                            <i className="fas fa-paper-plane" />
                                          </button>
                                        </div>
                                      </form>
                                    )}
                                  </Formik>
                                </Modal.Body>
                              </Modal>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />


  
    </>
  );
};

export default TourPackage;
