import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { getreviewdata } from "../redux/actions/reviewaction";
import ReviewCard from "./ReviewCard";
// import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

const ReviewCardSlider = () => {
  const dispatch = useDispatch();
  const [tourModal, setTourModal] = useState(false);

  const reviewData = useSelector((state) => state?.allreviews?.reviews);
  console.log("the review data coming from the backend is ", reviewData);
  // const gettopreview = reviewData?.splice(0, 3);

  const settings = {
    // dots: true,
    infinite: true,
    // speed: 2000,
    // autoplay:true,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          variableWidth: false,
        },
      },
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 734,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  useEffect(() => {
    dispatch(getreviewdata());
  }, []);
  return (
    <div className="container">
      <Slider {...settings}>
        {reviewData?.map((item) => (
          <div className="d-flex">
            <ReviewCard
              id={item?._id}
              // image="https://res.cloudinary.com/enchanting/f_auto,q_70,w_150,h_150,c_fill/et-web/2017/06/Enchanting-Travels-English-Guest-Africa-South-Africa-Alan-Strozza-Lori-Besen-africa-travel.jpg"
              image={item?.image[0]}
              author={item?.name}
              date={item?.createdAt}
              rating={item?.rating}
              comment={item?.review}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ReviewCardSlider;
