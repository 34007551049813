import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Tourdetails = () => {
  return (
    <>
    <Header />
<div>
  <div className="inner-banner-top blog-detisltop-banner1">
    <div className="container">
      <div className="row">
        <div className="banner-overlay d-flex justify-content-center align-items-center">
          <div className="banner-text">
            <h1>
              
              Indian Safari
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="blog-details-1 bg-pattern-1">
    <div className="container-fluid px-md-5">
      <div className="row">
        <div className="col-md-12 text-center common-heading pb-5">
          <p className="title mb-0">
            
            Lorem Ipsum
          </p>
          <h2>
            
            Welcome To Real Safari Of India
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p>
            
            Welcome to the land of the exquisite Tiger as well as the last abode
    of the Asiatic Lions. India is a country with a diversified
    environment in which loads of different flora fauna coexist, similar
    to its varied culture, languages, heritage and other aspects. An
    Indian Wildlife safari is not a different case as well!
          </p>
          <p>
            
            India is a country with 26 fascinating world heritage locations, 13
    Ramsay spots alongside 13 Biosphere Reserves; this nation is surely
    heaven! In addition to its bird’s animals, this nation also consists
    of more than 20,000 species of insects, 142 species of frogs, 2,000
    species of butterflies and more than 700 species of fish. That makes
    the whole country wildlife bliss as well as an ultimate harbour for
    natural wildlife followers and admirers.
          </p>
        </div>
        <div className="col-md-12 px-md-5 recommended-tours">
          <div className="row">
            &lt;% if(tour_records.length&gt;0 ){'{'} %&gt; &lt;%
            tour_records.forEach(function(row){'{'} %&gt;
            <div className="col-md-4 my-3">
              <div className="card text-white">
                <div className="position-relative">
                  <img className="card-img img-fluid" src="../assets/images/dbimages/<%=row.image%>" alt="Card image" />
                  <div className="card-img-overlay">
                    <div className="card-info">
                      <h5 className="card-title font-weight-light mb-1">
                        &lt;%= row.translation.tour_name%&gt;
                      </h5>
                      <div className="d-flex card-text">
                        <span>&lt;%= row.duration%&gt; &lt;%= translate.days%&gt;:</span>
                        <span className="px-2"><i className="fas fa-rupee-sign" /> 10,000/&lt;%= translate.person %&gt;</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="service-icon d-flex">
                    <span className="pl-0"><img src="../assets/images/vector-img/hotel.png" alt="vector-img1" /></span>
                    <span><img src="../assets/images/vector-img/restaurant.png" alt="vector-img1" /></span>
                    <span><img src="../assets/images/vector-img/car.png" alt="vector-img1" /></span>
                    <span><img src="../assets/images/vector-img/plane.png" alt="vector-img1" /></span>
                  </div>
                  <p className="body-text pt-2">&lt;%= row.translation.description%&gt;</p>
                  <hr />
                  <div className="row btn-link-custom">
                    <div className="col-6 text-center border-right-custom">
                      <a style={{cursor: 'pointer'}} onclick="function_url('/destinationdetails?tour_name=<%= row.tour_name %>')" className="link-text  btn text-uppercase">
                        &lt;%= translate.detail_btn %&gt;
                        {/* Details */}
                      </a>
                    </div>
                    <div className="col-6 text-center">
                      <button className="link-text btn link-text__green text-uppercase" data-toggle="modal" data-target="#exampleModal">
                        &lt;%= translate. inquiry_btn %&gt;
                        {/* INQUIRE */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            &lt;% {'}'}){'}'} else {'{'}%&gt;
            <div>
              <h1>No Tour Found</h1>
            </div>
            &lt;% {'}'} %&gt;
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="travel-agency bg-pattern-2">
    <div className="container">
      <div className="col-md-12 text-center common-heading pb-5 pt-5">
        <p className="title mb-0">
          &lt;%=translate.title2%&gt;
          {/* Why to travel */}
        </p>
        <h2>
          &lt;%=translate.sub_title2%&gt;
          {/* With our travel agency? */}
        </h2>
      </div>
      <div className="row justify-content-center">
        <div className="col-xl col-md-4 text-center my-3">
          <img className="why-travel-img" src="../assets/images/why-travel/img1.png" alt="travel-agency-img1" />
          <div className="travel-agency-text">
            <h6>
              &lt;%=translate.eco_tab%&gt;
              {/* Eco Friendly */}
            </h6>
            <p>
              &lt;%=translate.eco_tab1%&gt;
              {/* Invest in environment */}
            </p>
          </div>
        </div>
        <div className="col-xl col-md-4 text-center my-3">
          <img className="why-travel-img" src="../assets/images/why-travel/img4.png" alt="travel-agency-img2" />
          <div className="travel-agency-text">
            <h6>
              &lt;%=translate.skilled_tab%&gt;
              {/* Highly Skilled */}
            </h6>
            <p>
              &lt;%=translate.skilled_tab1%&gt;
              {/* Local knowledge */}
            </p>
          </div>
        </div>
        <div className="col-xl col-md-4 text-center my-3">
          <img className="why-travel-img" src="../assets/images/why-travel/img5.png" alt="travel-agency-img3" />
          <div className="travel-agency-text">
            <h6>
              &lt;%=translate.tailor_tab%&gt;
              {/* Trailor-Made */}
            </h6>
            <p>
              &lt;%=translate.skilled_tab1%&gt;
              {/* Unique and bespoke itineraries */}
            </p>
          </div>
        </div>
        <div className="col-xl col-md-4 text-center my-3">
          <img className="why-travel-img" src="../assets/images/why-travel/img1.png" alt="travel-agency-img4" />
          <div className="travel-agency-text">
            <h6>
              &lt;%=translate.committed_tab%&gt;
              {/* Committed */}
            </h6>
            <p>
              &lt;%=translate.committed_tab1%&gt;
              {/* Committed to responsible travel */}
            </p>
          </div>
        </div>
        <div className="col-xl col-md-4 text-center my-3">
          <img className="why-travel-img" src="../assets/images/why-travel/img2.png" alt="travel-agency-img5" />
          <div className="travel-agency-text">
            <h6>
              &lt;%=translate.happiness_tab%&gt;
              {/* Happiness */}
            </h6>
            <p>
              &lt;%=translate.happiness_tab1%&gt;
              {/* High traveller satisfaction */}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="bg-banner d-flex">
    <div className="container-fluid align-self-center">
      <div className="row">
        <div className="content-box align-items-center d-flex">
          <div>
            <span className="title mb-4"><h2 className="d-inline">
                &lt;%=translate.title3%&gt;
                {/* Lorem ipsum, */}
              </h2>
              {/* or lipsum */}
            </span>
            <p className="pt-3">
              &lt;%=translate.sub_title3%&gt;
              {/*             
      is sometimes known, is dummy text used in laying out print, graphic
      or web designs. is sometimes known, is dummy text used in laying out
      print, graphic or web designs. is sometimes known, is dummy text
      used in laying out print, graphic or web designs. is sometimes
      known, is dummy text used in laying out print, graphic or web
      designs. */}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="tabs-travel">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-10 mx-auto">
          <nav className>
            <div className="nav shadow-sm nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                &lt;%=translate.travel_expert%&gt;
                {/* Your Travel Expert */}
              </a>
              <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                &lt;%=translate.safari_trip%&gt;
                {/* Safari Trip */}
              </a>
              <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">
                &lt;%=translate.india_trip%&gt;
                India Trip
              </a>
              <a className="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">
                &lt;%=translate.good_reasons%&gt;
                {/* 7 Good Reasons To Travel To Jungle Safari */}
              </a>
            </div>
          </nav>
          <div className="tab-content py-3 px-3 px-sm-0 pt-5" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-sm-4">
                    <div className="user-img">
                      <img className="img-fluid" src="../assets/images/user-img-2.png" alt="user img-" />
                    </div>
                  </div>
                  <div className="col-lg-9 col-sm-8">
                    <p>
                      &lt;%=translate.travel_expert_desc%&gt;
                      {/* Best Proficiency of ourIndian Travel Agents in London UK,
              Hansa India Journeys is selling India trip travel services
              and mesmerizing experiences over the years through our
              state-of-the-art fabricated India trip travel services are
              an assurance of spellbinding our diverse customer base.
              Booking an adventure tour to India with an adventure tour
              company in India might seem to be a hard task but once you
              begin with our Indian travel agents in uk, you can
              definitely understand how effectivelywe provide India trip
              travel services. Our Boutique travel agency India fabricate
              comprehensive India trip travel services for you and assure
              you of far surpassing your expectations. */}
                    </p>
                    <div className="custom-btn">
                      <button className="btn btn-success py-2" data-toggle="modal" data-target="#exampleModal">
                        &lt;%=translate.contact_expert%&gt;
                        {/* Contact a Travel Expert */}
                      </button>
                    </div>
                    `
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div className="recommended-tours">
                <div className="row">
                  &lt;% if(tour_records.length&gt;0 ){'{'} %&gt; &lt;%
                  tour_records.forEach(function(row){'{'} %&gt;
                  <div className="col-md-4 my-3">
                    <a href="destinationdetails?tour_name=<%= row.tour_name %>">
                    </a><div className="card text-white"><a href="destinationdetails?tour_name=<%= row.tour_name %>">
                        <div className="position-relative">
                          <img className="card-img img-fluid" src="../assets/images/dbimages/<%=row.image%>" alt="Card image" />
                          <div className="card-img-overlay">
                            <div className="card-info">
                              <h5 className="card-title font-weight-light mb-1">
                                &lt;%=row.tour_name%&gt;
                              </h5>
                              <div className="d-flex card-text">
                                <span>&lt;%= row.duration%&gt; Days:</span>
                                <span className="px-2"><i className="fas fa-rupee-sign" /> 10,000/Person</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a><div className="card-body"><a href="destinationdetails?tour_name=<%= row.tour_name %>">
                          <div className="service-icon d-flex">
                            <span className="pl-0"><img src="../assets/images/vector-img/hotel.png" alt="vector-img1" /></span>
                            <span><img src="../assets/images/vector-img/restaurant.png" alt="vector-img1" /></span>
                            <span><img src="../assets/images/vector-img/car.png" alt="vector-img1" /></span>
                            <span><img src="../assets/images/vector-img/plane.png" alt="vector-img1" /></span>
                          </div>
                          <p className="body-text pt-2 py-3">&lt;%= row.description %&gt;</p>
                          <hr />
                        </a><div className="row btn-link-custom"><a href="destinationdetails?tour_name=<%= row.tour_name %>">
                          </a><div className="col-6 text-center border-right-custom"><a href="destinationdetails?tour_name=<%= row.tour_name %>">
                            </a><a href="#" className="link-text text-uppercase">
                              &lt;%= translate.detail_btn%&gt;
                              {/* Details */}
                            </a>
                          </div>
                          <div className="col-6 text-center">
                            <a href="#" className="link-text link-text__green text-uppercase" data-toggle="modal" data-target="#exampleModal">
                              &lt;%= translate.inquery_btn%&gt;
                              {/* INQUIRE */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  &lt;% {'}'}){'}'} %&gt;
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="text-center pb-3">
                    &lt;%=translate.good_reasons%&gt;
                    {/* 7 good reasons to travel to Indian Jungle Safari */}
                  </h4>
                  <div>
                    <p>
                      <span className="font-weight-bold">1.)</span>
                      <span className="text-green">Kanha:</span> 
                      &lt;%=translate.reason1%&gt;
                      {/* While the plateau
              here is covered with grasses, bamboo and saline are abundant
              on the flatter sections. During a journey through the area,
              see tigers, swamp deer, sloth bears, blackbucks, owls,
              hyenas and pythons. */}
                    </p>
                    <p>
                      <span className="font-weight-bold"> 2.)</span>
                      <span className="text-green">Bandhavgarh:</span>
                      
                      Sal and bamboo
              are the most common trees here, along with fruit trees such
              as mangoes and berries. Some of the most prominent animals
              found here are leopard, gaur, vulture, chinkara, nilgai and
              four-horned antelope.
                    </p>
                    <p>
                      <span className="font-weight-bod"> 4.)</span>
                      <span className="text-green">Pench:</span>
                      
                      This wonderful place
              is located in Madhya Pradesh. Grass and dense, dry and moist
              forests are found here, in which salts and bamboo dominate
              the area. Deer, fox, hyena, jungle fowl, peacock and about
              170 bird species have been recorded here.
                    </p>
                    <p>
                      <span className="font-weight-bold">5.)</span>
                      <span className="text-green">Panna:</span>
                      
                      This place is located
              in an area where the northern plains meet the tropical humid
              and dry deciduous belt. Among trees, teak is the most widely
              distributed type, with patches of grasses and thorny plants.
              Here you can see wolves, tigers, hyenas, geese and vultures.
                    </p>
                    <p>
                      <span className="font-weight-bold"> 6.) </span>
                      <span className="text-green">Corbett:</span> 
                 
                      Probably the most
              famous place for wildlife safari in India is Corbett
              National Park in Uttarakhand. The geography here is
              characterized by hills, valleys, ravines, valleys and narrow
              streams. Sal, papal and bamboo forests provide shelter to
              creatures such as bears, tigers, elephants, buffalo and
              monkeys.
                    </p>
                    <p>
                      <span className="font-weight-bold">7.)</span>
                      <span className="text-green">Sundarbans:</span> 
                     
                      Situated in the
              vast delta of the river Ganges, it is a vast mangrove
              forest. In addition to mammals, you will be able to see
              dolphins and porpoises, and birds such as cranes and
              kingfishers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
              
              Et et consectetur ipsum labore excepteur est proident excepteur ad
      velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt
      anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit
      non irure adipisicing aliqua ullamco irure incididunt irure non esse
      consectetur nostrud minim non minim occaecat. Amet duis do nisi duis
      veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui
      sit. Exercitation mollit sit culpa nisi culpa non adipisicing
      reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad
      duis occaecat ex.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-partners">
    <div className="container-fluid px-md-5">
      <div className="row">
        <div className="col-md-12 text-center common-heading pb-5">
          <p className="title mb-0">
            
            Lorem Ipsum
          </p>
          <h2>
            
            Our Partners
          </h2>
        </div>
      </div>
      <div className="col-auto mx-auto custom-slide-with">
        <div className="owl-carousel our-slide">
          <div className="partners-img">
            <img src="../assets/images/our-partners/img-1.png" alt="our-partner1" />
          </div>
          <div className="partners-img">
            <img src="../assets/images/our-partners/img-2.png" alt="our-partner2" />
          </div>
          <div className="partners-img">
            <img src="../assets/images/our-partners/img-3.png" alt="our-partner3" />
          </div>
          <div className="partners-img">
            <img src="../assets/images/our-partners/img-4.png" alt="our-partner4" />
          </div>
          <div className="partners-img">
            <img src="../assets/images/our-partners/img-1.png" alt="our-partner1" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="travellers-review bg-pattern-2">
    <div className="container-fluid px-md-5">
      <div className="row">
        <div className="col-md-12 text-center common-heading pb-5">
          <p className="title mb-0">
            
            Testimonials
          </p>
          <h2>
            
            What our guests have to say about us
          </h2>
        </div>
      </div>
      <div className="col-md-12">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6 col-12 my-3">
            <div className="box shadow-sm">
              <div className="review-text text-center pl-4">
                <p>
                  &lt;%= translate.title6%&gt;
                  Lorem ipsum, or lipsum as it is sometimes known, is dummy text
          used in laying out print, graphic or web designs.
                </p>
                <div className="pt-2">
                  <h6 className="mb-0 user-name">
                    
                    Josef
                  </h6>
                  <ul className="list-unstyled d-flex reting-star justify-content-center">
                    <li><i className="far fa-star" /></li>
                    <li><i className="far fa-star" /></li>
                    <li><i className="far fa-star" /></li>
                    <li><i className="far fa-star" /></li>
                    <li><i className="fas fa-star text-green" /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 my-3">
            <div className="box shadow-sm">
              <div className="review-text text-center pl-4">
                <p>
                  
                  Lorem ipsum, or lipsum as it is sometimes known, is dummy text
          used in laying out print, graphic or web designs.
                </p>
                <div className="pt-2">
                  <h6 className="mb-0 user-name">
                    
                    Josef
                  </h6>
                  <ul className="list-unstyled d-flex reting-star justify-content-center">
                    <li><i className="far fa-star" /></li>
                    <li><i className="far fa-star" /></li>
                    <li><i className="far fa-star" /></li>
                    <li><i className="far fa-star" /></li>
                    <li><i className="fas fa-star text-green" /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12 my-3">
            <div className="box shadow-sm">
              <div className="review-text text-center pl-4">
                <p>
                  
                  Lorem ipsum, or lipsum as it is sometimes known, is dummy text
          used in laying out print, graphic or web designs.
                </p>
                <div className="pt-2">
                  <h6 className="mb-0 user-name">
                    
                    Josef
                  </h6>
                  <ul className="list-unstyled d-flex reting-star justify-content-center">
                    <li><i className="far fa-star" /></li>
                    <li><i className="far fa-star" /></li>
                    <li><i className="far fa-star" /></li>
                    <li><i className="far fa-star" /></li>
                    <li><i className="fas fa-star text-green" /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="subscribe-newslatter">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 col-sm-8 line-center mx-auto text-center">
          <h5 className>
            
            GET OUR SESSIONAL DEALS AND OFFERS
          </h5>
          <div className="content-box pb-0">
            <p className="text-paragraph">
              
              Subscribe our Newsletters to get updated and latest offers Directly
      in your Email.
            </p>
            <form name="newsletter" method="post" action>
              <div className="form-group">
                <input type="email" name="nemail" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email" />
                <div id="nemail_error" />
              </div>
              <div className="custom-btn">
                <button className="btn btn-warning">
                  
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<Footer />

    </>
  )
}

export default Tourdetails