import { ActionTypes } from "../constants/actiontypes";

const initialState = {
  tour: [],
};

export const loadtourpackage = (state = initialState, { type, payload }) => {
  console.log("-----------payload", type, payload);
  switch (type) {
    case ActionTypes.SET_TOUR_PACKAGE:
      return { tour: payload };
    default:
      return state;
  }
};
