import { ActionTypes } from "../constants/actiontypes";

const initialState = {
  // relatedthemes: [],
  citydetails: [],
};

// export const cityReducer = (
//     state = initialState,
//     { type, payload }
//   ) => {
//     switch (type) {
//       case ActionTypes.SET_CITY_DETAILS:
//         return { ...state, citydetails: payload };
//       default:
//         return state;
//     }
//   };


  export const cityReducer = (state = initialState, { type, payload }) => {
    console.log("to find the datan payload", type, payload);
    switch (type) {
      case ActionTypes.SET_CITY_DETAILS:
        return { citydetails: payload};
      default:
        return state;
    }
  };