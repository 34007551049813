import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { AiOutlineCheck } from "react-icons/ai";

const OurTeam = () => {
  const [key, setKey] = useState("expert");
  return (
    <div>
      <Header />

      <div className="container">
          <div className="row">
            <div className="col-12 text-center"></div>
          </div>
        </div>
        <div
          className="inner-banner-top "
          style={{
            backgroundImage: `url("../assets/images/banner/thumbnail_Why India Unlimited Cover Phot.jpg")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="banner-overlay d-flex px-5 py-4 align-items-end">
                <div className="banner-text">
                  <h1> Unser Team</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="row ">
              <div className="col-sm-12 col-lg-8 ourteam-articles">
                <div className="mt-4" style={{textAlign: "justify",}}>
                  <p>
                    <strong className="text-success">Wer wir sind?</strong>
                  </p>
                  <p>
                    Genau wie unsere Destinationen, unser team ist eine Mischung
                    aus kulturen mit ausgesprochen individuellen perspektiven.
                    Zusammen haben wir eine abwechslungsreiche
                    karrieregeschichte, von unternehmensjuristen, journalisten,
                    lehrer und banker an eventplaner und hotelmanagement,
                    krankenpfleger und sogar Tanzlehrer, doch was uns verbindet,
                    ist unsere wahre leidenschaft für das reisen.
                  </p>

                  <p>
                    Wir stimmen zu, wenn unsere gäste es uns sagen wir haben
                    traumjobs. Wir testen unsere hotels regelmäßig, routen und
                    erlebnisse um sicherzustellen, dass Sie die reibungsloseste
                    und beste reise haben
                  </p>
                  <p>
                    Unsere vision für India Unlimited gehört schon immer dazu auf
                    die gesellschaft und lernen anbieten und wachstumschancen.
                    Im einklang mit dieser, wir lassen uns von vier grundwerten
                    leiten: gegenseitiges vertrauen und respekt, ehrlichkeit &
                    integrität, motivation & offenheit zum lernen, und
                    leidenschaft & sorgfalt.
                  </p>
                  <p>
                    Wir teilen eine vision für eine mitfühlendere welt durch die
                    freude am reisen.
                  </p>
                </div>
                <div className="ourteam-article col-sm-12">
                  <div className="ourteam-article-heading w-100 py-4">
                    <h2 className="ourteam-heading w-100">Über Führung</h2>
                  </div>
                  <div className="ourteam-paragraph" style={{textAlign: "justify",}}>
                    <p>
                      <strong>Wir glauben an mit gutem beispiel voran.</strong>
                    </p>
                    <p>
                      Das management weiß, dass führungskräfte geben den ton für
                      das gesamte unternehmen an. Deshalb machen wir es ein
                      ziel, harmonische arbeitsbeziehungen zu schaffen und ein
                      positives arbeitsklima für alle mannschaften. Der
                      enthusiasmus unseres managementteams für Ihre traumreise
                      wahr werden ist ansteckend, gelinde gesagt. Die straßen
                      genommen haben sowohl gut als auch weniger gereist, Wir
                      nutzen jahrelanges wissen und erfahrung zu führen und
                      unterstützen sie unser globales Team.
                    </p>
                    <p>
                      Wir sind spezialisiert auf Design wirklich komplizierte
                      und unvergessliche reisen für unsere kunden. Wir leben und
                      atmen die reiseziele wir schicken unsere Kunden an und
                      deshalb wir lieben es, personalisierte reiserouten zu
                      entwerfen die eine mischung aus enthalten faszinierende
                      reiseziele, wirklich immersive erfahrungen und vielleicht
                      entspannende Strandzeit am Ende.
                    </p>
                    <p>
                      Wir haben ein beneidenswertes schwarzbuch von kontakten
                      entwickelt von den besten lokalen führern bis hin zu
                      privaten fahrern voller wissen und persönlichkeit. Wir
                      konzentrieren uns auf das kleinste detail auf deiner
                      reise, also musst du nicht.
                    </p>
                  </div>
                  <div className="ourteam-members">
                    <div className="ourteam-member row mt-3 py-3">
                      <div className="col-sm-12 col-md-3 text-center text-md-left">
                        <Link to="/">
                          <img
                          src="/assets/images/our-team/thumbnail_ravinder 01.png"
                            alt="img"
                            className="img-fluid rounded-circle"
                            height="158px"
                          />
                        </Link>
                      </div>
                      <div className="col-sm-12 col-md-9 text-center text-md-left">
                        <div className="text-center text-md-left my-3 mt-2 mt-0">
                          <span className="pr-2 h5"  style={{       fontSize:"32px" , fontFamily: "'Satisfy'", color:" #4baf5d"}}>Ravindra Singh</span>

                       
                          <span className="border-left pl-2 h5" 
                             style={{       fontSize:"20px" ,
                             fontFamily: "'Satisfy'",
                            }}>
                            CEO & Geschäftsführer
                          </span>
                        </div>
                        <div className="member-description" style={{textAlign: "justify",}}>
                          <p>Namaste</p>
                          <p>
                            Mein Name ist Ravindra Singh und ich komme aus
                            Jaipur, der rosa Stadt. Ich habe India Unlimited
                            Pvt. Ltd. gegründet, ein örtliches indisches
                            Reisebüro, das individuelle Reisen zu fairen Preisen
                            anbietet, wobei der Schwerpunkt auf die Qualität
                            gelegt wird und Touristen einen Eindruck vom
                            authentischen indischen Leben erhalten. Reisen nach
                            Indien macht immer Spaß, wie die Menschen,
                            authentische Kulturen, Traditionen, Feste, Farben
                            und viele mehr zu erleben, die den Urlaub unendlich
                            und zauberhaft machen. Ich habe fast ganz Indien
                            besucht, vom nördlichen Himalaya bis zu den
                            Backwaters von Südindien, von den hochgelegenen
                            Teegärten von Ostindien (Darjeeling) bis zur Wüste
                            von Westindien (Rajasthan), und besuche ständig
                            verschiedene Gebiete, um das Beste von Indien zu
                            zeigen. Ich kümmere mich um neue Produkte,
                            Gästebetreuung und Datenverarbeitung. Ich bin
                            Tourismus-Absolvent von der CBS International
                            Business School – Koln. Ich spreche Deutsch und
                            Englisch.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="ourteam-member row mt-3 py-3">
                      <div className="col-sm-12 col-md-3 text-center text-md-left">
                        <Link to="/">
                          <img
                          src="/assets/images/our-team/Doris pic.jpg"
                            alt="img"
                            className="img-fluid rounded-circle"
                            height="158px"
                          />
                        </Link>
                      </div>
                      <div className="col-sm-12 col-md-9 text-center text-md-left" >
                        <div className="text-center text-md-left my-3 mt-0">
                        <span className="  pr-2 h5"  style={{       fontSize:"32px" , fontFamily: "'Satisfy'", color:" #4baf5d"}}>Doris Dubler</span>

      <span  className="border-left pl-2 h5"   style={{       fontSize:"20px" ,
    fontFamily: "'Satisfy'",
   }}>

                            Vice president
                          </span>
                        </div>
                        <div className="member-description">
                          <p style={{textAlign: "justify",}}>
                            Wir sind ein Team von enthusiastischen
                            Reise-Experten mit langjähriger Erfahrung. Ich habe
                            über 35 Jahre in der Tourismusindustrie für
                            verschiedene Reiseziele gearbeitet, aber Indien war
                            für mich wie eine zweite Heimat und habe oft in
                            Indian mehrere Wochen gelebt. Wir folgen den
                            Prinzipien des Ökotourismus und führen unsere
                            Aktivitäten so durch, dass die Umwelt nicht
                            geschädigt wird. Wir fördern lokale Aktivitäten in
                            abgelegenen Dörfern, indem wir die lokalen
                            Gemeinschaften einbeziehen. Unsere Kunden werden
                            ermutigt, sich an diesen Initiativen zu beteiligen.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="ourteam-member row mt-3 py-3">
                      <div className="col-sm-12 col-md-3 text-center text-md-left">
                        <Link to="/">
                          <img
                            src="/assets/images/our-team/sumer pic.jpeg"
                            alt="img"
                            className="img-fluid rounded-circle"
                            height="158px"
                          />
                        </Link>
                      </div>
                      <div className="col-sm-12 col-md-9 text-center text-md-left" style={{textAlign: "justify",}}>
                        <div className="text-center text-md-left my-3 mt-0">
                          <span className="pr-2 h5">Shakti Singh</span>

                          <span className="border-left pl-2 h5" style={{       fontSize:"20px" ,
    fontFamily: "'Satisfy'",
   }}>
                            HEAD of Operations
                          </span>
                        </div>
                        <div className="member-description">
                          <p style={{textAlign: "justify",}}>
                            Nach seinem Bachelor-Abschluss in Informatik
                            arbeitete Shakti mehrere Jahre lang als Entwickler
                            in der Softwarebranche, bevor er seine wahre
                            Berufung in der Lösung von Geschäftsproblemen durch
                            Technologie fand. Shakti liebt alles, was mit
                            Technik zu tun hat, die alte indische Kultur und
                            Sanskrit-Schriften. Er liest gerne über Startups,
                            baut erfolgreiche Teams auf und arbeitet an der
                            Produktentwicklung.
                          </p>
                          <p>
                            Ich kümmere mich bei der Vorbereitung der Reise
                            speziell darum, neue Erfahrungen zu sammeln und die
                            Kultur und Traditionen kennenzulernen. Ich habe mich
                            entschieden, in der Tourismusindustrie zu arbeiten,
                            da es mir die Möglichkeit gegeben hat, neue Leute
                            aus verschiedenen Teilen der Welt kennenzulernen und
                            neue Orte zu entdecken, da ich immer etwas Neues
                            kennenlerne, welches den Eifer in mir weckt. Während
                            ich den Touristen seit Jahren diene, um den
                            Reisenden etwas Neues zu bieten, heiße ich sie
                            willkommen in India!!!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="ourteam-member row mt-3 py-3">
                      <div className="col-sm-12 col-md-3 text-center text-md-left">
                        <Link to="/">
                          <img
                            src="/assets/images/our-team/team-4.jpg"
                            alt="img"
                            className="img-fluid rounded-circle"
                          />
                        </Link>
                      </div>
                      <div className="col-sm-12 col-md-9 text-center text-md-left" >
                        <div className="text-center text-md-left my-3 mt-0">
                          <span className="pr-2 h5"  style={{       fontSize:"32px" , fontFamily: "'Satisfy'", color:" #4baf5d"}}>Sumer Singh Rathaore</span>

                          <span className="border-left pl-2 h5" style={{       fontSize:"20px" ,
    fontFamily: "'Satisfy'",
   }}>
                            Örtliche Reiseleiter   

                          </span>
                        </div>
                        <div className="member-description">
                          <p  style={{textAlign: "justify",}}>
                            Namaste! Ich bin Sumer Singh Rathore, Sie konnen
                            mich als Sumi nannen.
                          </p>
                          <p  style={{textAlign: "justify",}}>
                            Ich begann 2007 als freiberuflicher Reiseleiter zu
                            arbeiten, bis ich zu "India Unlimited" kam. Die
                            Freiheit, die India Unlimited mir gibt, um das
                            Reiseprogramm mit den Gästen zusammen zu planen, ist
                            eine wunderbare Sache.
                          </p>
                          <p  style={{textAlign: "justify",}}>
                            Viele Reiseveranstalter planen alle Details der
                            Besichtigungen im Voraus, und wenn den Gästen das
                            Programm nicht passt, müssen wir mit dem Unternehmen
                            abklären, ob wir die Reiseroute ändern oder ergänzen
                            können. Bei India Unlimited kann ich solche
                            Entscheidungen selbst treffen, und diese
                            Flexibilität kommt den Gästen sehr zugute.
                          </p>
                          <p  style={{textAlign: "justify",}}>
                            Der heilige Augustinus hatte etwas sehr Bedeutsames
                            gesagt: "Die Welt ist ein Buch, und wer nicht reist,
                            liest nur eine Seite." Daran glaube ich von ganzem
                            Herzen. Ich habe das große Glück, einen Beruf
                            gewählt zu haben, der mir so viele Seiten dieses
                            Buches geöffnet hat!
                          </p>
                          <p  style={{textAlign: "justify",}}>
                            Mein Traum ist reisen und noch viele schöne Orte auf
                            dieser Welt zu sehen.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-4 d-lg-block d-none sidebar">
                <div className="subnav mt-5 shadow">
                  <div className="heading font-weight-800"></div>
                  <ul className="checklist ">
                    <Link className="text-success" to="/whyindiaunlimited">
                      <li>India Unlimited</li>
                    </Link>
                    <Link className="text-success" to="/privatetour">
                      {" "}
                      <li> Individuelle Und Maßgeschneiderte Reisen</li>
                    </Link>

                    <Link className="text-success" to="/indiatravelnotes">
                      {" "}
                      <li>Indien Reiseberichte</li>
                    </Link>

                    <Link className="text-success" to="/faqs">
                      {" "}
                      <li>FAQs</li>
                    </Link>
                    <Link className="text-success" to="/Covid19faq">
                      {" "}
                      <li>COVID-19 FAQs</li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <section className="tabs-travel">
        <div className="container-fluid">
          <div className="row navigation-tabs">
            <div className="col-md-10 mx-auto">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="expert" title="Your Travel Expert">
                  <div className="row my-2">
                    <div className="col-8 col-lg-4 d-flex justify-content-center mx-auto">
                      <img
                        src="assets/images/user-img-2.png"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-12 col-lg-8">
                      <p className="mt-5">
                        Best Proficiency of ourIndian Travel Agents in London
                        UK, Hansa India Journeys is selling India trip travel
                        services and mesmerizing experiences over the years
                        through our state-of-the-art fabricated India trip
                        travel services are an assurance of spellbinding our
                        diverse customer base. Booking an adventure tour to
                        India with an adventure tour company in India might seem
                        to be a hard task but once you begin with our Indian
                        travel agents in uk, you can definitely understand how
                        effectivelywe provide India trip travel services. Our
                        Boutique travel agency India fabricate comprehensive
                        India trip travel services for you and assure you of far
                        surpassing your expectations.
                      </p>
                      <button className="btn btn-success mt-2 p-2">
                        Contact to a travel expert
                      </button>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="trip" title="Safari Trip">
                  Best Proficiency of ourIndian Travel Agents in London UK,
                  Hansa India Journeys is selling India trip travel services and
                  mesmerizing experiences over the years through our
                  state-of-the-art fabricated India trip travel services are an
                  assurance of spellbinding our diverse customer base. Booking
                  an adventure tour to India with an adventure tour company in
                  India might seem to be a hard task but once you begin with our
                  Indian travel agents in uk, you can definitely understand how
                  effectivelywe provide India trip travel services. Our Boutique
                  travel agency India fabricate comprehensive India trip travel
                  services for you and assure you of far surpassing your
                  expectations.
                </Tab>
                <Tab eventKey="india" title="India Trip">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                  qui explicabo quasi facere harum corrupti aspernatur modi,
                  quas suscipit ducimus iusto magni soluta nesciunt commodi
                  reiciendis asperiores voluptatem alias, quia aperiam pariatur.
                </Tab>
                <Tab
                  eventKey="reason"
                  title="7 Good Reasons To Travel To Jungle Safari"
                >
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Laboriosam, dolorum. Cupiditate voluptatem voluptatum
                  explicabo voluptatibus ad sint ipsa aperiam excepturi sequi
                  consequuntur officiis illo itaque, eligendi quasi,
                  necessitatibus cumque adipisci ex culpa non. Repellendus autem
                  sapiente nihil soluta similique dignissimos quasi, quam sunt
                  omnis blanditiis sed quibusdam. Saepe, quas magnam? Nemo
                  aliquid repellendus deleniti veritatis?
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </div>
  );
};

export default OurTeam;
