import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getblogdetails } from "../redux/actions/blogaction";
import { BsArrowRightShort } from "react-icons/bs";

const BlogArticle = (Carddata) => {
  const data = Carddata?.details;
  // console.log("data recieved anmol", data);
  const navigate = useNavigate();
  const handleClick = (_id) => {

    navigate(`/blog-details/${data._id}`);
    console.log("ihih;''''==========", _id);
  };
  return (
    <article className="post w-100  inner-img ">
      {/* <figure
        className="featured-post m-0 "
        style={{
          backgroundImage: `${data?.image}`,
        }}
      /> */}
     <div className="img">
     <img
        src={data?.image}
        alt=""
        className="img-fluid w-100 h-100 inner-img"
        style={{ objectFit: "cover" }}
      />
     </div>
      <div className="post-content ">
      <h3>
      <span className="" style={{fontSize:"22px"}}>{data?.title}</span>
        </h3>
        <div className="cat-meta  text-success">
        <span className="text-success">{(data?.traveltheme).slice(0,20)}..</span>
        </div>
        <p className=" text-truncate2">{data?.short_description}</p>
        <div className="post-footer d-flex justify-content-end align-items-center">
          <div className="post-btn">
            <button className="round-btn" onClick={handleClick}>
              Weiterlesen <BsArrowRightShort size={15 }/> 
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

export default BlogArticle;
