import React, {useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import CitySection from "../components/CitySection";
import { BASE_URL } from "../service/url";
import axios from "axios";
import { BsArrowRightShort } from "react-icons/bs";

import Footer from "./Footer";
import Header from "./Header";

const ViewCity = () => {
  // const navigate = useNavigate();
  // const handleCityDetailClick = (id) => {
  //   navigate(`/city-details/${id}`);
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [citydata, setCitydata] = useState();

  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data testing", response)
      const { result } = response.data;
      console.log(response.data, " City data testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  useEffect(() => {
   
    fetchCity();
  }, []);

  const [noOfElement, setNoOfElement] = useState(8);
  const cityslice = citydata?.slice(0, noOfElement);

  const handleloadMore = () => {
    setNoOfElement(noOfElement + noOfElement);
    
  };
  return (
    <>
      <Header />
      <div className="inner-banner-top bg-about mb-4 ">
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Mehr Stadt</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="destination-city-section container">
        <div>
          <p className="section-title text-success text-center mb-5">
            Indien Reiseziele
          </p>
        </div>
        {/* <CitySection citydata={citydata}/> */}
        <div className="row mx-sm-3 mx-0">
        
        {cityslice?.map((citydata,index) => ( 
          <div className="col-md-3 col-6 my-2"> 
                 <CitySection citydata={citydata} />
  
          </div>
              ))}
                </div>
               <div className="custom-btn col-12 px-2 text-end pt-3">
          <Link to="/view-city">
            {" "}
            <button
              type="submit"
              className="btn border-success text-white bg-success"
              onClick={handleloadMore}
            >
              Mehr Anzeigen <BsArrowRightShort size={20 }/>
            </button>
          </Link>
          </div>
      </section>
      <Footer />
    </>
  );
};

export default ViewCity;
