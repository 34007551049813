import { loadblogsReducer } from "./blogReducer";
import { loaddestinationReducer } from "./destinationReducer";
import { loadtourpackage } from "./tourpackageReducer";

import { combineReducers } from "redux";
import { loadreviewsReducer } from "./reviewReducer";
import { loadreviewdetails } from "./detailedreviewReducer";
import blogReducer from "./newBlogReducer";
import { cityReducer } from "./cityReducer";
import { filterDataReducer } from "./filterDataReducer";

const reducers = combineReducers({
allBlogs: loadblogsReducer,
  alldestinations: loaddestinationReducer,
  alltours: loadtourpackage,
  allreviews: loadreviewsReducer,
  reviewdetails: loadreviewdetails,
  // allqueries: loadqueryReducer,
  bloghome:blogReducer,
  citydata:cityReducer,
  filterData:filterDataReducer,
});

export default reducers;
