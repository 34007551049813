import React, {useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import DestinationSlider from "../components/DestinationSlider";
import CitySection from "../components/CitySection";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
// import  {getcitydetails  from "../redux/actions/cityaction";
import { useParams } from "react-router-dom";
import { getCityDetails } from "../redux/actions/cityaction";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import parse from "html-react-parser";



const CityDetails = () => {
  const [citydata, setCitydata] = useState();
  const [noOfCity, setNoOfCity] = useState(8);
  const cityslice = citydata?.slice(0, noOfCity);
  const fetchCity = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get-city`);
      console.log("city data2 testing", response)
      const { result } = response.data;
      console.log(response.data, " City data2 testing laksdjflkajdsflk");
      setCitydata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  //  const [citydetails, setCitydetails] = useState();
  // const citydetailsdata = useSelector((state) => state?.citydata?.citydetails);
  // console.log("citydetailsdata------------", citydetailsdata);
  // let { id } = useParams();
  // console.log("id is------------", id);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getCityDetails(id));
  //   window.scrollTo(0, 0);
  // }, []);
  const citydetailsdata = useSelector((state) => state.citydata?.citydetails);
  console.log("citydetailsdata------------", citydetailsdata);
 // console.log("the id is",id)
  let { id } = useParams();
  console.log("id is", id);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCityDetails(id));
    fetchCity();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="section">
      <div className="container">
          <div className="row">
            
            <div className="col-lg-12">
              <div className="row">
                <div className="col-12">
                  <img
                    src= {citydetailsdata?.banner}
                    class="img-fluid rounded-top tour-banner w-100"
                    alt=""
                  />
        
                </div>
              </div>
            </div>

            <div className="section">  
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="banner-title px-0">
                      <u className="list-notincluded">
                        {citydetailsdata?.city_name &&
                          parse(citydetailsdata?.city_name)}
                      </u>
                    </div>
                    <div className="city-deatils ">
                      {/* <p>
                        On your beach vacation, a warm fuzziness envelops you as
                        soon as you catch sight of tranquil blue waters lapping
                        silken sands. Your taste buds tingle with sublime
                        flavors of coconut and local seafood. Watch a brilliant
                        orange sunset as you sip a drink with your toes
                        burrowing into the sand. Enchanting Travels experts
                        understand that a beach is not just a beach. Some like
                        it hot, and some like it cold. You may prefer sandy
                        shores touched by cool Atlantic waters around Cape Town,
                        South Africa or warm tropical waters off the Mozambique
                        coast. Take your pick from a secluded private stretch of
                        beach, or one with a pier and boardwalk, and a
                        restaurant and party scene. Luxurious accommodation with
                        modern amenities forms the base of a beach holiday. We
                        will find you a fabulous five-star hotel, retro
                        cliff-top cabin, or private villa with floor-to ceiling
                        windows. Hold still though, because we also know that a
                        beach vacation is much more than hours spent on a
                        sun-lounger sipping mai tais. Let the wonderful
                        creatures of the sea become an integral part of your
                        beach vacation. Hop on a whale and dolphin watching tour
                        on the Sri Lankan coast. Or get up close and personal as
                        you swim with dolphins in Mauritius or with turtles and
                        colorful tropical fish in the coral-filled waters of
                        Indonesia or the Maldives. Water sports that suit every
                        level of challenge and fitness are yours for the asking.
                      </p> */}
                      <p>{citydetailsdata?.city_details}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection  citydata={citydata} />

        </div>
            ))} 
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      

      <Footer />
    </>
  );
};

export default CityDetails;
