import React, { useEffect, useState } from "react";
import TravelCard from "./TravelCard";
const DestinationTourCard = ({ tourData, title }) => {
  
  const [endIndex, setEndIndex] = useState(3);

  console.log("the tourdata recieved", tourData);

  const tourdata = tourData;

  const [noOfElement, setNoOfElement] = useState(3);
  const cityslice = tourData?.slice(0, noOfElement);

  // const handleloadMore = () => {
  //   setNoOfElement(noOfElement + noOfElement);
  // const tourdataslice = tourdata?.slice(0, endIndex);
  const handleReadMoreClick = () => {
    setEndIndex(endIndex + 3);
  };

  return (
    <section className="pt-2">
      <div className="container-fluid">
        <div className="row p-auto">
          <div className="col-sm-12">
            <h4 className="sub-heading text-center">{title}</h4>
          </div>
        </div>

        <div className="row recommended-tours wildlife-tours text-center">
          <div className="col-sm-12">
            <div className="row">
              {tourData?.length > 0 &&
              tourData?.map((item, index) => (
                <div className="col-sm-6 col-md-4 py-3 px-2">
                  <TravelCard
                    Destination={item?.packageName}
                    Days={item?.totaldays}
                    PerDay={item?.budgetperday}
                    Details={item?.description}
                    LinkTo={`TourPaket/${item?._id}`}
                    image={item?.image}
                    className="w-100"
                  />
                </div>
              ))}
            </div>
            <div className="col-sm-12">
              <button className="btn btn-success"
               onClick={handleReadMoreClick}
               >
                WEITERLESEN
              </button>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DestinationTourCard;
