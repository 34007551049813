import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import ReactStars from "react-rating-stars-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getdetailedreview } from "../redux/actions/reviewaction";
import Moment from "react-moment";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import Slider from "react-slick";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


// const Review = () => {
//     const [ratingvalue, setRatingvalue] = useState(0);
//     const ratingChanged = (newRating) => {
//       console.log(newRating);
//       setRatingvalue(newRating);
//     };
// }

const   ReviewCard = ({ id, image, author, date, rating, comment }) => {
  console.log("here review card".image)
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const review = useSelector((state) => state?.reviewdetails?.detailed_review);
  console.log("review data where i post it --------------", review)
  // const   review = useSelector((state) => state?.reviewdetails?.detailed_review);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDetailedReviewShow = () => {
    dispatch(getdetailedreview(id));
    handleShow();
  };

const cardImage = review[0]?.image

console.log("the image for the card is: ", cardImage)

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="slick-arrow">
        {/* <button
          type="button"
          onClick={onClick}
          role="presentation"
          class="btn rounded-pill border px-3 slider-navigation-button"
        > */}
        <BsArrowRight
          onClick={onClick}
          role="presentation"
          className="rightnavigationarrow fa-2x  text-success"
          style={{ cursor: "pointer" }}
        />
        {/* <span aria-label="Next">›</span> */}
        {/* </button> */}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div class="slick-arrow">
        {/* <button
          type="button"
          onClick={onClick}
          role="presentation"
          class="btn rounded-pill border px-3 slider-navigation-button"
        >
          <span aria-label="Previous">‹</span>
        </button> */}
        <BsArrowLeft
          onClick={onClick}
          role="presentation"
          className="leftnavigationarrow fa-2x text-success"
          style={{ cursor: "pointer" }}
        />
      </div>
    );
  }

  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      
    ],
  };

  return (
    <>

 <div className="testimonial-block-one mx-3  w-100">
  <div className="inner-box" style={{height:"296px"}}>
  <div className="d-flex justify-content-around ">
  <ReactStars
              count={5}
              size={26}
              activeColor="#ffd700"
              id="rating"
              value={rating}
              edit={false}
              //   onChange={ratingChanged}
            />
            <span className="card-title align-self-center">
                  On:{<Moment date={date} format="YYYY/MM/DD" />}
                </span>

  </div>
    <div className="text mt-2">
      <div className="icon" style={{backgroundImage: 'url(assets/images/icons/quote-1.png)'}} />
      <p className="text-truncate2"> 
              {comment}
             </p>
    </div>
   
    <div className="author-box">
      <h4>{author}</h4>
      
      {/* <figure className="thumb-box"><img src="	http://azim.commonsupport.com/Travio/assets/images/resource/testimonial-1.png" alt /></figure> */}
      <div>
  
  </div>
      <figure className="thumb-box">
        <img src={image}  class="img-fluid card-profile" alt=""/>
        {/* <img src= alt /> */}
        </figure>
    </div>

    {/* <SimpleReactLightbox>
      <ReactImageVideoLightbox/>
    </SimpleReactLightbox> */}
    <div className="row justify-content-end">
            <div className="col-auto card-button">
              <Card.Link
                className=" text-end cursor-pointer "
                onClick={() => handleDetailedReviewShow()}
              >
                Weiterlesen
              </Card.Link>
            </div>
          </div>
  </div>
</div>
 



      {/* <Card className="review-card mx-3">
        <Card.Body>
          <Card.Title>
            <div className="row pt-3">
              <div className="col-md-2 col-lg-4">
                <img
                  src={image}
                  class="img-fluid rounded-circle review-image"
                 
                  alt=""
                />
              </div>

              <div className="col">
                <h6>{author}</h6>
                <p className="card-title">
                  On:{<Moment date={date} format="YYYY/MM/DD" />}
                </p>
              </div>
            </div>
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            <ReactStars
              count={5}
              size={35}
              activeColor="#ffd700"
              id="rating"
              value={rating}
              edit={false}
              //   onChange={ratingChanged}
            />
          </Card.Subtitle>
          <Card.Text>
            <p class="review-body">
              <i class="fa fa-quote-left" aria-hidden="true"></i>
              {comment?.slice(0, 40)}
              <i class="fa fa-quote-right" aria-hidden="true"></i>
            </p>
          </Card.Text>
          <div className="row justify-content-end">
            <div className="col-auto">
              <Card.Link
                className="text-success text-end cursor-pointer"
                onClick={() => handleDetailedReviewShow()}
              >
                Weiterlesen
              </Card.Link>
            </div>
          </div>
        </Card.Body>
      </Card> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="">
              <Card.Title>
                <div className="row ">
                  <div className="col-md-2 col-lg-4">
                    <img
                      // src="https://res.cloudinary.com/enchanting/f_auto,q_70,w_150,h_150,c_fill/et-web/2017/06/Enchanting-Travels-English-Guest-Africa-South-Africa-Alan-Strozza-Lori-Besen-africa-travel.jpg"
                      src={image}
                      class="img-fluid rounded-circle"
                      width="100%"
                      alt=""
                    />
                  </div>

                  <div className="col">
                    <h3 className="card-title modal-title">
                      {review?.[0]?.name}
                    </h3>
                    <p className="card-title">
                      On:
                      {
                        <Moment
                          date={review?.[0]?.createdAt}
                          format="YYYY/MM/DD"
                        />
                      }
                    </p>
                    <ReactStars
              count={5}
              size={26}
              activeColor="#ffd700"
              id="rating"
              value={rating}
              edit={false}
              //   onChange={ratingChanged}
            />
                  </div>
                </div>
              </Card.Title>
            </div>
          </div>
          <div className="row py-3 px-3">
            <p class="review-body">
              <i class="fa fa-quote-left text-success" aria-hidden="true"></i>
              {review?.[0]?.review}
              <i class="fa fa-quote-right text-success" aria-hidden="true"></i>
            </p>
            <div className="row">
              <div className="col-md-12">
                {/* <div className="row">
                  <div className="col-md-4">
                    <img
                      src="/assets/images/delhi-places/img-2.png"
                      class="img-fluid rounded-top"
                      alt=""
                    />
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/assets/images/delhi-places/img-2.png"
                      class="img-fluid rounded-top"
                      alt=""
                    />
                  </div>
                  <div className="col-md-4">
                    <img
                      src="/assets/images/delhi-places/img-2.png"
                      class="img-fluid rounded-top"
                      alt=""
                    />
                  </div>
                </div> */}
                <div>
                {/* {review?.image && review?.image.map((data,index) =>{
              return (
                 
                    // <img
                    //   src={data}
                    //   />
                    <p>hellows</p>
              
)})} */}
       
        
          <div>
          <button type="button" onClick={() => setOpen(true)}>
        Open Lightbox
      </button>
          {console.log("the value we are gettig here is inside the review")}
             {cardImage?.map((link,index) =>{
              {console.log("the value we are gettig here is",)}
              return (

                 
                    //  <img
                    //    src={link}
                    //   />
                    <Lightbox
                    open={open}
                    close={() => setOpen(false)}
                    slides={[
                      // { src: {image{0}} },
                     
                    ]}
                  />
                   
              
)
{/* <div>
<Lightbox images={
  [
    {
      src:{link},
      title: 'image title',
      description: 'image description'
    }
  ]
}/>
</div> */}

})}
                 
          </div>

          {/* <div>
            <TopDestinationCard
              Heading="Kombireisen"
              image="assets/images/destination-img/Rectangle4191.png"
              Details=" Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry'Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry'"
              LinkTo="tour-package"
            />
          </div> */}
          
          {/* <div>
            <TopDestinationCard
              Heading="Wine & Food"
              image="assets/images/destination-img/Rectangle4188.png"
              Details=" Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry'Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry'"
              LinkTo="tour-package"
            />
          </div> */}
          <div>
          <img
                      src="/assets/images/delhi-places/img-2.png"
                      class="img-fluid rounded-top"
                      alt=""
                    />
          </div>
          <div>
          <img
                      src="/assets/images/delhi-places/img-2.png"
                      class="img-fluid rounded-top"
                      alt=""
                    />
          </div>
        
      </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReviewCard;
