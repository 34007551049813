import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import TopDestinationCard from "./TopDestinationCard";

import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

const TopDestinationSlider = () => {
  const [tourModal, setTourModal] = useState(false);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="slick-arrow">
        {/* <button
          type="button"
          onClick={onClick}
          role="presentation"
          class="btn rounded-pill border px-3 slider-navigation-button"
        > */}
        <BsArrowRight
          onClick={onClick}
          role="presentation"
          className="rightnavigationarrow fa-2x  text-success"
          style={{ cursor: "pointer" }}
        />
        {/* <span aria-label="Next">›</span> */}
        {/* </button> */}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div class="slick-arrow">
        {/* <button
          type="button"
          onClick={onClick}
          role="presentation"
          class="btn rounded-pill border px-3 slider-navigation-button"
        >
          <span aria-label="Previous">‹</span>
        </button> */}
        <BsArrowLeft
          onClick={onClick}
          role="presentation"
          className="leftnavigationarrow fa-2x text-success"
          style={{ cursor: "pointer" }}
        />
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      
    ],
  };

  return (
    <div className="container">
      <div>
        <Slider {...settings}>
          <div>
            <TopDestinationCard
              Heading="Land und Leute Indien"
              image="/assets/images/travel-theme/Land Und Loute_8.jpg"
              Details="Kulturtourismus und die Entdeckung von Vergangenheit und Gegenwart
              Jeder Ort hat seine eigene Geschichte, und eine Kultur- und Kulturerbe-Tour hilft, sie zu erzählen. Entdecken Sie die Vergangenheit anhand der Überreste mittelalterlicher Städte, alter Tempel und archäologischer Stätten."
              LinkTo="heritage"
            />
          </div>

          <div>
            <TopDestinationCard
              Heading="Aktivurlaub"
              image="/assets/images/travel-theme/aktivelab_4.jpg"
              Details=" Planen Sie eine Reise voller Abenteuer oder fügen Sie ein abenteuerliches Element in Ihren Reiseplan ein. Wir am India Unlimited wissen, dass es viele verschiedene Arten von Abenteuern gibt und dass die Gäste unterschiedliche Fähigkeiten und Bedürfnisse haben. Für manche ist eine Fahrt mit dem Heißluftballon über Amer Palast oder In Pushkar  in Rajasthan genau das Richtige. Andere suchen vielleicht eine adrenalingeladene, kilometerlange Seilrutsche durch die Rishikesh in Himalaya . Wieder andere wollen tagelang auf den Gipfel des Kanchanjunga  in Nordostindien oder Mount Everest ."
              LinkTo="adventure"
            />
          </div>

          {/* <div>
            <TopDestinationCard
              Heading="Kombireisen"
              image="assets/images/destination-img/Rectangle4191.png"
              Details=" Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry'Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry'"
              LinkTo="tour-package"
            />
          </div> */}
          <div>
            <TopDestinationCard
              Heading="Tierreisen und Safaris"
              image="/assets/images/travel-theme/tiger_6.jpg"
              Details="Wenn Sie jedoch einen Tiger sehen möchten, müssen Sie mit den besten Fährtenlesern der Region in die Dschungel Zentralindiens reisen. Eine ganze Reihe von Dschungelbuchfiguren, von der Großkatze bis hin zu Faultieren und Pythons, leben in diesen uralten Wäldern.
              Exklusive Unterkünfte sind das Herzstück jedes unvergesslichen Safari-Erlebnisses, das wir planen. Die luxuriösen Zeltcamps, Boutique-Lodges oder Aufenthalte, die wir für Sie buchen, bieten ein hohes Maß an Komfort und genau die Art von Luxus, die Sie sich wünschen und verdient haben. In Indien empfehlen wir gerne private Konzessionen, in denen es oft möglich ist, Wildtierherden zu beobachten, ohne Ihre Unterkunft zu verlassen. "
              LinkTo="wildlife"
              traveltheme="wildlife"
            />
          </div>
          <div>
            <TopDestinationCard
              Heading="Ayurveda"
              image="/assets/images/travel-theme/Ayurveda_1.jpg "
              Details="Kombinieren Sie Yoga und Ayurveda, ein System der Naturheilkunde und Massage, bei einem Wellnessurlaub in Indien. Die malerischen Landschaften, die heilende ayurvedische Körperarbeit und die außergewöhnliche Gastfreundschaft Südasiens sorgen für einen Wellnessurlaub der besonderen Art."
              LinkTo="ayurveda"
              className="w-100"
            />
          </div>
          {/* <div>
            <TopDestinationCard
              Heading="Wine & Food"
              image="assets/images/destination-img/Rectangle4188.png"
              Details=" Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry'Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry'"
              LinkTo="tour-package"
            />
          </div> */}
          <div>
            <TopDestinationCard
              Heading=" Strandurlaub"
              image="/assets/images/travel-theme/5.jpg"
              Details="Der Geruch des Meeres. Sand zwischen den
              Füßen. Nur wenige Dinge sind so erholsam wie ein Strandurlaub
              . Ob,  schwimmen mit Delfinen in
              Goa  oder in einer verwöhnenden
              Wasser-Villa auf den Malediven, wir haben einen
              Strandurlaub für jede Stimmung."
              LinkTo="beaches"
            />
          </div>
          <div>
            <TopDestinationCard
              Heading="Luxus Reisen"
              image="/assets/images/travel-theme/luxus risen_3.jpg"
              Details="Luxusurlaub bedeutet für jeden etwas anderes. Ganz gleich, ob Sie von Indische Safari-Camps mit erstklassigen Spas, versteckten Inseln an Privatstränden oder malerischen Lodges in unberührten Landschaften träumen, unsere India Unlimited Experten werden Ihre Reise so zusammenstellen, dass sie Ihren Wünschen entspricht."
              LinkTo="relaxation"
            />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default TopDestinationSlider;
