
  import axios from "axios";
import { BASE_URL } from "../../service/url";
import { ActionTypes } from "../constants/actiontypes";


// export const CityDetailsActionbyId = (id) => async (dispatch) => {
//     const response = await axios.get(`${BASE_URL}/city_management/get_by_id/${id}`);
//     console.log(
//       "city details id , that we want================================",
//       response
//     );
  
//     const city = response?.data?.data;
//     console.log("on data", city);
//     dispatch({
//       type: ActionTypes.SET_CITY_DETAILS,
//       payload: jobs,
//     });
//   };
  

   



// import axios from "axios";
// import { BASE_URL } from "../../service/url";
// import { ActionTypes } from "../constants/actiontypes";

export const getCityDetails = (_id) => async (dispatch) => {
    console.log("dispatch succeded or not at line 6 in city action", _id)
    try {
      const response = await axios.get(`${BASE_URL}/city_management/get_by_id/${_id}`);
      console.log("city data in city action at line no. 9",response);
      let info = response?.data?.result[0]
      console.log("finding nemo",info)
      console.log("finding nemo",response?.data?.result[0])
       
  
      dispatch({
        type: ActionTypes.SET_CITY_DETAILS,
        payload: info,
      });
    } catch (error) {
      console.log("err===", error);
    }

  };

// export const getCityDetails = (id) => async (dispatch) => {
//     try {
//       console.log("CITY call-----", id);
//       const response = await axios.get(`${BASE_URL}/city_management/get_by_id/${id}`);
  
//       const { data, success } = response.data;
//       console.log("response.data.result----->>", response.data.result);
  
//       if (success) {
//         dispatch({
//           type: ActionTypes.SET_TOUR_PACKAGE,
          // payload: data,
    //       payload: response.data.result,
    //     });
    //   }
  
      // dispatch({
      //   type: ActionTypes.SET_TOUR_PACKAGE,
      //   payload: data,
      // });
//     } catch (error) {
//       console.log("err===", error);
//     }
//   };
  