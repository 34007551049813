import { React, useState, useEffect } from "react";
import CitySection from "../components/CitySection";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

const HeritageandCulture = () => {
  const [tourdata, setTourdata] = useState();
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };
  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      traveltheme: "Land Und Leute Indien",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package-theme`, obj);
      console.log("theme data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
   // --------city data APi

   const [citydata, setCitydata] = useState();
   const [noOfCity, setNoOfCity] = useState(8);
   const cityslice = citydata?.slice(0, noOfCity);
   const fetchCity = async () => {
     try {
       const response = await axios.get(`${BASE_URL}/city_management/get-city`);
       console.log("city data2 testing", response)
       const { result } = response.data;
       console.log(response.data, " City data2 testing laksdjflkajdsflk");
       setCitydata(result);
     } catch (error) {
       console.log("err===", error);
     }
   };

  useEffect(() => {
    // fetchTour();
    fetchFilterTour();
    fetchCity();
  }, []);

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top bg-about mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Land und Leute Indien.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Land Und Leute Indien</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <p>
                    Sie müssen nicht weit reisen, um die wunder des reisens zu
                    genießen es gibt keinen besseren zeitpunkt, um eine seite
                    Indiens zu entdecken mit dem du nicht gerechnet hast. Wir
                    sind uns aus dem weg gegangen um eine begrenzte serie von
                    lokalen gruppenreisen in der richtigen größe zu erstellen
                    das wird sie mit einheimischen verbinden sie würden sich
                    normalerweise nicht treffen und entdecken sie orte, die sie
                    normalerweise nicht erleben würden.
                  </p>
                  <p>
                    Das Sprichwort Unglaubliches Indien ist einfach kein Spruch
                    aber eine tatsache, die man lernen wird während sie jeden
                    winkel dieses unglaublichen landes erkunden.
                  </p>
                  <p>
                    Indem sie mit uns die Indianer und Indien erkunden aie
                    werden wirklich stolz darauf sein, ein Indianer zu sein
                    während Sie den Reichtum Ihres Zuhauses entdecken und
                    gleichzeitig lokale gemeinschaften und die wirtschaft
                    unterstützen.
                  </p>
                  <p>
                    Wir haben hart gearbeitet um sich auf die Zukunft des
                    Reisens vorzubereiten, mit hygieneverständnis und
                    distanziertes Reisen muss Ihnen Seelenfrieden geben, ohne
                    dass Sie etwas tun müssen.
                  </p>
                  <p>
                    Wir von  India Unlimited wissen es dass Sie auf Reisen ein
                    unaufhaltsames Erlebnis wünschen. Wir halten uns daran,
                    dasselbe zu machen.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE AUSFLÜGE FÜR ERBE UND KULTUR"
      />

<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
     
      <Footer />
    </div>
  );
};

export default HeritageandCulture;
