import { React, useEffect, useState } from "react";
import CitySection from "../components/CitySection";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";

import Footer from "./Footer";
import Header from "./Header";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

const Ayurveda = () => {
  const [tourdata, setTourdata] = useState();
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };
  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      traveltheme: "Ayurveda Und Yoga Reisen",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package-theme`, obj);
      console.log("theme data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
    // --------city data APi

    const [citydata, setCitydata] = useState();
    const [noOfCity, setNoOfCity] = useState(8);
    const cityslice = citydata?.slice(0, noOfCity);
    const fetchCity = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/city_management/get-city`);
        console.log("city data2 testing", response)
        const { result } = response.data;
        console.log(response.data, " City data2 testing laksdjflkajdsflk");
        setCitydata(result);
      } catch (error) {
        console.log("err===", error);
      }
    };

  useEffect(() => {
    fetchFilterTour();
    fetchCity();
  }, []);

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Ayurveda.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Ayurveda Und Yoga Reisen</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <p>
                    Kombinieren sie Yoga und Ayurveda, ein System der
                    natürlichen Heilung und massieren, im wellnessurlaub in
                    Indien oder Sri Lanka.
                  </p>
                  <p>
                    Malerische einstellungen, heilende ayurvedische körperarbeit
                    und die außergewöhnliche gastfreundschaft Südasiens sorgen
                    für einen wellnessurlaub der besonderen art. Ayurveda ist
                    verwurzelt in 5.000 Jahre alten indischen Kulturtraditionen.
                    Seine massagen und medizinischen behandlungen sind dafür
                    bekannt, stress abzubauen und helfen, ein gesundes zu
                    erreichen und lebendiger seinszustand.
                  </p>
                  <p>
                    Indien ist auch die spirituelle Heimat des Yoga, und eine
                    reihe von luxusresorts urlaub anbieten dazu gehören
                    Ayurveda-behandlungen und Yoga.  India Unlimited können sie einen
                    ganzheitlichen heilurlaub buchen und kombiniere es mit ein
                    bisschen sightseeing, um authentischen lokalen geschmack
                    hinzuzufügen.
                  </p>
                  <p>
                    Verschiedene Ayurveda-Resorts bieten ein einzigartiges
                    leistungsspektrum. Unsere experten wissen, was sie sind und
                    kann Ihnen bei der auswahl helfen das Ihren bedürfnissen
                    entspricht.
                  </p>
                  <p>
                    Im bundesstaat Kerala, Ayurveda-resorts befinden sich an
                    atemberaubenden orten. Auf den trägen backwaters, resorts
                    wie punnamada, Carnoustie, und Coconut Lagoon vereinen jede
                    Menge Charakter mit herausragenden leistungen.
                  </p>
                  <p>
                    Die wunderschönen strände des staates sind eine augenweide.
                    Profitieren Sie von ihrer perfekten Lage an der Küste sind
                    resorts wie Nattika und Marari Beach Resort. Für eine andere
                    Stimmung, Spice Village in den grünen Hügeln der südlichen
                    Western Ghats in der Nähe von Thekkady bietet Ayurveda in
                    einer umgebung von aromatischen gewürz- und kräutergärten.
                    Alternative, es gibt Ananda im Himalaya, das ist toll für
                    massagen, spa- behandlungen und Yoga,und lernen wie man zu
                    einem harmonischen und ausgeglichenen leben kommt. Einige
                    der resorts sind groß, freundlich, und ausschließlich über
                    heilmittel und vorbeugende Ayurveda-behandlungen. Einige
                    unter deutscher Leitung bieten urlaube an, die darauf
                    abzielen, körper, geist und seele in einklang zu bringen.
                  </p>
                  <p>
                    Ihr persönliches Wellnessziel kann darauf ausgerichtet sein
                    zur Komplettüberholung, oder eine ruhige entgiftung und
                    entspannung. Durch die Weisheit, die der alten Praxis des
                    Ayurveda innewohnt sie werden ein mächtiges werkzeug finden
                    um Ihren Urlaub zu einem Frieden zu machen, heilung und
                    transformation.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE REISE FÜR AYURVEDA UND YOGA REISEN"
      />

<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>
      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default Ayurveda;
