import React from 'react'

const IndiaUnlimited = () => {
  return (
    <>
       <section className="india-journeys pt-0">
          <div className="container-fluid px-md-5">
            <div className="row">
              <div className="col-md-12 text-center common-heading pb-5">
                {/* <p className="title mb-0">Something About</p> */}
                <h2 className="text-success"> India Unlimited</h2>
              </div>
            </div>
            <div className="row align-items-center">
              <div
                style={{
                  backgroundImage:
                    "url(/assets/images/Photos India Unlimited/path14.jpg)",
                }}
                className="col-sm-12 col-lg-6"
              >
                <img
                  className="img-fluid w-100"
                  src="/assets/images/Photos India Unlimited/India Unlimited home Page.jpg"
                  // src="/assets/images/Photos India Unlimited/Pushakr.jpg"
                  // src="assets/images/jurneys-img-1.png"
                  alt="jurneys-img-1"
                />
              </div>
              <div className="col-sm-12 col-lg-6">
                <div className="px-md-3 my-3">
                  <h3 className=" bold text-success review-body">
                    Das India Unlimited
                  </h3>
                  <p className="text">
                    Vielfalt und Gleichheit, unabhängig von ethnischer
                    Zugehörigkeit, Religion und Hintergrund, sind das Herzstück
                    unseres Wesens, unserer Denkweise und unseres Handelns.
                    India Unlimited Travels wurde 2012 mit einem Ziel gegründet
                    - transformative und authentische Reisen in Indien neu zu
                    definieren, und zwar durch unser Fachwissen über Reiseziele,
                    unsere Präsenz und Unterstützung vor Ort und ein
                    leidenschaftliches Team, das sich wirklich kümmert.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 order-lg-2">
                <img
                  className="img-fluid w-100"
                  src="/assets/images/Photos India Unlimited/Pushakr.jpg"
                  // src="/assets/images/Photos India Unlimited/India Unlimited home Page.jpg"
                  // src="assets/images/jurneys-img-1.png"
                  alt="jurneys-img-1"
                />
              </div>
              <div className="col-sm-12 col-lg-6 my-3">
                <div className="px-md-3 text-md-right order-lg-1">
                  <h3 className="title text-start text-success review-body">
                    UNSER TEAM
                  </h3>
                  <p className="text text-start">
                    Das Rezept für eine traumhafte Reise sind die Leute, die Sie
                    bei der Planung und Durchführung Ihrer Reise erleben. Unser
                    Reisewissen ist unübertrefflich. Bei uns gibt es keine
                    Zwischenhändler wie bei den meisten Reisebüros. Wir haben
                    unsere eigenen Menschen im Land, die Sie unterstützen.
                    Einige von uns sind in unseren Reisezielen zu Hause, während
                    andere ausgewandert sind. Genau wie unsere Reiseziele ist
                    auch unser Team eine Mischung aus verschiedenen Kulturen mit
                    ganz individuellen Ansichten. Gemeinsam haben wir einen
                    unterschiedlichen beruflichen Werdegang, von Juristen, Pilot
                    , Lehrern und Bankern bis hin zu Eventplanern und
                    Hotelmanagern, Krankenschwestern und sogar Tanzlehrern, doch
                    was uns eint, ist unsere wahre Leidenschaft für das Reisen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default IndiaUnlimited
