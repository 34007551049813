import { React, useState } from "react";
import { Link } from "react-router-dom";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";

const IslandsandBeaches = () => {
  const [displayForm, setDisplayForm] = useState(true);
  const handleClick = () => {
    setDisplayForm(!displayForm);
  };

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top  mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Tierreisen und Safari.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Tierreisen Und Safari</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5">
                <div className="beaches-article d-flex flex-column justify-content-center">
                  <p>
                    Von eindringlich schönen schneebedeckten eskapaden zu
                    üppigen wäldern und hügeln mit sanftem grün,von den endlosen
                    goldenen stränden bis zum saphir von den endlosen goldenen
                    stränden bis zu den saphir- und smaragdseen eingebettet in
                    die landschaft, die natürliche schönheit des indischen
                    subkontinents ist unvergleichlich.
                  </p>
                  <p>
                    Die unberührten und unerforschten Gebiete des Landes lassen
                    einen in ehrfurcht vor ihrer atemberaubenden pracht zurück.
                    Darüber hinaus, die unterschiede und kontraste einer region
                    von einer anderen unterstreichen den Reiz dieser
                    Landschaften- sei es die eiskalte Wüste von Leh dieser
                    aufruf an den Seelensucher in dir oder die
                    sonnendurchfluteten mystischen wüstendünen von Rajasthan die
                    Sie in die zeit der royals zurückversetzen.
                  </p>
                  <p>
                    Ausgehend vom schneebedeckten Himalaya im Norden,
                    durchquerung der wüsten von Rajasthan, durchqueren sie die
                    üppigen Wälder von Madhya Pradesh und dann in den
                    weitläufigen backwaters von Kerala segeln, und schließlich
                    in die unberührten gewässer der Andamanen- und
                    Nikobareninseln eintauchen, die reise ist voller
                    abenteuerlicher hochländer und ruhiger ebenen und
                    tiefländer.
                  </p>
                  <p>
                    Ein land mit einer vielfältigen flora und Fauna,Indien ist
                    auch die heimat vieler nationalparks, wildschutzgebiete,
                    tigerreservate und biosphärenreservate, die bezaubernde
                    tierbeobachtungen bieten.
                  </p>
                  <p>
                    Von bergstationen und seen bis hin zu stränden, hier sind
                    alle natürlichen rückzugsorte, die sie in Ihre reiseroute
                    aufnehmen können während sie von einem ende des landes zum
                    anderen reisen. Genießen Sie die Tierwelt mit den
                    außergewöhnlichen Safari-Services von Hansa India.{" "}
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row p-auto">
            <div className="col-sm-12">
              <h4>POPULAR TRIPS TO NORTH INDIA</h4>
            </div>
          </div>

          <div className="row recommended-tours wildlife-tours text-center">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-6 col-md-4 py-3 px-2">
                  <TravelCard
                    Destination="4 Days Bandhavgarh Tiger Safari"
                    Days="10"
                    Perperson="10,000"
                    Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                    LinkTo="tour-package"
                    image="assets/images/recommended-rours/recommended-img1.jpg"
                    className="w-100"
                  />
                </div>
                <div className="col-sm-6 col-md-4 py-3">
                  <TravelCard
                    Destination="4 Days Bandhavgarh Tiger Safari"
                    Days="10"
                    Perperson="10,000"
                    Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                    LinkTo="tour-package"
                    image="assets/images/recommended-rours/recommended-img3.jpg"
                    className="w-100"
                  />
                </div>
                <div className="col-sm-6 col-md-4 py-3">
                  <TravelCard
                    Destination="4 Days Bandhavgarh Tiger Safari"
                    Days="10"
                    Perperson="10,000"
                    Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                    LinkTo="tour-package"
                    image="assets/images/recommended-rours/recommended-img1.jpg"
                    className="w-100"
                  />
                </div>
              </div>

              <hr />
              <section
                class="travellers-review bg-pattern-2"
                style={{
                  background: "url(/assets/images/travel-agency-bg.jpg)",
                }}
              >
                <div class="container-fluid px-md-5">
                  <div class="row">
                    <div class="col-md-12 text-center common-heading pb-5">
                      <p class="title mb-2">Referenzen</p>
                      <h2>Was unsere Gäste über uns sagen</h2>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <ReviewCardSlider />
                  </div>
                </div>
              </section>
              <section className="top-destinations">
                <div className="container-fluid px-md-5">
                  <div className="row">
                    <div className="col-md-12 text-center common-heading pb-5">
                      <p className="title mb-0">
                        Popular city in Nordindien Reisen
                      </p>
                      {/* <h2>Top heritage &amp; culture destinations</h2> */}
                    </div>
                  </div>
                  <div className="row mx-sm-3 mx-0">
                    <div className="col-md-3 col-6 my-2">
                      <div className="card text-white">
                        <img
                          className="card-img"
                          src="assets/images/top-destinations/img-1.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="row align-items-end h-100">
                            <Link to="/city-details"></Link>
                            <div className="col-12 d-flex justify-content-between">
                              <h5 className="card-title">Jaipur</h5>
                              <span>
                                <i className="fas fa-chevron-right" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6 my-2">
                      <div className="card text-white">
                        <img
                          className="card-img img-fluid w-100"
                          src="assets/images/top-destinations/img-2.jpg"
                          alt="Card image"
                        />
                        <div
                          LinkTo="/city-details"
                          className="card-img-overlay"
                        >
                          <div className="row align-items-end h-100">
                            <Link to="/city-details">
                              <div className="col-12 d-flex justify-content-between">
                                <h5 className="card-title">Varanasi</h5>

                                <span>
                                  <i className="fas fa-chevron-right" />
                                </span>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6 my-2">
                      <div className="card text-white">
                        <img
                          className="card-img img-fluid w-100"
                          src="assets/images/top-destinations/img-3.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="row align-items-end h-100">
                            <Link to="/city-details">
                              <div className="col-12 d-flex justify-content-between">
                                <h5 className="card-title">Agra</h5>
                                <a href="#">
                                  <span>
                                    <i className="fas fa-chevron-right" />
                                  </span>
                                </a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6 my-2">
                      <div className="card text-white">
                        <img
                          className="card-img img-fluid w-100"
                          src="assets/images/top-destinations/img-4.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="row align-items-end h-100">
                            <Link to="/city-details">
                              <div className="col-12 d-flex justify-content-between">
                                <h5 className="card-title">Ahmedabad</h5>
                                <a href="#">
                                  <span>
                                    <i className="fas fa-chevron-right" />
                                  </span>
                                </a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6 my-2">
                      <div className="card text-white">
                        <img
                          className="card-img img-fluid w-100"
                          src="assets/images/top-destinations/img-5.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="row align-items-end h-100">
                            <Link to="/city-details">
                              <div className="col-12 d-flex justify-content-between">
                                <h5 className="card-title">kurukshetra</h5>
                                <a href="#">
                                  <span>
                                    <i className="fas fa-chevron-right" />
                                  </span>
                                </a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6 my-2">
                      <div className="card text-white">
                        <img
                          className="card-img img-fluid w-100"
                          src="assets/images/top-destinations/img-7.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="row align-items-end h-100">
                            <Link to="/city-details">
                              <div className="col-12 d-flex justify-content-between">
                                <h5 className="card-title">Shimla</h5>
                                <a href="#">
                                  <span>
                                    <i className="fas fa-chevron-right" />
                                  </span>
                                </a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6 my-2">
                      <div className="card text-white">
                        <img
                          className="card-img img-fluid w-100"
                          src="assets/images/top-destinations/img-6.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="row align-items-end h-100">
                            <Link to="/city-details">
                              <div className="col-12 d-flex justify-content-between">
                                <h5 className="card-title">South India</h5>
                                <a href="#">
                                  <span>
                                    <i className="fas fa-chevron-right" />
                                  </span>
                                </a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6 my-2">
                      <div className="card text-white">
                        <img
                          className="card-img img-fluid w-100"
                          src="assets/images/top-destinations/img-7.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="row align-items-end h-100">
                            <Link to="/city-details">
                              <div className="col-12 d-flex justify-content-between">
                                <h5 className="card-title">Kolkata</h5>
                                <a href="#">
                                  <span>
                                    <i className="fas fa-chevron-right" />
                                  </span>
                                </a>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default IslandsandBeaches;
