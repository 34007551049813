import { React, useEffect, useState } from "react";
import QueryCard from "../components/QueryCard";
import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";
import axios from "axios";
import { BASE_URL } from "../service/url";

const WineandFood = () => {
  const [displayForm, setDisplayForm] = useState(true);
  const [relatedTheme, setRelatedTheme] = useState();
  const handleClick = () => {
    setDisplayForm(!displayForm);
  };
  const fetchRelatedTourTheme = async () => {
    const obj = {
      traveltheme: "Wine & Food",
    };
    const response = await axios.post(
      `${BASE_URL}/tour/load-related-tour-package-theme`,
      obj
    );
    if (response.success === true) {
      setRelatedTheme(response?.data);
    }
  };
  useEffect(() => {
    fetchRelatedTourTheme();
  }, []);

  return (
    <div>
      <Header />

      <div className="inner-banner-top bg-about mb-4">
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Wine & Food</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row px-5">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8">
                <div className="beaches-article d-flex flex-column justify-content-center">
                  <p>
                    On your beach vacation, a warm fuzziness envelops you as
                    soon as you catch sight of tranquil blue waters lapping
                    silken sands. Your taste buds tingle with sublime flavors of
                    coconut and local seafood. Watch a brilliant orange sunset
                    as you sip a drink with your toes burrowing into the sand.
                  </p>
                  <p>
                    Enchanting Travels experts understand that a beach is not
                    just a beach. Some like it hot, and some like it cold. You
                    may prefer sandy shores touched by cool Atlantic waters
                    around Cape Town, South Africa or warm tropical waters off
                    the Mozambique coast. Take your pick from a secluded private
                    stretch of beach, or one with a pier and boardwalk, and a
                    restaurant and party scene.
                  </p>
                  <p>
                    Luxurious accommodation with modern amenities forms the base
                    of a beach holiday. We will find you a fabulous five-star
                    hotel, retro cliff-top cabin, or private villa with floor-to
                    ceiling windows. Hold still though, because we also know
                    that a beach vacation is much more than hours spent on a
                    sun-lounger sipping mai tais.
                  </p>
                  <p>
                    Let the wonderful creatures of the sea become an integral
                    part of your beach vacation. Hop on a whale and dolphin
                    watching tour on the Sri Lankan coast. Or get up close and
                    personal as you swim with dolphins in Mauritius or with
                    turtles and colorful tropical fish in the coral-filled
                    waters of Indonesia or the Maldives. Water sports that suit
                    every level of challenge and fitness are yours for the
                    asking.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row p-auto">
            <div className="col-sm-12">
              <h4>POPULAR TRIPS TO NORTH INDIA</h4>
            </div>
          </div>

          <div className="row recommended-tours wildlife-tours text-center">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-6 col-md-4 py-3 px-2">
                  <TravelCard
                    Destination="4 Days Bandhavgarh Tiger Safari"
                    Days="10"
                    Perperson="10,000"
                    Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                    LinkTo="tour-package"
                    image="assets/images/recommended-rours/recommended-img1.png"
                    className="w-100"
                  />
                </div>
                <div className="col-sm-6 col-md-4 py-3">
                  <TravelCard
                    Destination="4 Days Bandhavgarh Tiger Safari"
                    Days="10"
                    Perperson="10,000"
                    Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                    LinkTo="tour-package"
                    image="assets/images/recommended-rours/recommended-img3.png"
                    className="w-100"
                  />
                </div>
                <div className="col-sm-6 col-md-4 py-3">
                  <TravelCard
                    Destination="4 Days Bandhavgarh Tiger Safari"
                    Days="10"
                    Perperson="10,000"
                    Details="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry"
                    LinkTo="tour-package"
                    image="assets/images/recommended-rours/recommended-img1.png"
                    className="w-100"
                  />
                </div>
              </div>

              <hr />
              <section
                class="travellers-review bg-pattern-2"
                style={{
                  background: "url(/assets/images/travel-agency-bg.png)",
                }}
              >
                <div class="container-fluid px-md-5">
                  <div class="row">
                    <div class="col-md-12 text-center common-heading pb-5">
                      <p class="title mb-0">Testimonials</p>
                      <h2>What our guests have to say about us</h2>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="row justify-content-center">
                      <div class="col-lg-4 col-sm-6 col-12 my-3">
                        <div class="box shadow-sm">
                          <div className="text-center">
                            <div className="d-inline-block mx-auto">
                              <img
                                src="/assets/images/team/img-1.png"
                                className="rounded-circle size-50px w-25"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="review-text text-center ">
                            <p>
                              Lorem ipsum, or lipsum as it is sometimes known,
                              is dummy text used in laying out print, graphic or
                              web designs.{" "}
                            </p>
                            <div class="pt-2">
                              <h6 class="mb-0 user-name">Josef</h6>
                              <ul class="list-unstyled d-flex reting-star justify-content-center">
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="fas fa-star text-green"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12 my-3">
                        <div class="box shadow-sm">
                          <div className="text-center">
                            <div className="d-inline-block mx-auto">
                              <img
                                src="/assets/images/team/img-1.png"
                                className="rounded-circle size-50px w-25"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="review-text text-center ">
                            <p>
                              Lorem ipsum, or lipsum as it is sometimes known,
                              is dummy text used in laying out print, graphic or
                              web designs.{" "}
                            </p>
                            <div class="pt-2">
                              <h6 class="mb-0 user-name">Josef</h6>
                              <ul class="list-unstyled d-flex reting-star justify-content-center">
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="fas fa-star text-green"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-sm-6 col-12 my-3">
                        <div class="box shadow-sm">
                          <div className="text-center">
                            <div className="d-inline-block mx-auto">
                              <img
                                src="/assets/images/team/img-1.png"
                                className="rounded-circle size-50px w-25"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="review-text text-center ">
                            <p>
                              Lorem ipsum, or lipsum as it is sometimes known,
                              is dummy text used in laying out print, graphic or
                              web designs.{" "}
                            </p>
                            <div class="pt-2">
                              <h6 class="mb-0 user-name">Josef</h6>
                              <ul class="list-unstyled d-flex reting-star justify-content-center">
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="far fa-star"></i>
                                </li>
                                <li>
                                  <i class="fas fa-star text-green"></i>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default WineandFood;
