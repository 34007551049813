import { React, useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";

import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";

const PrivateTour = () => {
  const [tourdata, setTourdata] = useState();
  const fetchTour = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
      const { result } = response.data;

      setTourdata(result);
    } catch (error) {
      console.log("err===", error);
    }
  };

  useEffect(() => {
    fetchTour();
  }, []);
  return (
    <div>
      <Header />
      <div
        className="inner-banner-top bg-about mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Individuelle und margeschneiderte.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1> Individuelle Und Maßgeschneiderte Reisen</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-5">
        <div className="row mb-4">
          <div className="col-12 text-center text-lg-left">
            <h1 className="mt-5 text-success">	Individuelle Rundreise in Indien mit India unlimited</h1>
            <p className="font-weight-bolder my-3">
              Personalisierte planung und reisehinweise
            </p>
            <p className="my-3" style={{ textAlign: "justify", }}>

              Ihr reiseplan ist auf sie zugeschnitten mit liebe zum detail und individuelle betreuung. Ob es sich um einige der berühmtesten sehenswürdigkeiten der welt handelt, oder seltene, versteckte edelsteine, kulturelle einblicke, naturwunder oder abenteuer – sie entscheiden, was sie sehen und machen sie auf Ihrer privaten tour.

            </p>
            <p className="my-3" style={{ textAlign: "justify", }}>
              Sagen sie uns einfach, wie sie sich Ihren traumurlaub vorstellen – wir treten in aktion um ein komplett individuelles zu erstellen, unverbindliche reiseroute, die perfekt zu Ihrem reisetraum passt.
            </p>
            <p className="my-3" style={{ textAlign: "justify", }}>
              Buchen sie Ihre reise bei uns erst, wenn sie zu 100 % zufrieden sind– schließlich ist es die reise Ihres lebens!
            </p>
            <p className="my-3" style={{ textAlign: "justify", }}>
              Vielleicht haben sie nur eine Idee und wenig zeit zum planen, oder sie möchten vielleicht selbst eintauchen vollständig im planungsprozess – was auch immer sie wünschen, wir setzen es gerne um und planen mit Ihnen Ihre tour.
            </p>
            <p className="my-3" style={{ textAlign: "justify", }}>
              Persönliche Reiseberatung ist unsere größte Priorität: wir sind immer gespannt, davon zu hören und Ihren individuellen interessen gerecht werden, auch wenn es keine erfahrung ist, die wir normalerweise anbieten. Wir glauben, dass dies der schlüssel ist, um Ihnen zu helfen erfüllen Ihre sonderwünsche –So wie wir es bei vielen unserer gäste getan haben!
            </p>
            {/* <div>
              <img
                src="https://res.cloudinary.com/enchanting/f_auto,q_70,w_900,h_490,c_fill/et-web/2018/07/Enchanting-Travels-India-Guests-Janet-Sheehan.jpg"
                alt=""
                className="img-fluid w-100"
              />
              <p className="small">
                Janet Sheehan has traveled with us to India.
              </p>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12  text-lg-left">
            <h2 className="text-success mb-4">
              Was bedeutet Privatreise?
            </h2>
            <p className="my-3" style={{ textAlign: "justify", }}>
              Privater urlaub nach maß – im gegensatz zu pauschalreisen – bieten jedem reisenden viel mehr freiheit und flexibilität.
            </p>
            <p style={{ textAlign: "justify", }}>

              Eine “Privattour“ könnte bedeuten:

            </p>
            <ul className="my-4">
              <li>
                <p className="mb-0" style={{ textAlign: "justify", }}>
                  Eine selbst organisierte tour: Eine komplett von Ihnen geplante und organisierte reise, wo sie alle Ihre unterkünfte buchen, transport und aktivitäten individuell.
                </p>
              </li>

            </ul>
            <p style={{ textAlign: "justify", }}>

              Eine private Katalogtour: Diese werden privat durchgeführt aber folgen sie einer bestimmten reiseroute und kann nicht angepasst werden.

            </p>
            <ul className="my-4">
              <li>
                <p className="mb-0" style={{ textAlign: "justify", }}>

                  Touren mit einzelkomponenten: Eine tour, die sie aus verschiedenen Komponenten zusammenstellen können Ihnen zur verfügung gestellt.

                </p>
              </li>
              <li>
                <p>

                  Private Touren mit Hansa India bieten sich Ihnen an all die freiheit und flexibilität, die mit der planung Ihrer eigenen reise einhergehen.

                </p>
              </li>

            </ul>



            {/* <div className="row recommended-tours wildlife-tours text-center">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-6 col-md-4 py-3 px-2">
                    <div className="card text-white">
                      <div className="position-relative">
                        <img
                          className="card-img img-fluid"
                          src="assets/images/recommended-rours/recommended-img1.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="card-info">
                            <h5 className="card-title font-weight-light mb-1">
                              4 Days Bandhavgarh Tiger Safari
                            </h5>
                            <div className="d-flex card-text">
                              <span>10 Days:</span>
                              <span className="px-2">
                                <i className="fas fa-rupee-sign" />{" "}
                                10,000/Person
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="service-icon d-flex">
                          <span className="pl-0">
                            <img
                              src="assets/images/vector-img/hotel.jpg"
                              alt="vector-img1"
                            />
                          </span>
                          <span>
                            <img
                              src="assets/images/vector-img/restaurant.jpg"
                              alt="vector-img1"
                            />
                          </span>
                          <span>
                            <img
                              src="assets/images/vector-img/car.jpg"
                              alt="vector-img1"
                            />
                          </span>
                          <span>
                            <img
                              src="assets/images/vector-img/plane.jpg"
                              alt="vector-img1"
                            />
                          </span>
                        </div>
                        <p className="body-text pt-2 py-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry'
                        </p>
                        <hr />
                        <div className="row btn-link-custom">
                          <div className="col-6 text-center border-right-custom">
                            <a href="#" className="link-text text-uppercase">
                              Details
                            </a>
                          </div>
                          <div className="col-6 text-center">
                            <a
                              href="#"
                              className="link-text link-text__green text-uppercase"
                            >
                              INQUIRE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 py-3">
                    <div className="card text-white">
                      <div className="position-relative">
                        <img
                          className="card-img img-fluid"
                          src="assets/images/recommended-rours/recommended-img3.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="card-info">
                            <h5 className="card-title font-weight-light mb-1">
                              4 Days Bandhavgarh Tiger Safari
                            </h5>
                            <div className="d-flex card-text">
                              <span>10 Days:</span>
                              <span className="px-2">
                                <i className="fas fa-rupee-sign" />{" "}
                                10,000/Person
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="service-icon d-flex">
                          <span className="pl-0">
                            <img
                              src="assets/images/vector-img/hotel.jpg"
                              alt="vector-img1"
                            />
                          </span>
                          <span>
                            <img
                              src="assets/images/vector-img/restaurant.jpg"
                              alt="vector-img1"
                            />
                          </span>
                          <span>
                            <img
                              src="assets/images/vector-img/car.jpg"
                              alt="vector-img1"
                            />
                          </span>
                          <span>
                            <img
                              src="assets/images/vector-img/plane.jpg"
                              alt="vector-img1"
                            />
                          </span>
                        </div>
                        <p className="body-text pt-2 py-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry'
                        </p>
                        <hr />
                        <div className="row btn-link-custom">
                          <div className="col-6 text-center border-right-custom">
                            <a href="#" className="link-text text-uppercase">
                              Details
                            </a>
                          </div>
                          <div className="col-6 text-center">
                            <a
                              href="#"
                              className="link-text link-text__green text-uppercase"
                            >
                              INQUIRE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 py-3">
                    <div className="card text-white">
                      <div className="position-relative">
                        <img
                          className="card-img img-fluid"
                          src="assets/images/recommended-rours/recommended-img1.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay">
                          <div className="card-info">
                            <h5 className="card-title font-weight-light mb-1">
                              4 Days Bandhavgarh Tiger Safari
                            </h5>
                            <div className="d-flex card-text">
                              <span>10 Days:</span>
                              <span className="px-2">
                                <i className="fas fa-rupee-sign" />{" "}
                                10,000/Person
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="service-icon d-flex">
                          <span className="pl-0">
                            <img
                              src="assets/images/vector-img/hotel.jpg"
                              alt="vector-img1"
                            />
                          </span>
                          <span>
                            <img
                              src="assets/images/vector-img/restaurant.jpg"
                              alt="vector-img1"
                            />
                          </span>
                          <span>
                            <img
                              src="assets/images/vector-img/car.jpg"
                              alt="vector-img1"
                            />
                          </span>
                          <span>
                            <img
                              src="assets/images/vector-img/plane.jpg"
                              alt="vector-img1"
                            />
                          </span>
                        </div>
                        <p className="body-text pt-2 py-3">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry'
                        </p>
                        <hr />
                        <div className="row btn-link-custom">
                          <div className="col-6 text-center border-right-custom">
                            <a href="#" className="link-text text-uppercase">
                              Details
                            </a>
                          </div>
                          <div className="col-6 text-center">
                            <a
                              href="#"
                              className="link-text link-text__green text-uppercase"
                            >
                              INQUIRE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section
                  class="travellers-review bg-pattern-2"
                  style={{
                    background: "url(/assets/images/travel-agency-bg.jpg)",
                  }}
                >
                  <div class="container-fluid px-md-5">
                    <div class="row">
                      <div class="col-md-12 text-center common-heading pb-5">
                        <p class="title mb-0">Testimonials</p>
                        <h2>What our guests have to say about us</h2>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <ReviewCardSlider />
                    </div>
                  </div>
                </section>
              </div>
            </div> */}

            <section className="recommended-tours bg-pattern-1 mb-0 pb-0">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center common-heading pb-2">
                    <p className="title mb-3"> Indien Erleben</p>
                    <h2>Das vielfältige Land der Farben und Mystik erleben</h2>
                  </div>
                </div>
              </div>
            </section>
            <DestinationTourCard tourData={tourdata} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivateTour;
