import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { postquerydata } from "../redux/actions/queryaction";
import PhoneInput from "react-phone-number-input";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { IoMdMail } from "react-icons/io";

const validationSchema = Yup.object().shape({
  phoneNo: Yup.number(),
  firstname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  lastname: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  email: Yup.string().email("Invalid Email").required("Required*"),
  country: Yup.string(),
});

const Contact = () => {
  const [phone, setPhone] = useState("");
  const [value, setValue] = useState();
  const [queryalert, setQueryalert] = useState(false);
  const dispatch = useDispatch();
  const queryForm = {
    firstname: "",
    lastname: "",
    email: "",
    phoneNo: "",
    country: "",
    tripduration: "",
    stage: "",
    description: "",
  };
  const userSubmit = (formdata) => {
    const queryForm = {
      firstname: formdata?.firstname ? formdata?.firstname : "",
      lastname: formdata?.lastname ? formdata?.lastname : "",
      email: formdata?.email ? formdata?.email : "",
      phoneNo: phone,
      country: formdata?.country ? formdata?.country : "",
      tripduration: formdata?.tripduration ? formdata?.tripduration : "",
      stage: formdata?.stage ? formdata?.stage : "",
      description: formdata?.description ? formdata?.description : "",
    };
    console.log("queryform is", queryForm);
    // axios({
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   url: "http://localhost:8000/query/addquery",
    //   data: JSON.stringify(queryForm),
    // })
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((error) => console.log(error));
    dispatch(postquerydata(queryForm, setQueryalert));
  };
  const successAdditionOfCategoryAlert = (title, icon) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        window.location.reload();
      }
    });
  };
  const onlyTextAllowed = (e) => {
    const re = /^[a-zA-Z]+$/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (queryalert) {
      successAdditionOfCategoryAlert("Submitted", "success");
    }
  }, [queryalert]);

  return (
    <>
      <Header />
      <div>
        <div className="inner-banner-top contact-us mb-4">
          <div className="container">
            <div className="row">
              <div className="banner-overlay d-flex align-items-end py-4 px-5">
                <div className="banner-text">
                  <h1>Kontakt Uns</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="bg-pattern-1 contact-info-sec">
          <div className="container-fluid px-md-5">
            <div className="row">
              <div className="col-md-12 text-center common-heading pb-5">
                <h2>Schick Uns Eine Nachricht</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-auto ">
                <div className="px-md-4 d-flex h-100 contact-box">
                  <div className="">
                    <h5>Kontaktinformationen</h5>
                    <div className="d-flex py-2">
                      <div>
                        <i className="fas fa-map-marker-alt" />
                      </div>
                      <div className="pl-4">
                        <p>Indien address : 1482 D Wazir nagar, Delhi 110049</p>

                        {/* <a
                          href="#"
                          target="_blank"
                          className="pt-2 font-weight-bold"
                        >
                          Get Directions
                        </a> */}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div>
                        {/* <i className="fas fa-map-marker-alt" /> */}
                      </div>
                      <div className="pl-3">
                        <p>
                          {/* Deutschland(Germany) Address :- Eichstr. 57, 50733
                          Köln, Deutschland */}
                        </p>
                        {/* <a
                          href="#"
                          target="_blank"
                          className="pt-2 font-weight-bold"
                        >
                          Get Directions
                        </a> */}
                      </div>
                    </div>
                    <div className="d-flex py-2">
                      <div>
                        <i
                          class="fa fa-phone"
                          style={{ transform: "rotate(86deg)" }}
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="pl-3">
                        <p><a href="tel:+91 9711077576">+91 9711077576</a></p>
                        
                        {/* <span className="font-weight-bold">
                          Rufen Sie uns an
                        </span> */}
                      </div>
                    </div>
                    <div className="d-flex py-2">
                      <div>
                        <IoMdMail
                         style={{ color:" #4baf5d", }}
                         />
                        {/* <i
                          class="fa fa-phone"
                          style={{ transform: "rotate(86deg)" }}
                          aria-hidden="true"
                        ></i> */} 
                      </div>
                      <div className="pl-3">
                        <p><a href="mailto:ravindra@indiaunlimited.de">ravindra@indiaunlimited.de</a></p>
                        <span className="font-weight-bold">
                          Rufen Sie uns an
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="px-md-4">
                  <Formik
                    initialValues={queryForm}
                    onSubmit={userSubmit}
                    validationSchema={validationSchema}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                    }) => (
                      <form
                        method="post"
                        name="inquiryFormLarge"
                        onSubmit={handleSubmit}
                      >
                        <div className="form-row">
                          <div className="col-md-6 form-group">
                            <input
                              type="text"
                              id="firstname"
                              name="firstname"
                              value={values.firstname}
                              onChange={handleChange}
                              className="form-control custom-height shadow-sm"
                              placeholder="Vorname"
                              maxLength={30}
                              onKeyPress={(e) => onlyTextAllowed(e)}
                            />
                            <div id="name_error" />
                            <span
                              style={{
                                color: "tomato",
                                "font-size": "small",
                              }}
                            >
                              {errors.firstname && touched.firstname && (
                                <div>{errors.firstname}</div>
                              )}
                            </span>
                          </div>
                          <div className="col-md-6 form-group">
                            <input
                              type="text"
                              id="lastname"
                              name="lastname"
                              value={values.lastname}
                              onChange={handleChange}
                              className="form-control custom-height shadow-sm"
                              placeholder="Nachname"
                              maxLength={30}
                              onKeyPress={(e) => onlyTextAllowed(e)}
                            />
                            <span
                              style={{
                                color: "tomato",
                                "font-size": "small",
                              }}
                            >
                              {errors.lastname && touched.lastname && (
                                <div>{errors.lastname}</div>
                              )}
                            </span>
                          </div>
                          <div className="col-md-6 form-group">
                            <input
                              type="email"
                              id=" email"
                              value={values.email}
                              onChange={handleChange}
                              name="email"
                              className="form-control custom-height shadow-sm"
                              placeholder="Email*"
                            />
                            <div id="email_error" />
                            <span
                              style={{
                                color: "tomato",
                                "font-size": "small",
                              }}
                            >
                              {errors.email && touched.email && (
                                <div>{errors.email}</div>
                              )}
                            </span>
                          </div>
                          <div className="col-md-6 form-group">
                            <PhoneInput
                              placeholder="Telefon-Nr"
                              id="phoneNo"
                              name="phoneNo"
                              // value={values.phoneNo}
                              // onChange={handleChange}
                              value={phone}
                              onChange={setPhone}
                              className=" form-control d-flex  custom-height shadow-sm"
                              required={true}
                              maxLength="17"
                            />

                            <div id="phone_number_error" />
                            <span
                              style={{
                                color: "tomato",
                                "font-size": "small",
                              }}
                            >
                              {errors.phoneNo && touched.phoneNo && (
                                <div>{errors.phoneNo}</div>
                              )}
                            </span>
                          </div>

                          <div className="col-md-6 form-group">
                            <select
                              className="form-select custom-height shadow-sm"
                              id="country"
                              name="country"
                              value={values.country}
                              onChange={handleChange}
                            >
                              <option>Land Auswählen</option>
                              <option value="india">India</option>
                             
                            </select>
                            <span
                              style={{
                                color: "tomato",
                                "font-size": "small",
                              }}
                            >
                              {errors.country && touched.country && (
                                <div>{errors.country}</div>
                              )}
                            </span>
                          </div>
                          <div className="col-md-6 form-group">
                            <input
                              type="text"
                              className="form-control custom-height shadow-sm"
                              placeholder="Reisedauer in Tagen"
                              id="tripduration"
                              name="tripduration"
                              value={values.tripduration}
                              onChange={handleChange}
                            />
                            <div id="name_error" />
                          </div>
                          <div className="col-md-12 form-group">
                            <select
                              className="form-select custom-height shadow-sm"
                              id="stage"
                              value={values.stage}
                              onChange={handleChange}
                            >
                              <option>Welche Phase Sie planen</option>
                              <option value="Still dreaming/ Researching">
                                Ich träume immer noch/forsche
                              </option>
                              <option value="Definitely travelling not sure which part of India">
                                Ich bin mir auf jeden Fall nicht sicher, in
                                welchen Teil Indiens
                              </option>
                              <option value="I want to Book my trip">
                                Ich möchte meine Reise buchen
                              </option>
                            </select>
                          </div>
                          <div className="form-group col-12">
                            <textarea
                              className="form-control shadow-sm"
                              id="description"
                              value={values.description}
                              onChange={handleChange}
                              placeholder="Beschreibung"
                              rows={5}
                              defaultValue={""}
                            />
                            <div id="message_error" />
                          </div>
                          <div className="col-md-6 form-group">
                            <input
                              type="hidden"
                              name="inquiry_status"
                              defaultValue="send_query"
                              className="form-control custom-height shadow-sm"
                            />
                          </div>
                        </div>
                        <div className="custom-btn col-12 px-2">
                          <button
                            type="submit"
                            className="btn btn-success w-100 py-2"
                          >
                            Abschicken &nbsp;
                            <i className="fas fa-paper-plane" />{" "}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-map">
          <div className="container-fluid px-md-5">
            <div className="row">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28032.245586597586!2d77.22569608826224!3d28.56884087633264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3ad801d293d%3A0x7f730966747ee8fc!2sLajpat%20Nagar%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1619769541966!5m2!1sen!2sin"
                width="100%"
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
              />
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
