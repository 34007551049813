import axios from "axios";
import { BASE_URL } from "../../service/url";
import { ActionTypes } from "../constants/actiontypes";

export const postnewsletter = (formdata,setSubmitAlert) => async (dispatch) => {
  try {
    console.log("formata send from dispatch", formdata);
    const response = await axios.post(`${BASE_URL}/newsletter/createnewsletter`, formdata);
    const data = response.data;
    console.log("ooooooooooooooooooooopppppppppppppppppsssssss", response.data);
    if (response.data.status === true)
     {
        setSubmitAlert(true);
    }
     else {
        setSubmitAlert(false);
    }

    // dispatch({
    //   type: ActionTypes.POST_QUERY_FORMDATA,
    //   payload: formdata,
    // });
  } catch (error) {
    console.log("err===", error);
  }
};
