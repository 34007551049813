import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import axios from "axios";
import { Formik } from "formik";
import ReactStars from "react-rating-stars-component";
// import { BsHandThumbsUp ,BsHandThumbsDown } from 'react-icons/bs';
import ReviewCardSlider from "../components/ReviewCardSlider";
import { useDispatch } from "react-redux";
import { postreviewdata } from "../redux/actions/reviewaction";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { RMIUploader } from "react-multiple-image-uploader";
//import { compileString } from "sass";
// import "./App.css";
import { useDropzone } from "react-dropzone";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(30, "Too Long!")
    .required("Required*"),
  email: Yup.string().email("Invalid Email").required("Required*"),

  review: Yup.string().required("Required*"),
});

// const onSelect = (data) => {
//   console.log("the selected file is check it to use",data)
// }

const Review = () => {
  const [ratingvalue, setRatingvalue] = useState(0);
  const [reviewalert, setReviewalert] = useState(false);
  const [date, setDate] = useState("");
  console.log("------------------data============", date);
  const [image, setImage] = useState("");
  console.log("--=--=-=-=--image=-=-=-=-=-=-=-=", image);
  const dispatch = useDispatch();
  const ratingChanged = (newRating) => {
    console.log(newRating);
    setRatingvalue(newRating);
  };
   const [images,setImages] = useState([]);
  const {   acceptedFiles, getRootProps, getInputProps } = useDropzone();
  console.log(acceptedFiles, "acceptedFiles");
  const userForm = {
    name: "",
    email: "",
    phone: "",
    // rating: 0,
    image: "",  
    travelDate: "",
    review: "",
  };

  const userSubmit = (values) => {

    // const image = acceptedFiles.map((file,index) => {
    //   return (file.path)
    // }
    // )
    // for(var i=0; i<acceptedFiles.length; i++) {
    //    setImages(...images, acceptedFiles[i].path)
    // }

   
    console.log("image rererererer ",);
    const formdata = new FormData();
    formdata.append("name", values.name);
    formdata.append("email", values.email);
    formdata.append("phone", values.phone);
    // formdata.append("image", image);
    formdata.append("rating", ratingvalue);
    formdata.append("review", values.review);
    // formdata.append("image",acceptedFiles);
    acceptedFiles.forEach((e,i) => {
      
        formdata.append("file1",e,e.name)
    })
    // const userForm = {
    //   name: formdata?.name ? formdata?.name : "",
    //   email: formdata?.email ? formdata?.email : "",
    //   phone: formdata?.phone ? formdata?.phone : "",
    //   file1: image,
    //   travelDate: date,
    //   rating: ratingvalue,
    //   review: formdata?.review ? formdata?.review : "",
    // };
    console.log(userForm);
    dispatch(postreviewdata(formdata, setReviewalert));
  };
  const successAdditionOfCategoryAlert = (title, icon) => {
    Swal.fire({
      title: title,
      text: "",
      icon: icon,
      showCancelButton: false,
      confirmButtonText: "Continue",
      confirmButtonColor: "#0CDA98",
      dangerMode: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        window.location.reload();
      }
    });
  };
  useEffect(() => {
    if (reviewalert) {
      successAdditionOfCategoryAlert("Submitted", "success");
    }
  }, [reviewalert]);

  const [visible, setVisible] = useState(false);
  const handleSetVisible = () => {
    setVisible(true);
  };
  const hideModal = () => {
    setVisible(false);
  };
  const onUpload = (data) => {
    console.log("Upload files    fasjsjsjdasjasjdasjdla", data);
  };
  const onSelect = (data) => {
    console.log("the selected file is check it to use", data);
  };
  const onRemove = (id) => {
    console.log("Remove image id", id);
  };
  const handleChangeValue = (e) => {
    console.log("Handle change value", e.target.value);
  };
  const handleUploader = (e) => {
    console.log("the data to  display is", e);
  };

  const handleImageData = (e) => {

    const {files} = e.target

    console.log("the image value for checking is", files)
  }
  return (
    <>
      <Header />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center"></div>
          </div>
        </div>
        <div
          className="inner-banner-top "
          style={{
            backgroundImage: `url("/assets/images/banner/Reisebewertungen.jpg")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="banner-overlay d-flex px-5 py-4 align-items-end">
                <div className="banner-text">
                  <h1>Reisebewertungen</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="bg-pattern-1">
          <div className="container-fluid px-md-5">
            <div className="row">
              <div className="col-md-12 text-center common-heading pb-5">
                <p className="title mb-3">Eine Rezension Schreiben</p>
                <h2>
                  Eine gute, sympathische Bewertung ist immer eine wunderbare
                  Überraschung
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7 mx-auto">
                <Formik
                  initialValues={userForm}
                  onSubmit={(values) => {
                    console.log(
                      "form values from review js page'''====",
                      values
                    );
                    userSubmit(values);
                  }}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    errors,
                    setFieldValue,
                    touched,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <p className="text-green">
                        Schreiben Sie eine Bewertung, indem Sie uns eine
                        Nachricht senden, indem Sie das untenstehende Formular
                        ausfüllen.
                      </p>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="
                          Ihren Namen"
                          id="name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.name && touched.name && (
                            <div>{errors.name}</div>
                          )}
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="E-mail"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.email && touched.email && (
                            <div>{errors.email}</div>
                          )}
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          aria-describedby="emailHelp"
                          placeholder="Telefonnummer(Optional)"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.phone && touched.phone && (
                            <div>{errors.phone}</div>
                          )}
                        </span>
                      </div>
                      <div className="form-group">
                        <lable className="mb-3">Reisedatum</lable>
                        <input
                          type="date"
                          className="form-control mt-2"
                          id="date"
                          name="date"
                          aria-describedby="emailHelp"
                          value={values.date}
                          onChange={(e) => setDate(e.target.value)}
                          onBlur={handleBlur}
                          placeholder="travel On"
                        />
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.travelDate && touched.travelDate && (
                            <div>{errors.travelDate}</div>
                          )}
                        </span>
                      </div>

                      <div className="form-group">
                        <lable className="mb-3">Tourbild hochladen</lable>

                        <div className="col-auto mt-2 ml-3">
                          {/* <button onClick={handleSetVisible}>Show Me</button>
      <div className="multiuploaader">
      <RMIUploader  
        isOpen = {visible}
        onSelect = {onSelect}
        onUpload = {onUpload}
        onChange = {(e) => handleUploader(e)}
      />
      </div> */}

                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                             {" "}
                            <button
                              className="p-2 rounde"
                              style={{
                                width: "auto",
                                fontSize: "16px",
                                cursor: "pointer",
                                borderRadius: "4px",
                                textTransform: "uppercase",
                                letterSpacing: "2px",
                                color: "rgba(255, 255, 255, 0.9)",
                                backgroundColor: "#209f84",
                                fontWeight: "bold",
                              }}
                            >
                              image upload{" "}
                            </button>
                         {" "}
                          </div>

               

                        </div>
                        {/* <input
                          type="file"
                          className="form-control mt-2"
                          id="date"
                          name="image"
                          aria-describedby="emailHelp"
                          value={values.image}
                          onChange={(e) => setImage(e.target.value)}
                          // onChange={(event) => {
                          //   setFieldValue(
                          //     "image",
                          //     event.currentTarget.files
                          //   )
                          // }}
                          onBlur={handleBlur}
                          placeholder="travel On"
                        /> */}

                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.travelDate && touched.travelDate && (
                            <div>{errors.travelDate}</div>
                          )}
                        </span>
                      </div>
                      {/* <div className="form-group">
                        <lable className="mb-3">Upload Image</lable>
                        <input
                          type="file"
                          className="form-control"
                          id="image"
                          aria-describedby="emailHelp"
                          placeholder="upload Image"
                        />
                      </div> */}

                      <div className="form-group w-100 justify-content-center d-flex">
                        <ReactStars
                          count={5}
                          size={65}
                          activeColor="#ffd700"
                          id="rating"
                          name="rating"
                          // value={values.rating}
                          onChange={ratingChanged}
                          required={true}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          id="review"
                          name="review"
                          rows={4}
                          placeholder="Rezension"
                          defaultValue={""}
                          value={values.review}
                          onChange={handleChange}
                        />
                        <span
                          style={{
                            color: "tomato",
                            "font-size": "small",
                          }}
                        >
                          {errors.review && touched.review && (
                            <div>{errors.review}</div>
                          )}
                        </span>
                      </div>
                      <div className="col-12 d-flex form-group w-100 px-0">
                        <button
                          className="btn btn-success py-2 px-4"
                          type="submit"
                        >
                          Einreichen
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              <div />
              <section className="">
                <div className="container">
                  <div className="col-md-12 text-center common-heading pb-5">
                    <p className="title mb-0">
                      Lesen Sie, was einige unserer Kunden sagen
                    </p>
                  </div>
                  <ReviewCardSlider />
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default Review;
