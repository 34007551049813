export const ActionTypes = {
  SET_BLOG_DATA: "SET_BLOG_DATA",
  SET_BLOG_DETAILS: "SET_BLOG_DETAILS",
  SET_FEATURED_BLOG_DATA: " SET_FEATURED_BLOG_DATA",
  SET_RELATED_TRAVEL_THEMES_DATA: "SET_RELATED_TRAVEL_THEMES_DATA",
  POST_QUERY_FORMDATA: "POST_QUERY_FORMDATA",
  POST_REVIEW_FORMDATA: "POST_REVIEW_FORMDATA",

  SET_REVIEW_DATA: "SET_REVIEW_DATA",
  SET_REVIEW_DETAILS: "SET_REVIEW_DETAILS",
  // destination
  SET_DESTINATION_DATA: "SET_DESTINATION_DATA",
  SET_TOUR_PACKAGE: "SET_TOUR_PACKAGE",
  SET_NEWSLETTER: "SET_NEWSLETTER",
  SET_CITY_DETAILS: "SET_CITY_DETAILS",

  SET_TOUR_PACKAGE:"SET_TOUR_PACKAGE"
};
