import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const TermsConditions = () => {
  return (
    <div>
      <Header />
      <div className="inner-banner-top bg-about mb-4">
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1> Geschaftsbedingungen</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pt-0">
        <div className="container">
          <div className="row">
            <div className=" col">
              <h3>Geschaftsbedingungen</h3>
            </div>
          </div>
          <div className="row">
            <div className="col mt-4">
              <h5 className="mb-3 text-success">Bitte Aufmerksam Lesen</h5>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>
                Der Kauf von Reisedienstleistungen, die von india  Unlimited,
                Inc. (das "Unternehmen", "Wir" oder "Uns") angeboten werden,
                unterliegt den hier dargelegten Bedingungen (die "Allgemeinen
                Geschäftsbedingungen"). Bitte lesen Sie die folgenden
                Informationen sorgfältig durch. Damit eine Buchung abgeschlossen
                werden kann, müssen Sie, der Kunde (der Kunde ("Sie" oder
                "Ihr"), angeben, dass Sie diese Allgemeinen Geschäftsbedingungen
                akzeptieren, indem Sie Ihre Buchung wie unten beschrieben
                bestätigen. Wir empfehlen Ihnen, die Website des Unternehmens
                unter www.indiaunlimited.de (die "Website" und zusammen mit
                allen anderen Websites des Unternehmens die "Website(s) des
                Unternehmens") auf die neueste Version dieser Allgemeinen
                Geschäftsbedingungen zu überprüfen oder die neueste Version
                dieser Allgemeinen Geschäftsbedingungen beim Unternehmen
                anzufordern, bevor Sie Ihre Reise buchen.
              </p>
              <p>
                Ungeachtet des Vorstehenden regelt jede Bestimmung in diesen
                Allgemeinen Geschäftsbedingungen, die besagt, dass sie durch
                Ihre Nutzung der Website in Kraft tritt, Ihre Nutzung der
                Website, und wenn Sie mit diesen Bedingungen nicht einverstanden
                sind, dürfen Sie die Website nicht nutzen.
              </p>
              <p>
                Wir von der Firma glauben daran, unsere Kunden mit
                Aufrichtigkeit und Ehrlichkeit zu bedienen, und deshalb können
                Sie uns telefonisch kontaktieren oder uns schreiben, wenn Sie
                Fragen haben, und wir werden uns freuen, alle Ihre Fragen zu
                beantworten oder Ihnen jede Klarstellung zu geben, die Sie
                wünschen.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TermsConditions;
