import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import BlogCard from "../components/BlogCard";
import Footer from "./Footer";
import Header from "./Header";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import {
  loaddatafrombackend,
  loadfeaturedblog,
  loadrelatedtraveltheme,
} from "../redux/actions/blogaction";

import ReviewCardSlider from "../components/ReviewCardSlider";

export const Blog = () => {
  const [noOfElement, setNoOfElement] = useState(3);
  
  const blogsdata = useSelector((state) => state?.allBlogs);
  console.log("shujbha, dskfj,n fkjfnskdfjn skfune vuerinnk kjdfn eirjk0",blogsdata); 

  const featuredBlogs = blogsdata?.featuredblogs;
  console.log("shujbhadkmsfl ,dskfmldkssd cdskf .a",featuredBlogs[0]?.updatedAt);
  const relatedthemes = blogsdata?.relatedthemes;
  console.log("featuredBlogs is ", featuredBlogs);
  // console.log("related theme is ", relatedthemes);
  console.log("blogdata is this ", blogsdata.blogs[0]);
  const dispatch = useDispatch();
  const blogslice = blogsdata?.blogs?.slice(0, noOfElement);
  const handleloadMore = () => {
    setNoOfElement(noOfElement + noOfElement);
    
  };

  useEffect(() => {
    dispatch(loaddatafrombackend());
    dispatch(loadfeaturedblog());
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className="row">
        <div className="col-sm-12 p-4">
        
          <div className="row">
           <div className="col-lg-8 pr-0" style={{ overflow: "hidden"}}>
             <div className="container" >
             {/* <img
                src={blogsdata.blogs[0]?.image}
                className="img-fluid w-100"
                style={{objectFit: "cover" ,maxHeight:"357 px"}}
                alt=""
                style={{
              backgroundImage:"url({blogsdata.blogs[0]?.image})",
              width: "100%",
              height: "300px",
              backgroundPosition:" bottom",
              backgroundRepeat:" no-repeat",
             }}
              /> */}
              <img decoding="async"  src={blogsdata.blogs[0]?.image}
              width="840" height="300" style={{objectFit:"cover",maxHeight: "300px",}} alt="Travel Blog" 
              loading="eager" sizes="(max-width: 1200px) 50vw, (max-width: 768px) 100vw, 840px">              
              </img>
             </div>
            </div>
            <div className="col-lg-4 pr-0 d-flex pr-2 ">
             {/* <div className="bg-light">
             <div className="blog-head ">
                <h3 className="  ">
                  {blogsdata.blogs[0]?.title}
                </h3>
              </div>
              <div className="text-muted cat row justify-content-between py-3">
                <div className="col">
                  <i className="fas fa-users text-success"></i>
                  &nbsp;durch : {blogsdata.blogs[0]?.author}
                </div>
                <div className="col-auto ms-auto">
                  <i className="far fa-clock text-success">
                    {blogsdata.blogs[0]?.minutesread}
                  </i>
                </div>
              </div>
              <div className="beaches-article">
                <p>{blogsdata.blogs[0]?.short_description}</p>
              </div>
              <div className="custom-btn pt-3">
                <Link
                  to={`/blog-details/${blogsdata.blogs[0]?._id}`}
                  className="btn btn-success"
                >
                  Weiterlesen
                </Link>
              </div>
             </div> */}
             <div class="bg-light featured-post__content container">

              <h3 class="h2 m-3">  {blogsdata.blogs[0]?.title}</h3>
              <div class="d-flex featured-post__meta justify-content-between">
                <div class="small-emphasis"  style={{fontSize:"14px"}}>
                  <i className="fas fa-users text-success"></i>
                  &nbsp;durch : {blogsdata.blogs[0]?.author}
                  </div> 
                <span class="total-time" style={{fontSize:"14px"}}>
                  <i className="far  pr-2 fa-clock text-success">
                    {/* {blogsdata.blogs[0]?.minutesread} */}
                  </i>
                  <span>{moment(blogsdata).format("DD MMMM YYYY")}</span>

                  </span></div>
                <p class="sub-text m-2">
                  {blogsdata.blogs[0]?.short_description}
                </p>
               {/* <div className="custom-btn pt-3">


                <Link
                  to={`/blog-details/${blogsdata.blogs[0]?._id}`}
                  className="btn btn-success"
                >
                  Weiterlesen
                </Link>
              </div> */}
              <div className="custom-btn ">
                <Link to={`/blog-details/${blogsdata.blogs[0]?._id}`}
                >
               <button className="btn btn-success">
               Weiterlesen
              </button>
              </Link>
              </div>
                  </div>
            </div>
           </div>
         
        </div>
      </div>
      <div className="row">
        <div className="col">
          <hr />
        </div>
      </div>
      <section className="">
        <div className="container-fluid px-md-5">
          <div className="row">
            <div className="col-md-12 text-center common-heading pb-5">
              <p className="title mb-0">Neuste Blogs</p>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <div className="row">
              {blogslice?.map((item, index) => (
                  <div className="col-md-4 py-3 d-flex">
                    <BlogCard
                      image={item?.image}
                      title={item?.title}
                      author={item?.author}
                      shortdescription={item?.short_description}
                      minutesread={item?.minutesread}
                      id={item?._id}
                    />
                  </div>
                ))}

               
              </div>
            </div>
            {/* <div className="col-md-12   ">
              <div className="row">
                <div className="col-md-4 py-3 d-flex">
                  <div className="card border-0 shadow">
                    <img
                      className="card-img"
                      src="/assets/images/blog-details-1/img-1.jpg"
                      alt=""
                    />
                    <div className="card-body">
                      <small className="text-muted cat d-flex justify-content-between ">
                        <i className="fas fa-users text-success">
                          by : JEMIMA FORBES
                        </i>
                        <i className="far fa-clock text-success"> 30 minutes</i>
                      </small>
                      <h4 className="card-title my-3">
                        Travel for Good: Creating Change Through Education
                      </h4>
                      <p className="card-text">
                        Through our partnership with Logos Scholarship Fund, a
                        Kenya-based social justice initiative, we continue to
                        support the education of children from disadvantaged
                        backgrounds
                      </p>
                      <a href="#" className="btn text-success ">
                        Read Recipe
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 py-3 d-flex">
                  <div className="card border-0 shadow">
                    <img
                      className="card-img"
                      src="/assets/images/blog-details-1/img-1.jpg"
                      alt=""
                    />
                    <div className="card-body">
                      <small className="text-muted cat d-flex justify-content-between ">
                        <i className="fas fa-users text-success">
                          by : JEMIMA FORBES
                        </i>
                        <i className="far fa-clock text-success"> 30 minutes</i>
                      </small>
                      <h4 className="card-title my-3">
                        Travel for Good: Creating Change Through Education
                      </h4>
                      <p className="card-text">
                        Through our partnership with Logos Scholarship Fund, a
                        Kenya-based social justice initiative, we continue to
                        support the education of children from disadvantaged
                        backgrounds
                      </p>
                      <a href="#" className="btn text-success ">
                        Read Recipe
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 py-3 d-flex">
                  <div className="card border-0 shadow">
                    <img
                      className="card-img"
                      src="/assets/images/blog-details-1/img-1.jpg"
                      alt=""
                    />
                    <div className="card-body">
                      <small className="text-muted cat d-flex justify-content-between ">
                        <i className="fas fa-users text-success">
                          by : JEMIMA FORBES
                        </i>
                        <i className="far fa-clock text-success"> 30 minutes</i>
                      </small>
                      <h4 className="card-title my-3">
                        Travel for Good: Creating Change Through Education
                      </h4>
                      <p className="card-text">
                        Through our partnership with Logos Scholarship Fund, a
                        Kenya-based social justice initiative, we continue to
                        support the education of children from disadvantaged
                        backgrounds
                      </p>
                      <a href="#" className="btn text-success ">
                        Read Recipe
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className=" col custom-btn pt-3 text-center">
              <button className="btn btn-success" onClick={handleloadMore}>
                Mehr laden
              </button>
            </div>
          </div>
          <hr />
        </div>
      </section>
      <section className="blog-Featured pt-0">
        <div className="container-fluid px-md-5">
          <div className="row">
            <div className="col-md-12 text-center common-heading pb-5">
              <p className="title mb-0">Ausgewahlt</p>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
            {featuredBlogs?.map(
                (item, index) =>
                  index < 3 && (
                    <div className="col-md-4 py-3 d-flex">
                      <BlogCard
                        title={item?.title}
                        author={item?.author}
                        shortdescription={item?.short_description}
                        minutesread={item?.minutesread}
                        image={item?.image}
                        id={item?._id}
                      />
                    </div>
                  )
              )}
              {/* 
              {featuredBlogs.map((item, index) => {
                <>{console.log("item data===>", item.title)}</>;
                <h1>{item.title}</h1>;
              })} */}
              {/* <div className="col-md-4 py-3 d-flex">
                <BlogCard />
              </div>
              <div className="col-md-4 py-3 d-flex">
                <BlogCard />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section
        className="travellers-review bg-pattern-2"
        style={{ background: "url(/assets/images/travel-agency-bg.jpg)" }}
      >
        <div className="container-fluid px-md-5">
          <div className="row">
            <div className="col-md-12 text-center common-heading pb-5">
              <p class="title mb-2">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div className="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
