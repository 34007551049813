import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TravelCard from "../components/TravelCard";
import Footer from "./Footer";
import Header from "./Header";
import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import CitySection from "../components/CitySection";
import DestinationTourCard from "../components/DestinationTourCard";
import axios from "axios";
import { BASE_URL } from "../service/url";
import { BsArrowRightShort } from "react-icons/bs";

const Himalayas = () => {
  const [tourdata, setTourdata] = useState();
  // const fetchTour = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/tour/tourpackage/gettours`);
  //     const { result } = response.data;

  //     setTourdata(result);
  //   } catch (error) {
  //     console.log("err===", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchTour();
  // }, []);

  const [filterDataTour, setFilterDataTour] = useState('');
  const fetchFilterTour = async () => {
    const obj = {
      category: "Himalayas",
    }
    try {
      const response = await axios.post(`${BASE_URL}/tour/load-related-tour-package`, obj);
      console.log("Reson data testing", response);
      // const { result } = response.data;
      const data = response.data.data;
      console.log(data, "reson data by filter......");
      setFilterDataTour(data)
     
    } catch (error) {
      console.log("err===", error);
    }
  };
   // --------city data APi

   const [citydata, setCitydata] = useState();
   const [noOfCity, setNoOfCity] = useState(8);
   const cityslice = citydata?.slice(0, noOfCity);
   const fetchCity = async () => {
     try {
       const response = await axios.get(`${BASE_URL}/city_management/get-city`);
       console.log("city data2 testing", response)
       const { result } = response.data;
       console.log(response.data, " City data2 testing laksdjflkajdsflk");
       setCitydata(result);
     } catch (error) {
       console.log("err===", error);
     }
   };
 

  useEffect(() =>{
    fetchFilterTour();
    fetchCity()
  }, [])

 console.log("filterDataTour ......." , filterDataTour)

  return (
    <div>
      <Header />

      <div
        className="inner-banner-top bg-about mb-4"
        style={{
          backgroundImage: `url("/assets/images/banner/Himalayas.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="banner-overlay d-flex justify-content-star align-items-end">
              <div className="banner-text p-4">
                <h1>Himalayas</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="row ">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto col-lg-8 px-5">
                <div className="beaches-article d-flex flex-column justify-content-center" style={{textAlign: "justify",}}>
                  <h3>
                    Verschneite Wohnstätte: Touren und Trekking im mächtigen
                    Himalaya
                  </h3>
                  <p>
                    Wenn es jemals einen Weg gab zu erleben, wie ein Maharadscha
                    zu leben, es ist in Rajasthans Palasthotels. Wachen Sie mit
                    einem Pfau auf, der auf Rasenflächen tanzt außerhalb deines
                    Zimmers.
                  </p>
                  <p>
                    Speisen sie raffinierte mahlzeiten gekocht nach königlichen
                    rezepten, in königlichen küchen. Stab mit turban wird Ihre
                    wünsche voraussehen, jeden noch so kleinen Wunsch erfüllen.
                    Genau so ist es berater bei  India Unlimited arbeiten, geht auf
                    jeden Wunsch ein wie sie entwerfen Ihre private Nordindien
                    rundreise.
                  </p>
                  <p>
                    Sie sind nicht nur unerbittlich fokussiert auf das
                    verständnis Ihrer anforderungen, sie setzen sich dafür ein
                    eine störungsfreie Fahrt. Eine Fahrt mit dem Heißluftballon?
                    Kein Problem. Erkundung eines geheimen tunnels zwischen
                    majestätischen wallburgen, oder vielleicht eine bootsfahrt
                    in der dämmerung am Ganges? Kein Problem. Komm zum Taj, aber
                    bleiben sie für die vielfalt und farbe Nordindiens.
                  </p>
                  <p>
                    Der mächtige Himalaya, berühmt für seine bergstationen,
                    wandern, und geistliche passagen, ist das höchste gebirge
                    der welt, die den Norden Indiens umsäumt in einem
                    weitläufigen 1.500- meilen-langen bogen. An bergstationen,
                    wie Manali und Shimla, wachen Sie mit panoramen von hoch
                    aufragenden schneebedeckten wipfeln auf und schroffe
                    klippen.
                  </p>
                  <p>
                    Neben malerischen ausblicken, der Himalaya ist spirituell
                    und kulturell bereichernd. Andächtige lieder zerreißen die
                    luft als anbeter, an hinduistischen Wallfahrtsorten an den
                    Ufern des heiligen Flusses Ganges, jeden Abend singen und
                    beten.
                  </p>
                  <p>
                    Auch der tibetische Buddhismus hat tiefe Wurzeln im
                    Himalaya. Buddhistische gesänge und rituelle hörner kündigen
                    den beginn der gebete an in McLeod Ganj, heimat des Dalai
                    Lama. In der hochgebirgswüste von Ladakh, alltag und
                    geistliche tätigkeit in klöstern fortfahren, seit
                    jahrhunderten unverändert. Bei so hohen bergen, gelegenheit
                    gibt es genug für sanftes abenteuer: wanderwege,
                    trekkingrouten, wildwasser-rafting.
                  </p>
                  <p>
                    Der Himalaya ist atemberaubend und seelenbewegend, aber auch
                    umfangreich und komplex. Lassen sie sich von einem 
                    India Unlimited berater beraten werde dein bergführer, erstellen sie
                    die perfekte reiseroute durch diese beeindruckende
                    landschaft.
                  </p>
                </div>
              </div>
              <QueryCard />
            </div>
          </div>
        </div>
      </section>
      <DestinationTourCard
        tourData={filterDataTour}
        title="BELIEBTE REISEN NACH HIMALAYA"
      />

      
<section>
          <div className="container">
            <p className="section-title text-success text-center mb-2">
              {" "}
              Indien Erleben
            </p>
            <h2 className="text-center pb-3 pt-2">
              Top-Destinationen Für Erbe Und Kultur
            </h2>
            {/* <CitySection /> */}
            <div className="row mx-sm-3 mx-0">
        
    

      {cityslice?.map((citydata,index) => ( 
        <div className="col-md-3 col-6 my-2 "> 
               <CitySection citydata={citydata} />

        </div>
            ))}
              </div>
             <div className="custom-btn col-12 px-2 text-end pt-3">
        <Link to="/view-city">
          {" "}
          <button
            type="submit"
            className="btn border-success text-white bg-success"
          >
            Mehr Anzeigen <BsArrowRightShort size={20 }/>
          </button>
        </Link>
      </div>
          </div>
        </section>


      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.jpg)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>
     
      <Footer />
    </div>
  );
};

export default Himalayas;
