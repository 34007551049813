import React from 'react'
import Footer from './Footer'
import Header from './Header'

const About = () => {
  return (
    <>
    <Header />
<div>
  <div className="inner-banner-top bg-about mb-4">
    <div className="container">
      <div className="row">
        <div className="banner-overlay d-flex justify-content-center align-items-center">
          <div className="banner-text">
            <h1>
              
              About Us
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="about-us">
    <div className="container-fluid px-md-5">
      <div className="row align-items-center">
        <div className="col-lg-5">
          <img className="img-fluid w-100" src="../assets/images/about-img.png" alt="about-img" />
        </div>
        <div className="col-lg-7 pl-md-5 pr-md-4 my-3">
          <div className="text-about py-lg-3 my-3">
            <div className="col-md-12 common-heading">
              <p className="title mb-0">
                
                About
              </p>
            </div>
            <p className="info">
              <span className="font-weight-bold">
                
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              </span>
              
              sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      Diam ut venenatis tellus in metus. Blandit volutpat maecenas
      volutpat blandit. Arcu bibendum at varius vel pharetra vel turpis
      nunc. Diam ut venenatis tellus in metus vulputate eu. Integer
      feugiat scelerisque varius morbi. Gravida neque convallis a cras
      semper auctor. Tempus imperdiet
              <span className="font-weight-bold">
                
                nulla malesuada pellentesque elit eget gravida cum. Purus sit
        amet luctus venenatis lectus magna fringilla urna porttitor. Odio
        eu feugiat pretium nibh ipsum consequat nisl vel giat pretium nibh
        ipsum consequat nisl ve
              </span>
            </p>
            <p className="pt-3">
              
              Sit amet risus nullam eget felis eget. Non quam lacus suspendisse
      faucibus interdum posuere lorem ipsum. Amet commodo
            </p>
            <div className="custom-btn pt-3">
              <button className="btn btn-success">
                
                Explore More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="bg-pattern-1 process-easy position-relative">
    <div className="overlay-sec" />
    <div className="container-fluid px-md-5">
      <div className="row">
        <div className="col-md-12 text-center common-heading pb-5">
          <p className="title mb-0">
            
            Why Us
          </p>
          <h2>
            
            We make all the process easy
          </h2>
        </div>
      </div>
      <div className="row justify-content-lg-start justify-content-center">
        <div className="col-lg-4 col-sm-6 col-6 text-center my-3">
          <div className="d-flex align-items-center justify-content-center easy-info">
            <div>
              <img src="../assets/images/process-easy/img-2.png" alt="process-easy-img-2" />
            </div>
            <div className="pl-3">
              <p>
                
                Best Travel Agent
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-6 text-center my-3">
          <div className="d-flex align-items-center justify-content-center easy-info">
            <div>
              <img src="../assets/images/process-easy/img-3.png" alt="process-easy-img-3" />
            </div>
            <div className="pl-3">
              <p>
                
                Best Travel Agent
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-6 text-center my-3">
          <div className="d-flex align-items-center justify-content-center easy-info">
            <div>
              <img src="../assets/images/process-easy/img-4.png" alt="process-easy-img-4" />
            </div>
            <div className="pl-3">
              <p>
                
                Best Travel Agent
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-6 text-center my-3">
          <div className="d-flex align-items-center justify-content-center easy-info">
            <div>
              <img src="../assets/images/process-easy/img-5.png" alt="process-easy-img-5" />
            </div>
            <div className="pl-3">
              <p>
                
                Best Travel Agent
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 col-6 text-center my-3">
          <div className="d-flex align-items-center justify-content-center easy-info">
            <div>
              <img src="../assets/images/process-easy/img-1.png" alt="process-easy-img-1" />
            </div>
            <div className="pl-3">
              <p>

                Best Travel Agent
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="countup-bg">
    <div className="overlay" />
    <div className="container-fluid px-md-5">
      <div className="
  row
  align-items-center
  justify-content-lg-start justify-content-center
">
        <div className="col-lg-3 col-sm-4 col-6 text-center my-3">
          <div className>
            <img src="../assets/images/coutnup/img-1.png" alt="process-easy-img-2" />
            <div className="info">
              <h3 className="pt-3 mb-0">154</h3>
              <p>
                
                Destinations
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-4 col-6 text-center my-3">
          <div className>
            <img src="../assets/images/coutnup/img-2.png" alt="process-easy-img-2" />
            <div className="info">
              <h3 className="pt-3 mb-0">2,165</h3>
              <p>
                
                Successful Tours
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-4 col-6 text-center my-3">
          <div className>
            <img src="../assets/images/coutnup/img-3.png" alt="process-easy-img-2" />
            <div className="info">
              <h3 className="pt-3 mb-0">48</h3>
              <p>
                
                Tour Types
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-4 col-6 text-center my-3">
          <div className>
            <img src="../assets/images/coutnup/img-4.png" alt="process-easy-img-2" />
            <div className="info">
              <h3 className="pt-3 mb-0">123,456</h3>
              <p>
                
                Happy Customers
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-team">
    <div className="container-fluid px-md-5">
      <div className="row">
        <div className="col-md-12 text-center common-heading pb-5">
          <p className="title mb-0">
            
            Our Team
          </p>
          <h2>
            
            Meet the Team
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-sm-6">
          <div className="px-xl-4">
            <div className="card text-center">
              <img className="card-img-top mx-auto" src="../assets/images/team/img-1.png" alt="Card image" />
              <div className="card-body">
                <p className="card-text mb-0">Marvin McKinney</p>
                <h5 className="card-title">
            
                  Tour Guide
                </h5>
              </div>
              <div className="card-footer bg-transparent">
                <ul className="list-unstyled d-flex mb-0 justify-content-center">
                  <li>
                    <a target="_blank" href="#"><i className="fab fa-facebook-f" /></a>
                  </li>
                  <li className="px-4">
                    <a target="_blank" href="#"><i className="fab fa-twitter" /></a>
                  </li>
                  <li>
                    <a target="_blank" href="#"><i className="fab fa-instagram" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="px-xl-4">
            <div className="card text-center">
              <img className="card-img-top mx-auto" src="../assets/images/team/img-2.png" alt="Card image" />
              <div className="card-body">
                <p className="card-text mb-0">Jenny Wilson</p>
                <h5 className="card-title">
            
                  Tour Guide
                </h5>
              </div>
              <div className="card-footer bg-transparent">
                <ul className="list-unstyled d-flex mb-0 justify-content-center">
                  <li>
                    <a target="_blank" href="#"><i className="fab fa-facebook-f" /></a>
                  </li>
                  <li className="px-4">
                    <a target="_blank" href="#"><i className="fab fa-twitter" /></a>
                  </li>
                  <li>
                    <a target="_blank" href="#"><i className="fab fa-instagram" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="px-xl-4">
            <div className="card text-center">
              <img className="card-img-top mx-auto" src="../assets/images/team/img-3.png" alt="Card image" />
              <div className="card-body">
                <p className="card-text mb-0">Dianne Russell</p>
                <h5 className="card-title">
            
                  Tour Guide
                </h5>
              </div>
              <div className="card-footer bg-transparent">
                <ul className="list-unstyled d-flex mb-0 justify-content-center">
                  <li>
                    <a target="_blank" href="#"><i className="fab fa-facebook-f" /></a>
                  </li>
                  <li className="px-4">
                    <a target="_blank" href="#"><i className="fab fa-twitter" /></a>
                  </li>
                  <li>
                    <a target="_blank" href="#"><i className="fab fa-instagram" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="px-xl-4">
            <div className="card text-center">
              <img className="card-img-top mx-auto" src="../assets/images/team/img-4.png" alt="Card image" />
              <div className="card-body">
                <p className="card-text mb-0">Guy Hawkins</p>
                <h5 className="card-title">
            
                  Tour Guide
                </h5>
              </div>
              <div className="card-footer bg-transparent">
                <ul className="list-unstyled d-flex mb-0 justify-content-center">
                  <li>
                    <a target="_blank" href="#"><i className="fab fa-facebook-f" /></a>
                  </li>
                  <li className="px-4">
                    <a target="_blank" href="#"><i className="fab fa-twitter" /></a>
                  </li>
                  <li>
                    <a target="_blank" href="#"><i className="fab fa-instagram" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<Footer />

    </>
  )
}

export default About