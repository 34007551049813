import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TravelCard from "./TravelCard";


const DestinationSlider = ({ tourdata }) => {
   function SampleNextArrow(props) {
     const { className, style, onClick } = props;
     return (

       <div className="slick-arrow slick-next">

         <button
           type="button"
           onClick={onClick}
           role="presentation"
           class="btn rounded-pill border px-3 slider-navigation-button"
           // style={{height:"45px",width:"45px",display:"flex",alignItems:"center",justifyContent:"center"}}
         >
           <span aria-label="Next">
             <i class="fa fa-chevron-right" aria-hidden="true"></i>
           </span>
        </button>
      </div>

     );
   }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="slick-arrow slick-prev">     <button
           type="button"
           onClick={onClick}
           role="presentation"
           class="btn rounded-pill border px-3 slider-navigation-button"
           // style={{height:"45px",width:"45px",display:"flex",alignItems:"center",justifyContent:"center" }}
         >
          <span aria-label="Previous">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    );
   }

   const settings = {
     dots: true,
     infinite: true,
     speed: 500,
     slidesToShow: 3,
     slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
     responsive: [
       {
         breakpoint: 1024,
         settings: {
           slidesToShow: 3,
           slidesToScroll: 3,
           infinite: true,
           dots: true,
         },
       },
       {
         breakpoint: 648,
         settings: {
           slidesToShow: 2,
           slidesToScroll: 2,
         },
       },
       {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
           slidesToScroll: 1,
         },
       },
       
     ],
   };
  
  console.log("the tour data recieved in slider", tourdata);
  return (
    <div className="container">
      
      <div>

        <Slider {...settings}>
          {tourdata?.map((item, index) => {
            return (
              <div>
                <TravelCard
                  Destination={item?.packageName}
                  Days={item?.totaldays}
                  PerDay={item?.budgetperday}
                  Details={item?.description}
                  LinkTo={`TourPaket/${item?._id}`}
                  image={item?.image}
                  className="w-100"
                />
              </div>
            );
          })}
        </Slider>
      </div>


      
    </div>
  );
};

export default DestinationSlider;
