import { ActionTypes } from "../constants/actiontypes";

const initialState = {
  destination: [],
};

export const loaddestinationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.SET_DESTINATION_DATA:
      return { ...state, destination: payload };
    default:
      return state;
  }
};
