import { React, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { BsArrowRightShort } from "react-icons/bs";
// import QueryCard from "../components/QueryCard";
import ReviewCardSlider from "../components/ReviewCardSlider";
import QueryIndia from "../components/QueryIndia";

const WhyIndiaUnlimited = () => {
  const [displayForm, setDisplayForm] = useState(true);
  const handleClick = () => {
    setDisplayForm(!displayForm);
  };
  return (
    <div>
      <Header />

      <div className="container-fluid row">
        <div className="col-12">
          <div className="row">
            <div className="col-auto col-lg-8">
              <h2 className="text-center pt-2 text-success">
                Why India Unlimited
              </h2>
              <p className="pb-2 pt-2" style={{ textAlign: "justify", }}>
                Indien ist ein fantastisches reiseziel das bietet den besuchern ein breites spektrum an Erlebnissen bietet. Vom prächtigen Taj Mahal, eines der weltwunder, zu verschwenderischen palästen und festungen. Das reiche Erbe, vielfalt, zahlreiche sprachen, und verschiedene kulturen machen das schöne Indien.
              </p>
              <p className="pb-2" style={{ textAlign: "justify", }}>

                Es gibt viele erstaunliche fakten über dieses wunderschön vielfältige land, wie Indien mit dem umfangreichsten schienennetz in ganz ASIEN und die höchste eisenbahnbrücke der welt- die Chenab-Brücke. Wir haben alles von der ältesten Kangra Festung zum abgelegensten ort der Welt - Sentinel Island.

              </p>
              <p className="pb-2" style={{ textAlign: "justify", }}>
                Viele von Ihnen müssen einen ort besuchen wollen mit wunderschönen kulturerbestätten und möchte erleben verschiedene kulturen auf einmal- wenn ja, dann empfehlen wir Indien. Es gibt nichts Vergleichbares, nicht nur Hunderte von Traditionen, verschiedene Kulturen, aber die atemberaubende Natur und ansichten kombiniert, um dieses schöne land zu bilden.
              </p>
              <p style={{ textAlign: "justify", }}>
                Lassen Sie uns einige überwältigende Fakten festhalten über dieses schöne Land. Ziemlich sicher, dass Sie Indien besuchen möchten mindestens einmal, um sich zu fühlen und sehen Sie diese seltsamen, einzigartige orte selbst.
              </p>
              <div style={{ textAlign: "justify", }}>
                <ul className="p-3 mb-0">
                  <li>
                    <p style={{ textAlign: "justify", }}>

                      Das einzige schwimmende Postamt der Welt liegt im Dal-See, im Paradies Indiens–Kaschmir. Stellen Sie sich vor, wie atemberaubend es ist, ein Boot zu fahren, während Sie Ihre Post liefern.

                    </p>
                  </li>
                  <li>
                    <p>
                      Eine Wahlkabine wurde eingerichtet für nur eine Person (Wähler) im Gir-Wald, Gujrat.
                    </p>
                  </li>
                  <li>
                    <p>
                      Es gibt einen ort namens Shani Shingnapur die häuser ohne türen hat! Sie haben richtig gelesen. Die bewohner dieses kleinen dorfes glauben, dass Lord Shani wird sie vor schaden bewahren.
                    </p>
                  </li>
                  <li>
                    <p>
                      Hügel, die der Schwerkraft trotzen, sind ein weiterer wunderbarer Ort  das lässt wissenschaftler in frage. Tulsi Shyam, ein Gebiet in Gujrat, trotzt der Schwerkraft! Eine Sache, die ich sicher bin, ist, dass kinder würde gerne an so einem mysteriösen Ort springen.
                    </p>
                  </li>
                  <li>
                    <p>

                      Indien gehört zu den ländern mit dem höchsten anteil an vegetariern. Sie verehren bestimmte tiere,wie kühe und affen, wegen ihrer erwähnung in den heiligen büchern Mahabharata und Ramayana. Aber wissen sie, Indien hat einen Tempel, der der anbetung von ratten gewidmet ist? Völlig verwirrend, ist es nicht!?

                    </p>
                  </li>
                  <li>
                    <p>

                      Wir alle wissen, wie groß die indische bevölkerung ist aber sehen sie die prominenteste familie der Welt mit 181 Mitgliedern im Dorf Mizoram, zu dem auch der mann des hauses gehört, seine frauen, kinder und enkelkinder? Ein lazarettzug!! Können sie es glauben? Der erste lazarettzug der welt steht in Indien. Jeevan Rekha, oder Rettungsleine Express, bringt seine dienste in abgelegene dörfer und gebiete im ganzen land und bietet heilung für verschiedene Krankheiten.
                    </p>
                  </li>
                </ul>
              </div>

            </div>
            <div className="col-md-5 col-lg-4 pt-3">
              <QueryIndia />
              {/* <QueryCard /> */}
            </div>
          </div>
        </div>
      </div>

      <section
        class="travellers-review bg-pattern-2"
        style={{
          background: "url(/assets/images/travel-agency-bg.png)",
        }}
      >
        <div class="container-fluid px-md-5">
          <div class="row">
            <div class="col-md-12 text-center common-heading pb-5">
              <p class="title mb-3">Referenzen</p>
              <h2>Was unsere Gäste über uns sagen</h2>
            </div>
          </div>
          <div class="col-md-12">
            <ReviewCardSlider />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default WhyIndiaUnlimited;
